.wrap_div {
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: Noto Sans KR;
}

.back_img {
    width: 600px;
    position: absolute;
    z-index: -99;
    transform: translate(0, -25%);
}

/* .back_img1 {
    width: 600px;
    position: absolute;
    z-index: -99;
    transform: translate(0, -25%);
}

.back_img2 {
    width: 600px;
    position: absolute;
    z-index: -99;
    transform: translate(0, 225%);
} */

.title_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
}

.title_div > img {
    width: 135px;
    margin-right: 20px;
}

.title_div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}

.title_div > div > h3 {
    /* margin: 0; */
    font-size: 28px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
}

.title_div > div > h1 {
    /* margin: 0; */
    font-size: 70px;
    font-weight: 450;
    margin: 0;
}

.info_table {
    width: 90%;
    max-width: 793px;
    text-align: center;
    border: 1px solid #010101;
    border-collapse: collapse;
    margin-bottom: 10px;
    padding: 5px;
    font-size: 17px;
    font-family: Noto Sans KR;
    font-weight: normal;
}

.info_table > tbody > tr td {
    border: 1px solid #010101;
    border-collapse: collapse;
    padding: 5px;
}

.table_title {
    background-color: #f5f5f5;
    width: 150px;
}

.table_title2 {
    background-color: #f5f5f5;
    width: 50px;
}

.info_div {
    width: 100%;
    /* background-color: red; */
    max-width: 650px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 100;
}

.info_div > :nth-child(1) {
    /* flex: 0.5; */
    flex: 2;
    margin-right: 180px;
    text-align: center;
    /* font-weight: bold; */
}
.info_div > div > :nth-child(1) {
    text-align: center;
    /* font-weight: bold; */
}
/* .info_div > :nth-child(2) {
    flex: 1;
    text-align: center;
    font-weight: bold;
  } */

.content_p {
    width: 90%;
    max-width: 650px;
    font-size: 28px;
    line-height: 250%;
}

.date_p {
    width: 90%;
    max-width: 650px;
    font-size: 23px;
    text-align: right;
}

.date_p2 {
    width: 90%;
    max-width: 793px;
    font-size: 23px;
    text-align: right;
}

@page {
    size: A4;
    margin: 0;
}
@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
    }
    /* ... the rest of the rules ... */
    .page_break {
        break-after: always !important;
    }
}
