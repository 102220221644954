.wrap_div {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/images/main/bg_01.jpg);
    background-size: cover;
    flex-direction:column;
    padding: 70px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    /* height: 100vh; */
    overflow: hidden;
}

.wrap_div > :nth-child(1) > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 40px;
    text-align: center;
    letter-spacing: -0.015em;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 18px;
}

/* .wrap_div > :nth-child(1) > hr {
    width: 240px;
} */

.all_rank_btn {
    margin-top: 23px;
    border: 2px solid #BD3FDC;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 9px #C44CD8);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    font-family: "Noto Sans KR";
    color: #FFFFFF;
    width: 110px;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-shadow: 0px 1.25px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    transition: background-color 0.5s ease;
    /* margin-bottom: 300px; */
}

.all_rank_btn:hover {
    cursor: pointer;
    background-color: #BD3FDC;
}


.content_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rank_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 270px;
    
}

.rank_div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rank_div2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-right: 70px; */
    margin-top: 0px;
}

.rank_div2 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rank_div3 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-right: 70px; */
    margin-top: 0px;
}

.rank_div3 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rank_team_border1{
    filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
    position: absolute;
    top: -19%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 90;
}

.logoTeam_img1{
    position: absolute;
    top: -19%;
    left: 50.5%;
    transform: translate(-50%, -50%);
    clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
    width: 295px;
    height: 295px;
    z-index: 99;
}

.rank_flash1 {
    position: absolute;
    top: -19%;
    left: 50.5%;
    transform: translate(-50%, -50%);
}

.rank_team_border2{
    filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF5C00);
    position: absolute;
    top: -21%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 90;
}

.logoTeam_img2{
    position: absolute;
    top: -21%;
    left: 50.2%;
    transform: translate(-50%, -50%);
    clip-path: polygon(49% 0%, 94% 25%, 94% 75%, 49% 100%, 5% 75%, 5% 25%);
    height: 200px;
    width: 200px;
    z-index: 99;
}

.rank_flash2 {
    position: absolute;
    top: -21%;
    left: 50.6%;
    transform: translate(-50%, -50%);
}

.rank_team_border3{
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)), drop-shadow(0px 0px 3px #33004A), drop-shadow(0px 0px 60px #FF0000); */
    filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF0000);
    position: absolute;
    top: -21%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 90;
}
.rank_flash3 {
    position: absolute;
    top: -21%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rank_team_name1{
    margin-top: 25px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    white-space: nowrap;
    margin-bottom: 80px !important;
    width: 300px;
}

.rank_team_name{
    margin-top: 25px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    white-space: nowrap;
    width: 300px;
}

.rank_crown {
    margin-top: 30px;
}



.rank_flag {
    filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
}

@media screen and (max-width: 1500px) {
    .rank_div {
        margin: 0px 50px;
    }
}



@media screen and (max-width: 1000px) {
    .wrap_div {
        height: 1900px;
    }
    
    .content_div {
        margin-top: 50px;
        flex-direction: column;
        position: relative;
    }



    .rank_team_name1{
        margin-bottom: 0px;
    }

    .rank_div {
        margin-right: 0px;
        position: relative;
        margin: 0px;
        top: -220px;
        left: 0px;
    }
    .rank_div2 {
        /* margin-top: 500px; */
        position: relative;
        top: 530px;
    }
    .rank_div3 {
        margin-top: 400px;
        
    }
}
