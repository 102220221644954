.set_drop {
    /* background-color: rgba(255, 255, 255, 0) !important; */
    font-size: 15px;
    width: 200px !important;
    margin-bottom: 40px;
}
.score_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.score_div > div {
    margin: 0px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.score_div > :nth-child(1) {
    background-color: red;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: "Noto Sans KR";
    width: 150px;
    height: 150px;
}

.score_div > :nth-child(2) {
    background-color: blue;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: "Noto Sans KR";
    width: 150px;
    height: 150px;
}

.score_div > div:hover {
    cursor: pointer;
}

