.wrap_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: relative; */
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* padding-top: 150px; */
    overflow-y: hidden;
}

.wrap_div > h1 {
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 300px;
    color: white;
}

.wrap_div > :nth-child(2) {
    font-size: 80px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding-top: 7px;
}

.wrap_div > :nth-child(3) {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    padding-top: 3px;
}

.wrap_div > p:hover{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);
}