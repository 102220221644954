.content_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content_div * {
    font-family: "Noto Sans KR";
}

.content_div span {
    color: red;
    font-weight: bold;
}



.content_div > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px;
}

/* .content_div > div > p {
    margin: 0px;
    font-size: 15px;
} */
.content_div > div > :nth-child(1) {
    margin: 0px;
    font-size: 15px;
    flex: 1;
}



.content_div > div > :nth-child(2) {
    text-align: center;
    flex: 3;
}