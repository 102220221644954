.wrap_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.back_img {
    width: 600px;
    position: absolute;
    z-index: -99;
    transform: translate(0, -25%);
}

@page {
    size: A4;
    margin: 0;

  }
  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
    }
    /* ... the rest of the rules ... */
    .page_break {
        break-after: always !important;
    }

  }