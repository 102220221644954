.wrap_div {
    background-image: url(/images/intro_DS/rule_image/rules_bg01.jpg);
    background-position: center;
    /* height: 8407px; */
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw !important;
    overflow: hidden;
}

.intro_content_text {
    padding: 0px 40px;
    font-size: 18px;
    text-align: center;
    word-break: keep-all;
    width: 1200px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    line-height: 131.5%;
    /* or 33px */
    letter-spacing: -0.03em;
    color: #000000;
    margin-top: 70px;
}

.intro_content_text > span {
    font-weight: bold;
    color: #61D1E0;
}

.title_div * {
    font-family: "Noto Sans KR";
}

.title_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 130px;
}

.title_div > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.015em;
    margin-bottom: 0px;
    color: #000000;
}

.title_div > hr {
    width: 100%;
}

.title_div_stadium {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -130px;
}

.title_div_stadium h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.015em;
    margin-bottom: 0px;
    color: #000000;
    
}

.title_div_stadium hr {
    width: 100%;
}

.intro_droneball_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}

.intro_third_forth_img{
    width: 500px;
    height: 500px;
}

.intro_title_sub_text1{
    font-size: 18px;
    position: relative;
    top: -500px;
    left: -430px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .intro_title_sub_text2{
    font-size: 18px;
    position: relative;
    top: -695px;
    left: 400px;
    position: relative;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }
  
  .intro_title_sub_text3{
    font-size: 18px;
    position: relative;
    top: -280px;
    left: 350px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .intro_title_sub_text4{
    font-size: 18px;
    position: relative;
    top: -530px;
    left: -310px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }


.droneBall_arrow{
    display: flex;
}

.droneBall_arrow1{
    position: relative;
    top: -500px;
    left: -300px;
}
.droneBall_arrow2{
    position: relative;
    top: -615px;
    left: 225px;
}
.droneBall_arrow3{
    position: relative;
    top: -230px;
    left: 170px;
}

.droneBall_arrow4{
    position: relative;
    top: -550px;
    left: -290px;
}





  
  

.explanation_div {
    margin-bottom: 33px;
    background-color: #A50000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 10px 0px;
}

.explanation_div > * {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    letter-spacing: -0.015em;
}

.explanation_div_white {
    margin-bottom: 33px;
    background-color: rgba(255, 255, 255, 0.86);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 10px 0px;
}

.explanation_div_white > * {
    letter-spacing: -0.015em;
    color: #020202;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

.explanation_div_white * > span {
    color: #FF0000;
}

.explanation_div_white * > u {
    text-decoration: none;
    /* border-bottom: 10px solid yellow; */
    box-shadow: inset 0 0px 0 white, inset 0 -5px 0 yellow
}

.rule_image_div1{
    position: relative;
}

.rule_image_div1 > :nth-child(2) {
    position: absolute;
    top: 37%;
    left: 5%;
    transform: translate( -50%, -50% );
    color: #FFBABA;
    font-weight: bold;
    font-size: 15px;
}

.rule_image_div1 > :nth-child(3) {
    position: absolute;
    top: 37%;
    right: 6%;
    transform: translate( -50%, -50% );
    color: #FFBABA;
    font-weight: bold;
    font-size: 15px;
}

.rule_image {
    width: 1200px;
}

.rule_image_div2{
    position: relative;
}

.rule_image_div2 > :nth-child(2) {
    position: absolute;
    top: 47%;
    left: 32%;
    transform: translate( -50%, -50% );
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}

.rule_image_div2 > :nth-child(3) {
    position: absolute;
    top: 47%;
    right: 25.5%;
    transform: translate( -50%, -50% );
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}


.little_title_div {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}

.little_title_div > p {
    margin: 0px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.little_title_div > hr {
    width: 100%;
}

.circle_rules {
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 200px;
    /* background-color: red; */
}

.circle_rules > div {
    position: relative;
}

.circle_rule_image {
    width: 350px;
}

.circle_rules > div > div {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate( -50%, -50% );
    font-family: "Noto Sans KR";
    height: 50%;
    /* background-color: red; */
}

.circle_rules > div > div > :nth-child(2) {
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    margin-top: -30px;
    font-weight: bold;
    width: 260px;
    color: #000000;
    /* background-color: red; */
}


.circle_rules > div > div > :nth-child(2) > span {
    color: #FF0000;
}




.circle_rules > div > div > :nth-child(1) {
    position: relative;
    top: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.circle_rules > div > div > div > h1{
    font-size: 60px;
    margin: 0px;
    font-weight: bold;
    color: #000000;
}

.circle_rules > div > div > div > p {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #000000;
    /* background-color: red; */
}

.circle_rules > div > div > div hr {
    width: 250px;
}

.download_rulebook{
    font-family: "Noto Sans KR";
    color: #FFFFFF;
    font-size: 40px;
    margin-bottom: 100px;
    text-align: center;
}

.download_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 800px;
}

.download_div > a {
    text-decoration: none;
    background-color: #CF3C0C;
    border-radius: 5px;
    width: 150px;
    height: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.download_div > a:hover {
    cursor: pointer;
}


.download_div > a > p {
    margin-bottom: 10px;
    color: #FFFFFF;
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 20px;
}

@media screen and (max-width: 1900px){
    .download_rulebook {
        color: #000000;
    }
}

@media screen and (max-width: 1200px) {
    .rule_image { width: 800px; }
    .intro_content_text { width: 800px; }
    .intro_third_forth_img {width: 400px; height: 400px;}
    .intro_title_sub_text1{ font-size: 18px; position: static; margin-top: 50px;}
    .intro_title_sub_text2{ font-size: 18px; position: static;}
    .intro_title_sub_text3{ font-size: 18px; position: static;}
    .intro_title_sub_text4{ font-size: 18px; position: static; }
    .droneBall_arrow { display: none; }
    .droneBall_arrow1{ position: static; display: none;}
    .droneBall_arrow2{ position: static; display: none; margin-bottom: 15px;}
    .droneBall_arrow3{ position: static; display: none; margin-bottom: 15px;}
    .title_div_stadium { margin-top: 130px; }


    .rule_image_div1 > :nth-child(2) {
        top: 35%;
        left: 5%;
        font-size: 15px;
    }
    .rule_image_div1 > :nth-child(3) {
        top: 35%;
        right: 4.2%;
        font-size: 15px;
    }
    .rule_image_div2 > :nth-child(2) {
        top: 45%;
        left: 32%;
        font-size: 10px;
    }
    
    .rule_image_div2 > :nth-child(3) {
        top: 45%;
        right: 24.5%;
        font-size: 10px;
    }
    .circle_rules {
        width: 800px;
    }
    .circle_rule_image {
        width: 250px;
    }
    .circle_rules > div > div > div h1{
        font-size: 40px;
    }
    
    .circle_rules > div > div > div p {
        font-size: 10px;
        margin-bottom: 5px;
    }
    .circle_rules > div > div > div hr {
        width: 150px;
    }
    .circle_rules > div > div > :nth-child(2) {
        width: 230px;
        font-size: 13px;
        margin-top: -30px;
        font-weight: bold;
    }

}

@media screen and (max-width: 768px) {
    .title_div {
        width: fit-content;
    }
    /* .title_div > hr {
        width: 100%;
    } */


    .rule_image { width: 350px; }
    .intro_content_text { width: 100%; text-align: center; word-break: normal;}
    .intro_third_forth_img { width: 300px !important; height: 300px !important;}


    .rule_image_div1 > :nth-child(2) {
        top: 32%;
        left: 5%;
        font-size: 8px;
        
        /* background-color: red; */
    }
    .rule_image_div1 > :nth-child(3) {
        top: 32%;
        right: 3.2%;
        font-size: 8px;
    }
    .rule_image_div2 > :nth-child(2) {
        top: 33%;
        left: 34%;
        font-size: 2px;
    }
    
    .rule_image_div2 > :nth-child(3) {
        top: 33%;
        right: 19.7%;
        font-size: 2px;
    }
    
    .circle_rules {
        width: 430px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 200px;
    }

    .explanation_div {
        width: 350px !important;
        padding: 10px;
    }
    
    .explanation_div p {
        word-break: keep-all;
    }

    .explanation_div_white {
        width: 350px;
        padding: 10px;
    }
    
    .explanation_div_white p {
        word-break: keep-all;
    }
    .download_rulebook {
        color: #ffffff;
    }
    .download_div {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 430px;
        height: 500px;
    }
}