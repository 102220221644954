.intro_tap_box {
    background-position: center;
    padding-top: 180px;
    padding-bottom: 280px;
    background-image: url("/images/intro/bg_01.jpg");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.img_div {
    display: flex;
    flex-direction: row;
    width: 800px;
    justify-content: space-between;
    margin-top: 80px;
}

.intro_tap_box > p {
    font-size: 20px;
    font-family: "Noto Sans KR";
    font-style: normal;
    text-align: center;
    color: white;
}

.intro_tap_box > div {
    font-size: 20px;
    font-family: "Noto Sans KR";
    font-style: normal;
    text-align: center;
    color: white;
}

.intro_tap_box .count_txt {
    font-size: 98px;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-weight: bold;
    color: #32faea;
    padding: 0;
    margin: 0;
}

.intro_tap_box .count_name {
    font-size: 28px;
    font-family: "Noto Sans KR";
    font-weight: bold;
    text-shadow: 2px 2px 2px gray;
    color: white;
    padding: 0;
    margin: 0;
}

.intro_tap_box .text_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.text_div > :nth-child(1) {
    font-family: "Noto Sans KR";
    color: white;
    font-weight: normal;
    text-shadow: gray 2px 2px 2px;
    font-size: 18px;
    margin-bottom: 0px;
}

.text_div > :nth-child(2) {
    color: white;
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 56px;
    text-shadow: gray 2px 2px 2px;
    margin-top: 5px;
    margin-bottom: 10px;
    overflow: hidden;
}

.text_div > :nth-child(3) {
    max-width: 1200px;
    padding: 0px 40px;
}

.orgChart_div {
    margin-top: 70px;
    border-radius: 5px;
}

.squad_table {
    max-width: 1000px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
    margin-top: 70px;
}

.squad_table > thead > tr {
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.squad_table > thead > tr > th {
    background-color: #5814e1;
    width: 80vw;
}

.squad_table > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
}

.squad_table > tbody > tr {
    height: 60px;
    font-size: 16px;
    font-family: "Noto Sans KR";
}

.squad_table > tbody > tr > td {
    border-bottom: 1px dashed gray;
}

.squad_table > tbody > :nth-last-child(1) > td {
    border-bottom: 0px;
}

@media screen and (max-width: 1200px) {
    .text_div > :nth-child(3) {
        width: 800px;
    }
    .orgChart_div {
        width: 80%;
    }
}

@media screen and (max-width: 768px) {
    .intro_tap_box .text_div > h1 {
        font-size: 40px;
        overflow: hidden;
    }

    .text_div > :nth-child(2) {
        width: 430px;
        overflow: hidden;
    }
    .text_div > :nth-child(3) {
        width: 430px;
        overflow: hidden;
    }

    .intro_tap_box .img_div {
        flex-direction: column;
    }

    .intro_tap_box .img_div > div {
        margin-bottom: 30px;
    }

    .squad_table {
        width: 80vw;
    }

    .squad_table > thead > tr {
        height: 40px;
        font-size: 12px;
    }

    .squad_table > tbody > tr {
        height: 40px;
        font-size: 16px;
    }
}
