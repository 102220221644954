.intro_tap_box {
    background-position: center;
    min-height: 1080px;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #090832; */
}