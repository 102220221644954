.style_a {
    background-color: rgba(50, 250, 83, 0.8);
    border-radius: 10px;
    position: relative;
    display: inline-block;
    padding: 15px 20px;
    /* width: fit-content; */
    height: 50px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 0px;
    text-align: center;
    font-weight: bold;
    font-family: "Noto Sans KR";
}

.style_a:hover {
  cursor: pointer;
    background: #32FA53;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #32FA53,
                0 0 25px #32FA53,
                0 0 50px #32FA53,
                0 0 150px #32FA53;
  }

  .style_a1 {
    background-color: rgba(82, 82, 82, 0.8);
    border-radius: 10px;
    position: relative;
    display: inline-block;
    padding: 15px 20px;
    /* width: 180px; */
    height: 50px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 0px;
    text-align: center;
    font-weight: bold;
    font-family: "Noto Sans KR";
}
.style_a1:hover {
    cursor: pointer;
      background: #525252;
      color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 5px #525252,
                  0 0 25px #525252,
                  0 0 50px #525252,
                  0 0 150px #525252;
    }

.mouse_hover {
    cursor: pointer;
}


@media (min-width:554px) and (max-width:768px){
  .style_a {padding:10px; font-size: 9pt; height: 40px;}
  .style_a1 {margin-top: 10px; padding: 10px; font-size: 9pt; height: 40px;}
}


@media (min-width:321px) and (max-width:553px){
  .style_a {padding: 10px; font-size: 9pt; height: 40px;}
  .style_a1 {margin-top: 10px; padding: 10px; font-size: 9pt; height: 40px;}
}

@media (max-width:320px){
  .style_a {padding: 10px; font-size: 9pt; height: 40px;}
  .style_a1 {margin-top: 10px; padding: 10px; font-size: 9pt; height: 40px;}
}