.bracketTable {
    margin-top: 100px;
    margin-bottom: 150px;
    border: 0;
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-left: 25px;
}

.matchWinner {
    background: #272f36;
    color: #FF8C00;
    box-sizing: border-box;
    font-weight: 600;
    line-height: 0.7rem;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
 }

.matchWinner span {
    vertical-align: middle;
    font-size: 0.9rem;
    font-family: "Noto Sans KR";
}
  
.matchWinner span:last-child {
    padding-left: 10px;
    padding-right: 5px;
}

.matchLosser {
    background: #182026;
    color: #6b798c;
    box-sizing: border-box;
    font-weight: 700;
    line-height: 0.7rem;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
}

.matchLosser span {
    vertical-align: middle;
    font-size: 0.9rem;
    font-family: "Noto Sans KR";
}
  
.matchLosser span:last-child {
    padding-left: 12px;
    padding-right: 5px;
}

.teamLog {
    width: 23px;
    height: 23px;
    vertical-align: middle;
    border-radius: 50%;
}

.borderTop {
    border-top:1px solid #6b798c
}

.borderTopLeft {
    border-top:1px solid #6b798c;
    border-left:1px solid #6b798c;
}

.borderTopRight {
    border-top:1px solid #6b798c;
    border-right:1px solid #6b798c;
}

.borderLeftTop {
    border-left:1px solid #6b798c;
    border-top:1px solid #6b798c;
}

.borderRight {
    border-right:1px solid #6b798c;
}

.borderLeft {
    border-left:1px solid #6b798c;
}


@media screen and (max-width: 768px){
    .teamLog {
        width: 15px;
        height: 15px;
        vertical-align: middle;
    }

    .matchWinner {
        background: #272f36;
        color: #FF8C00;
        box-sizing: border-box;
        font-weight: 700;
        line-height: 0.7rem;
        text-align: center;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
     }
    
    .matchWinner span {
        vertical-align: middle;
        font-size: 0.6rem;
        font-family: "Noto Sans KR";
    }
      
    .matchWinner span:last-child {
        padding-left: 2px;
        padding-right: 2px;
    }
    
    .matchLosser {
        background: #182026;
        color: #6b798c;
        box-sizing: border-box;
        font-weight: 700;
        line-height: 0.6rem;
        text-align: center;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
     }
    
    .matchLosser span {
        vertical-align: middle;
        font-size: 0.7rem;
        font-family: "Noto Sans KR";
    }
      
    .matchLosser span:last-child {
        padding-left: 2px;
        padding-right: 2px;
    }

    .bracketTable {
        border: 0;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        padding-left: 1px;
        display: none;
    }
  }