@import url("https://fonts.googleapis.com/earlyaccess/notosanskr.css");

.rank_div * {
    font-family: "Noto Sans KR";
    font-size: 12pt;
}

.rank_div {
    font-family: "Noto Sans KR";
    background-image: url("/images/rank/rank_bg_01.jpg");
    min-height: 1200px;
    height: 100vh;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.rank_div > .rank_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rank_div > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    font-family: "Noto Sans KR";
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.rank_div > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 40px;
    font-family: "Noto Sans KR";
    color: white;
    text-shadow: gray 2px 2px 2px;
}

.rank_div > :nth-child(2) {
    color: white;
    font-size: 15px;
    justify-content: space-between;
    font-family: "Noto Sans KR";
}

.rank_div > .rank_wrap .rankTypeWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: center;
    margin-bottom: 40px;
}

.radioButton {
    display: flex;
    background-image: url("/images/rank/level_tab_bg.png");
    align-items: center;
    width: 200px;
    height: 41px;
    font-size: 15px;
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 10px;
}

.radioButton:first-child > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.radioButton:first-child > :nth-child(2) {
    margin-right: auto;
}

.rank_div > .rank_wrap .rankTypeWrap > :nth-child(2) > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.rank_div > .rank_wrap .rankTypeWrap > :nth-child(2) > :nth-child(2) {
    margin-right: auto;
}

.radioButton:last-child > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.radioButton:last-child > :nth-child(2) {
    margin-right: auto;
}

.rank_table {
    width: 1600px;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
    border-collapse: separate;
    border-spacing: 0 20px;
}

.rank_table > thead > tr {
    height: 40px !important;
    color: white;
}

.rank_table > thead > tr > th {
    background-color: rgba(20, 22, 30, 0.8);
    width: 160px;
}

.rank_table > thead > tr > th:first-child {
    background-color: rgba(20, 22, 30, 0.8);
    width: 5%;
}

.rank_table > thead > tr > :nth-child(2) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 8%;
}

.rank_table > thead > tr > :nth-child(3) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 8%;
}

.rank_table > thead > tr > :nth-child(4) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 8%;
}

.rank_table > thead > tr > :nth-child(5) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 51%;
}

.rank_table > thead > tr > :nth-child(6) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 20%;
}

.rank_table > thead > tr {
    height: 80px;
    color: white;
}

.rank_table > tbody {
    color: white;
}

.rank_table > tbody .tableRow {
    background-image: url("/images/rank/rank_back_01.png");
    background-size: 100% 100%;
    max-width: 1600px;
    width: 100%;
    height: 85px;
    background-repeat: no-repeat;
    overflow-x: "auto";
}

.rank_table > tbody .tableRow:hover {
    /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
    box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
    background-image: url("/images/rank/rank_back_over.png");
    cursor: pointer;
}

.rank_table > tbody .tableRow:hover :nth-child(2) {
    /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
    box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
    color: #ff2358;
}

.rank_table > tbody > tr > td > .bodyTable {
    height: 500px;
    overflow-y: scroll;
}

.rank_table > tbody > tr > td > .bodyTable::-webkit-scrollbar-thumb {
    background-color: white;
}

.rank_table > thead > tr > th:first-child {
    background-color: rgba(20, 22, 30, 0.8);
    width: 5%;
}

.rank_table > thead > tr > :nth-child(2) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 10%;
}

.rank_table > thead > tr > :nth-child(3) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 10%;
}

.rank_table > thead > tr > :nth-child(4) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 10%;
}

.rank_table > thead > tr > :nth-child(5) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 45%;
}

.rank_table > thead > tr > :nth-child(6) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 20%;
}

.trophyRow {
    width: 5%;
}

.rank {
    font-size: 35px;
    width: 10%;
}

.rankImgRow {
    font-size: 18px;
    width: 10%;
}

.teamLogRow {
    font-size: 18px;
    width: 10%;
    padding-left: 5px;
}

.teamText {
    font-size: 18px;
    width: 45%;
    padding-left: 5px;
}

.record {
    font-size: 18px;
    width: 20%;
    padding-left: 15px;
}

.rankUp {
    width: 15px;
    height: 11px;
}

.moreBg {
    width: 100px;
    height: 100px;
}

.rankSame {
    width: 16px;
    height: 8px;
}

.rankDown {
    width: 15px;
    height: 11px;
}

.rankRecord {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff2358;
    border-radius: 11px;
    width: 122px;
    height: 45px;
    font-size: 15px;
    background-size: cover;
    position: relative;
    margin: auto;
    text-align: center;
}

.teamLog {
    width: 44px;
    height: 44px;
    clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
}

.trophy {
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.competition_table > tbody > :nth-last-child(1) > td {
    border-bottom: 0px;
    text-align: center;
}

.table_wrap {
    /* display: block; */
    display: flex;
    /* width: 90vw; */
    max-width: 1600px;
    width: 90vw;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* background-color: yellow; */
}

.competition_dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    margin-bottom: 50px;
}

.competition_dropdown > div > * {
    background-color: transparent !important;
    border-color: white !important;
    color: white !important;
    text-align: center !important;
    /* font-size: 20px; */
    max-width: max-content !important;
}

@media (max-width: 499px) {
    .table_wrap {
        width: 300px;
        overflow-x: "scroll";
    }

    .rank_div * {
        font-family: "Noto Sans KR";
        font-size: 9pt;
    }

    .rank_table {
        width: 500px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
    }

    .rank_table > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .rank_table > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        height: 5px;
    }
    .rank_table > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .rank_table > tbody {
        color: white;
    }

    .rank_table > tbody .tableRow {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
        overflow-x: "auto";
    }

    .rank_table > tbody .tableRow:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .rank_table > tbody .tableRow > td:first-child {
        padding-left: 5px;
    }

    .rank_table > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 5%;
    }

    .rank_table > thead > tr > :nth-child(2) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 10%;
    }

    .rank_table > thead > tr > :nth-child(3) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 10%;
    }

    .rank_table > thead > tr > :nth-child(4) {
        background-color: rgba(20, 22, 30, 0.8);
        padding-left: 12px;
        width: 10%;
    }

    .rank_table > thead > tr > :nth-child(5) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 45%;
    }

    .rank_table > thead > tr > :nth-child(6) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 20%;
    }

    .trophyRow {
        width: 5%;
    }

    .rank {
        font-size: 15px;
        padding-right: 16px;
        width: 10%;
    }

    .rankImgRow {
        font-size: 13px;
        padding-right: 5px;
        width: 10%;
    }

    .teamLogRow {
        font-size: 13px;
        width: 10%;
    }

    .teamText {
        font-size: 13px;
        padding-left: 5px;
        width: 45%;
    }

    .record {
        font-size: 13px;
        width: 20%;
        padding-left: 27px;
        padding-right: 13px;
    }

    .rankUp {
        width: 10px;
        height: 5px;
    }

    .rankSame {
        width: 10px;
        height: 5px;
    }

    .rankDown {
        width: 8px;
        height: 5px;
    }

    .rankRecord {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 27px;
        height: 21px;
        font-size: 7px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .teamLog {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .trophy {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .radioButton {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 100px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .radioButton:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .radioButton:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 10px;
    }

    .rank_div > div.rank_wrap .rankTypeWrap > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .rank_div > div.rank_wrap .rankTypeWrap > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 22.5px;
    }

    .radioButton:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .radioButton:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 11.25px;
    }
}

@media (min-width: 500px) and (max-width: 768px) {
    .rank_div * {
        font-family: "Noto Sans KR";
        font-size: 8pt;
    }

    .rank_table {
        /* max-width: 500px; */
        /* width: 90vw; */
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
        /* background-color: red; */
    }

    .rank_table > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .rank_table > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 10px;
        height: 5px;
    }
    .rank_table > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .rank_table > tbody {
        color: white;
    }

    .rank_table > tbody .tableRow {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
    }

    .rank_table > tbody .tableRow:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .rank_table > tbody .tableRow > td:first-child {
        padding-left: 10px;
    }

    .rank {
        font-size: 1em;
    }

    .rankUp {
        width: 10px;
        height: 5px;
    }

    .rankSame {
        width: 10px;
        height: 5px;
    }

    .rankDown {
        width: 8px;
        height: 5px;
    }

    .rankRecord {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 30px;
        height: 25px;
        font-size: 10px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .teamLog {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .trophy {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .radioButton {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 100px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .radioButton:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .radioButton:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 10px;
    }

    .rank_div > div.rank_wrap .rankTypeWrap > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .rank_div > div.rank_wrap .rankTypeWrap > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 22.5px;
    }

    .radioButton:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .radioButton:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 11.25px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .rank_div * {
        font-family: "Noto Sans KR";
        font-size: 10pt;
    }

    .rank_table {
        max-width: 1000px;
        width: 900px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
    }

    .rank_table > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .rank_table > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 15px;
        height: 5px;
    }
    .rank_table > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .rank_table > tbody {
        color: white;
    }

    .rank_table > tbody .tableRow {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
    }

    .rank_table > tbody .tableRow:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
        color: #ff2358;
    }

    .rank_table > tbody .tableRow > td:first-child {
        padding-left: 20px;
    }

    .rank {
        font-size: 1em;
    }

    .rankUp {
        width: 10px;
        height: 5px;
    }

    .rankSame {
        width: 10px;
        height: 5px;
    }

    .rankDown {
        width: 8px;
        height: 5px;
    }

    .rankRecord {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 40px;
        height: 30px;
        font-size: 10px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .teamLog {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .trophy {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .radioButton {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 120px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .radioButton:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .radioButton:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 10px;
    }

    .rank_div > div.rank_wrap .rankTypeWrap > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .rank_div > div.rank_wrap .rankTypeWrap > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 32.5px;
    }

    .radioButton:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .radioButton:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 11.25px;
    }
}
