.wrap_div {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    /* height: 100vh; */
    /* min-height: 600px; */
    min-height: 100vh;
}

.title_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
}

.title_div p {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 18px;
    margin-bottom: 5px;
}

.title_div h1{
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 56px;
    margin-top: 0px;
    font-weight: bold;
    text-align: center;
}

.content_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80vw;
    max-width: 1200px;
    flex-wrap: wrap;
}

.region_drop {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: white !important;
    color: white !important;
    font-size: 15px;
    width: 180px !important;
    margin-bottom: 40px;
}

.profile_div {
    margin: 10px;
    width: 350px;
    height: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.3);
    /* border-radius: 15px; */
}


.profile_img_div img {
    width: 155px;
    height: 198px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
}

.profile_text_div {
    width: 160px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border: 0px;
    border-radius: 10px;
    padding: 5px;
}

.profile_text_div p {
    margin: 0px;
    font-family: "Noto Sans KR";
    font-size: 15px;
    font-style: oblique;
    color: white;
}

.profile_text_div > :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile_text_div > :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.profile_text_div > :nth-child(1) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 23px;
}

.profile_text_div > :nth-child(1) > :nth-child(2) {
    color: #61ece1;
    font-size: 12px;
}

.profile_text_div > :nth-child(2) {
    margin-bottom: 10px;
}

.profile_text_div > :nth-child(2) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

.profile_text_div > :nth-child(3) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

@media screen and (max-width: 1200px){
    .content_div {width: 740px;}
}

@media screen and (max-width: 738px){
    .content_div {flex-direction: column; width: 370px;}
}