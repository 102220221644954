.content_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    /* background-color: blue; */
}

.member_profile > img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgba(97, 236, 224, 0.3), 
                0 0 0 6px #020202, 
                0 0 0 9px #61ece1;
    margin-top: 10px;
  }

  .style_a {
    border-radius: 5px;
    background-color: #e02e0e;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
    /* font-weight: bold; */

}

.style_a:hover {
    cursor: pointer;
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }