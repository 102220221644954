.wrap_div * {
    font-family: "Noto Sans KR";
}

.wrap_div {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    min-height: 1080px;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover; 
}

.wrap_div > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 100px; */
    margin-bottom: 80px;
}

.wrap_div > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.wrap_div > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 56px;
    text-shadow: gray 2px 2px 2px;
    text-align: center;
}

/* .wrap_div > :nth-child(2) {
    margin-bottom: 50px;
    width: 250px;
    font-size: 20px
} */

.wrap_div > :nth-child(2) {
    color: white;
    font-size: 20px;
    font-family: "Noto Sans KR";

    /* text-align: right; */

}

.wrap_div > :nth-child(2) > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.wrap_div > :nth-child(2) > :nth-child(1) > :nth-child(1) {
    margin-bottom: 30px;
    font-size: 15px;
}

.wrap_div > :nth-child(2) > :nth-child(1) > :nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 5px;  
}

.wrap_div > :nth-child(2) > :nth-child(1) > :nth-child(2) > *{
    background-color: transparent;
    border-color: white;
    color: white;
    font-size: 15px;
    width: max-content;
}


.squad_table{
    max-width: 800px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
}

.squad_table > thead > tr {
    height: 40px ;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.squad_table > thead > tr > th {
    background-color: #5814e1;
    width: 80vw;
}


.squad_table > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
}

.squad_table > tbody > tr:hover {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}


.squad_table > tbody > tr {
    height: 60px;
    font-size: 16px;
    font-family: "Noto Sans KR";
}

.squad_table > tbody > tr > td {
    border-bottom: 1px dashed gray;
}

.squad_table > tbody > :nth-last-child(1) > td {
    border-bottom: 0px
}

.team_modal{
    position: relative;
    transform: translate( 22, 22 );
    z-index: 50;
}


@media screen and (max-width: 768px){
    /* .wrap_div > :nth-child(1) > p { font-size: 18px; } */
    
    /* .wrap_div > :nth-child(1) > h1 { font-size: 50px; } */

    .squad_table > thead > tr > th:first-child {
        width:18%
    }
    
    .squad_table > thead > tr > :nth-child(2) {
        width:56.5%
    }
    
    .squad_table > thead > tr > :nth-child(3) {
        width:25.5%
    }

    .wrap_div > :nth-child(2) { font-size: 15px; }

    .wrap_div > :nth-child(2) > :nth-child(1) > :nth-child(1) > *{ font-size: 13px; width: 100px; }

    .squad_table > thead > tr { height: 40px ; font-size: 15px;}

    .squad_table > tbody > tr { height: 40px; font-size: 13px; }
}

/* #5814e1 -> 테이블 헤더쪽 색상 */