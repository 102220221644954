.title_div {
    width: 70%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
    height: 30px;
}

.participationCompetition_div {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.participationCompetition_div > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
    margin: 10px;
}

@media screen and (max-width: 768px) {
    .title_div {
        width: 100%;
        flex-direction: column;
    }
}
