.wrap_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    /* min-height: 100vh; */
    height: 100%;
    color: #FFFFFF;
}

.wrap_div > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    /* height: 67px; */
    padding: 10px 0px;
    color: #FFFFFF;
    margin-bottom: 190px;
    width: 100vw;
}

.league1_title {
    background: linear-gradient(89.98deg, rgba(255,255,255,0) 25%, rgba(227, 109, 0, 0.546875) 50%, rgba(255, 255, 255, 0) 75%);
}
.league2_title {
    background: linear-gradient(89.98deg, rgba(255,255,255,0) 25%, rgba(0, 132, 227, 0.546875) 46.54%, rgba(255, 255, 255, 0) 75%);
}
.league3_title {
    background: linear-gradient(89.98deg,  rgba(255,255,255,0) 25%, rgba(182, 0, 227, 0.546875) 46.54%, rgba(255, 255, 255, 0) 75%);
}

.content_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rank_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
}

.rank_div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rank_div2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
}

.rank_div2 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rank_div3 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
    margin-top: 0px;
}

.rank_div3 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.rank_div4 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
    margin-top: 0px;
}

.rank_div4 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.rank_team_name{
    margin-top: 25px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    width: 200px;
}

.rank_team_reward{
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #F7941C;
}

.rank_team_border1{
    filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
    position: absolute;
    top: -34%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 90;
}

.logoTeam_img1{
    position: absolute;
    top: -34%;
    left: 50.4%;
    transform: translate(-50%, -50%);
    clip-path: polygon(50% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
    width: 164px;
    height: 164px;
    z-index: 99;
}



.rank_team_border2{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF5C00);
    position: absolute;
    top: -21%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 90;
}

.logoTeam_img2{
    position: absolute;
    top: -21%;
    left: 50.4%;
    transform: translate(-50%, -50%);
    clip-path: polygon(50% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
    width: 114px;
    height: 114px;
    /* height: 113px; */
    z-index: 99;
}

.rank_team_border3{
    filter: drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF0000);
    position: absolute;
    top: -21%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 90;
}


.rank_team_border4{
    filter: drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF0000);
    position: absolute;
    top: -21%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 90;
}



.rank_flag {
    filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
}

@media screen and (max-width: 1000px){
    .wrap_div {
        height: 100%;
    }
    .content_div {
        flex-direction: column;
    }
    .rank_div {
        margin-right: 0px;
        margin: 100px 0px;
        position: relative;
        top: -340px;
    }

    .rank_div2 {
        margin-right: 0px;
        margin: 100px 0px;
        position: relative;
        top: 390px;
    }

    .rank_div3{
        margin: 45px 0px;
    }
    .rank_div4 {
        margin-top: 100px;
        margin-bottom: 85px;
    }
}