@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://blogfonts.com/css/aWQ9MTU1MTUxJnN1Yj0xNTEmYz1wJnR0Zj1wb3N0LW5vLWJpbGxzLWNvbG9tYm8uZXh0cmFib2xkLnR0ZiZuPXBvc3Qtbm8tYmlsbHMtY29sb21iby1leHRyYWJvbGQ/Post No Bills Colombo ExtraBold.ttf);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://blogfonts.com/css/aWQ9MTU1MTUxJnN1Yj0xNTEmYz1wJnR0Zj1wb3N0LW5vLWJpbGxzLWNvbG9tYm8uZXh0cmFib2xkLnR0ZiZuPXBvc3Qtbm8tYmlsbHMtY29sb21iby1leHRyYWJvbGQ/Post No Bills Colombo ExtraBold.ttf);
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap'); */


@font-face {
  font-family: 'Noto Sans KR';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/Noto Sans KR.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Play";
  src: url("/font/Play-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Introds_wrap_div__2ffAJ {
    background-image: url(/images/intro_DS/rule_image/rules_bg01.jpg);
    background-position: center;
    /* height: 8407px; */
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw !important;
    overflow: hidden;
}

.Introds_intro_content_text__2-gLL {
    padding: 0px 40px;
    font-size: 18px;
    text-align: center;
    word-break: keep-all;
    width: 1200px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    line-height: 131.5%;
    /* or 33px */
    letter-spacing: -0.03em;
    color: #000000;
    margin-top: 70px;
}

.Introds_intro_content_text__2-gLL > span {
    font-weight: bold;
    color: #61D1E0;
}

.Introds_title_div__18bo9 * {
    font-family: "Noto Sans KR";
}

.Introds_title_div__18bo9 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 130px;
}

.Introds_title_div__18bo9 > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.015em;
    margin-bottom: 0px;
    color: #000000;
}

.Introds_title_div__18bo9 > hr {
    width: 100%;
}

.Introds_title_div_stadium__2xz5u {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -130px;
}

.Introds_title_div_stadium__2xz5u h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.015em;
    margin-bottom: 0px;
    color: #000000;
    
}

.Introds_title_div_stadium__2xz5u hr {
    width: 100%;
}

.Introds_intro_droneball_div__331Yj{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}

.Introds_intro_third_forth_img__uaC7Y{
    width: 500px;
    height: 500px;
}

.Introds_intro_title_sub_text1__104xx{
    font-size: 18px;
    position: relative;
    top: -500px;
    left: -430px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .Introds_intro_title_sub_text2__LWBKw{
    font-size: 18px;
    position: relative;
    top: -695px;
    left: 400px;
    position: relative;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }
  
  .Introds_intro_title_sub_text3__1BInB{
    font-size: 18px;
    position: relative;
    top: -280px;
    left: 350px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .Introds_intro_title_sub_text4__2bSrJ{
    font-size: 18px;
    position: relative;
    top: -530px;
    left: -310px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }


.Introds_droneBall_arrow__3SBUt{
    display: flex;
}

.Introds_droneBall_arrow1__298nC{
    position: relative;
    top: -500px;
    left: -300px;
}
.Introds_droneBall_arrow2__3Gz9o{
    position: relative;
    top: -615px;
    left: 225px;
}
.Introds_droneBall_arrow3__1ZfnG{
    position: relative;
    top: -230px;
    left: 170px;
}

.Introds_droneBall_arrow4__CUgR2{
    position: relative;
    top: -550px;
    left: -290px;
}





  
  

.Introds_explanation_div__2lWfd {
    margin-bottom: 33px;
    background-color: #A50000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 10px 0px;
}

.Introds_explanation_div__2lWfd > * {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    letter-spacing: -0.015em;
}

.Introds_explanation_div_white__1X0G7 {
    margin-bottom: 33px;
    background-color: rgba(255, 255, 255, 0.86);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 10px 0px;
}

.Introds_explanation_div_white__1X0G7 > * {
    letter-spacing: -0.015em;
    color: #020202;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

.Introds_explanation_div_white__1X0G7 * > span {
    color: #FF0000;
}

.Introds_explanation_div_white__1X0G7 * > u {
    text-decoration: none;
    /* border-bottom: 10px solid yellow; */
    box-shadow: inset 0 0px 0 white, inset 0 -5px 0 yellow
}

.Introds_rule_image_div1__I21TW{
    position: relative;
}

.Introds_rule_image_div1__I21TW > :nth-child(2) {
    position: absolute;
    top: 37%;
    left: 5%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    color: #FFBABA;
    font-weight: bold;
    font-size: 15px;
}

.Introds_rule_image_div1__I21TW > :nth-child(3) {
    position: absolute;
    top: 37%;
    right: 6%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    color: #FFBABA;
    font-weight: bold;
    font-size: 15px;
}

.Introds_rule_image__31z7p {
    width: 1200px;
}

.Introds_rule_image_div2__xuG4k{
    position: relative;
}

.Introds_rule_image_div2__xuG4k > :nth-child(2) {
    position: absolute;
    top: 47%;
    left: 32%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}

.Introds_rule_image_div2__xuG4k > :nth-child(3) {
    position: absolute;
    top: 47%;
    right: 25.5%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}


.Introds_little_title_div__3qGR4 {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}

.Introds_little_title_div__3qGR4 > p {
    margin: 0px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.Introds_little_title_div__3qGR4 > hr {
    width: 100%;
}

.Introds_circle_rules__jQpv8 {
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 200px;
    /* background-color: red; */
}

.Introds_circle_rules__jQpv8 > div {
    position: relative;
}

.Introds_circle_rule_image__3RcMC {
    width: 350px;
}

.Introds_circle_rules__jQpv8 > div > div {
    position: absolute;
    top: 50%;
    left: 48%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    font-family: "Noto Sans KR";
    height: 50%;
    /* background-color: red; */
}

.Introds_circle_rules__jQpv8 > div > div > :nth-child(2) {
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    margin-top: -30px;
    font-weight: bold;
    width: 260px;
    color: #000000;
    /* background-color: red; */
}


.Introds_circle_rules__jQpv8 > div > div > :nth-child(2) > span {
    color: #FF0000;
}




.Introds_circle_rules__jQpv8 > div > div > :nth-child(1) {
    position: relative;
    top: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Introds_circle_rules__jQpv8 > div > div > div > h1{
    font-size: 60px;
    margin: 0px;
    font-weight: bold;
    color: #000000;
}

.Introds_circle_rules__jQpv8 > div > div > div > p {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #000000;
    /* background-color: red; */
}

.Introds_circle_rules__jQpv8 > div > div > div hr {
    width: 250px;
}

.Introds_download_rulebook__JxnTH{
    font-family: "Noto Sans KR";
    color: #FFFFFF;
    font-size: 40px;
    margin-bottom: 100px;
    text-align: center;
}

.Introds_download_div__1YZiI {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 800px;
}

.Introds_download_div__1YZiI > a {
    text-decoration: none;
    background-color: #CF3C0C;
    border-radius: 5px;
    width: 150px;
    height: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Introds_download_div__1YZiI > a:hover {
    cursor: pointer;
}


.Introds_download_div__1YZiI > a > p {
    margin-bottom: 10px;
    color: #FFFFFF;
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 20px;
}

@media screen and (max-width: 1900px){
    .Introds_download_rulebook__JxnTH {
        color: #000000;
    }
}

@media screen and (max-width: 1200px) {
    .Introds_rule_image__31z7p { width: 800px; }
    .Introds_intro_content_text__2-gLL { width: 800px; }
    .Introds_intro_third_forth_img__uaC7Y {width: 400px; height: 400px;}
    .Introds_intro_title_sub_text1__104xx{ font-size: 18px; position: static; margin-top: 50px;}
    .Introds_intro_title_sub_text2__LWBKw{ font-size: 18px; position: static;}
    .Introds_intro_title_sub_text3__1BInB{ font-size: 18px; position: static;}
    .Introds_intro_title_sub_text4__2bSrJ{ font-size: 18px; position: static; }
    .Introds_droneBall_arrow__3SBUt { display: none; }
    .Introds_droneBall_arrow1__298nC{ position: static; display: none;}
    .Introds_droneBall_arrow2__3Gz9o{ position: static; display: none; margin-bottom: 15px;}
    .Introds_droneBall_arrow3__1ZfnG{ position: static; display: none; margin-bottom: 15px;}
    .Introds_title_div_stadium__2xz5u { margin-top: 130px; }


    .Introds_rule_image_div1__I21TW > :nth-child(2) {
        top: 35%;
        left: 5%;
        font-size: 15px;
    }
    .Introds_rule_image_div1__I21TW > :nth-child(3) {
        top: 35%;
        right: 4.2%;
        font-size: 15px;
    }
    .Introds_rule_image_div2__xuG4k > :nth-child(2) {
        top: 45%;
        left: 32%;
        font-size: 10px;
    }
    
    .Introds_rule_image_div2__xuG4k > :nth-child(3) {
        top: 45%;
        right: 24.5%;
        font-size: 10px;
    }
    .Introds_circle_rules__jQpv8 {
        width: 800px;
    }
    .Introds_circle_rule_image__3RcMC {
        width: 250px;
    }
    .Introds_circle_rules__jQpv8 > div > div > div h1{
        font-size: 40px;
    }
    
    .Introds_circle_rules__jQpv8 > div > div > div p {
        font-size: 10px;
        margin-bottom: 5px;
    }
    .Introds_circle_rules__jQpv8 > div > div > div hr {
        width: 150px;
    }
    .Introds_circle_rules__jQpv8 > div > div > :nth-child(2) {
        width: 230px;
        font-size: 13px;
        margin-top: -30px;
        font-weight: bold;
    }

}

@media screen and (max-width: 768px) {
    .Introds_title_div__18bo9 {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    /* .title_div > hr {
        width: 100%;
    } */


    .Introds_rule_image__31z7p { width: 350px; }
    .Introds_intro_content_text__2-gLL { width: 100%; text-align: center; word-break: normal;}
    .Introds_intro_third_forth_img__uaC7Y { width: 300px !important; height: 300px !important;}


    .Introds_rule_image_div1__I21TW > :nth-child(2) {
        top: 32%;
        left: 5%;
        font-size: 8px;
        
        /* background-color: red; */
    }
    .Introds_rule_image_div1__I21TW > :nth-child(3) {
        top: 32%;
        right: 3.2%;
        font-size: 8px;
    }
    .Introds_rule_image_div2__xuG4k > :nth-child(2) {
        top: 33%;
        left: 34%;
        font-size: 2px;
    }
    
    .Introds_rule_image_div2__xuG4k > :nth-child(3) {
        top: 33%;
        right: 19.7%;
        font-size: 2px;
    }
    
    .Introds_circle_rules__jQpv8 {
        width: 430px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 200px;
    }

    .Introds_explanation_div__2lWfd {
        width: 350px !important;
        padding: 10px;
    }
    
    .Introds_explanation_div__2lWfd p {
        word-break: keep-all;
    }

    .Introds_explanation_div_white__1X0G7 {
        width: 350px;
        padding: 10px;
    }
    
    .Introds_explanation_div_white__1X0G7 p {
        word-break: keep-all;
    }
    .Introds_download_rulebook__JxnTH {
        color: #ffffff;
    }
    .Introds_download_div__1YZiI {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 430px;
        height: 500px;
    }
}
.Topmenu_wrapper__Qc1lu {
    background-color: #d32d36;
    /* background-color: blue; */
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 79px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* overflow: hidden; */
}

.Topmenu_topmenuWrap__33AiT {
    background-color: #d32d36;
    max-width: 1900px;
    width: 100vw;
    flex-shrink: 0;
    z-index: 1000;
}


/* .largeMenuLogo { */
    /* margin-top: 10px; */
    /* width: 55px !important; */
    /* background-color: black; */
/* } */

.Topmenu_smallLogoColumn__D8PY8 {
    display: none !important;
}

/* .smallLogoColumn > img { */
    /* width: 40px; */
/* } */

.Topmenu_smallLogoColumn__D8PY8:hover {
    cursor: pointer;
}


.Topmenu_largeMenuLogo__1xNox:hover {
    cursor: pointer;
}

.Topmenu_topmenuWrap__33AiT span {
    font-weight: bold;
    color:white;
    font-size:1.2em;
}

.Topmenu_topmenuWrap__33AiT .Topmenu_item__19Q3t {
    font-weight: bold;
    font-size:1em;
    color: #d32d36;
}

.Topmenu_topmenuWrap__33AiT .Topmenu_item__19Q3t {
    font-weight: bold;
    font-size:1em;
    color: #d32d36;
}

.Topmenu_topmenuWrap__33AiT .Topmenu_menuList__2bxyy {
    width:1000px;
    justify-content: space-between;
    align-items: center;
}

.Topmenu_topmenuWrap__33AiT .Topmenu_miniMenuDiv__1-wE4 {
    display: none;
}
.Topmenu_miniMenuColumn__23h5r {
    display: none !important;
}

.Topmenu_miniMenuColumn__23h5r > div > div * {
    font-size: 12px !important;
}

.Topmenu_largeMenuDiv__2DCeY {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.Topmenu_largeMenuDiv__2DCeY > div > :nth-child(1){
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-end !important;
    padding-bottom: 10px !important;
}

.Topmenu_dropdown_tap__XsKSQ {
    font-weight: bold !important;
    text-align: center !important;
    font-size: 13px !important;
}

.Topmenu_dropdown_tap__XsKSQ:hover {
    background-color: #9B1C23 !important;
}


.Topmenu_dropdown_tap__XsKSQ:hover > div >  img {
    color: white;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.Topmenu_dropdown_tap__XsKSQ > :nth-child(2) {
    border: 0px !important;
}

.Topmenu_dropdown_tap__XsKSQ > :nth-child(2) >div {
    background-color: #d32d36 !important;
    text-align: center !important;
    width: 95px !important;
    position: relative;
    height: 40px !important;
    color: white !important;
}

.Topmenu_dropdown_tap__XsKSQ > :nth-child(2) > div > :nth-child(1) {
    color: white;
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
}


.Topmenu_dropdown_tap__XsKSQ > :nth-child(2) >div:hover {
    background-color: #9B1C23 !important;
}

.Topmenu_dropdown_tap__XsKSQ > :nth-child(2) > div >  span {
    font-family: "Noto Sans KR";
    font-weight: bold !important;
    font-style: normal;
    font-size: 13px;
    line-height: 15px;
    position: absolute !important;
    display: block !important;
    
    width: 95px !important;
    word-break: break-all !important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    color: white !important;
}

.Topmenu_dropdown_tap__XsKSQ > :nth-child(2) > div > :nth-child(2) {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
    border: none;
}

.Topmenu_dropdown_tap__XsKSQ > :nth-child(2) > div > :nth-child(2) > div{
    background-color: #d32d36 !important;
    font-family: "Noto Sans KR" !important;
    font-style: normal !important;
    font-weight: bold !important;
    color: white !important;
    font-size: 13px !important;
    line-height: 15px !important;
    left: 10px;
    text-align: center !important;
}

.Topmenu_dropdown_tap__XsKSQ > :nth-child(2) > div > :nth-child(2) > div:hover{
    background-color: #9B1C23 !important;
}

.Topmenu_dropdown_tap__XsKSQ > :nth-child(2) > div > :nth-child(2)::after{
    background-color: #d32d36 !important;
    left: 7px !important;
}

.Topmenu_miniMenuDiv__1-wE4 > div > div {
    background-color: #d32d36 !important;
}

.Topmenu_miniMenuDiv__1-wE4 > div > div > div {
    color: white !important;
}




.Topmenu_style_a1__27o7n {
    border-radius: 5px;
    background-color: #DFA4A4;
    position: relative;
    display: inline-block !important;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    transition: .5s;
    letter-spacing: 0px;
    white-space: nowrap;
}

.Topmenu_style_a1__27o7n:hover {
    background: #DFA4A4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #DFA4A4,
                0 0 25px #DFA4A4,
                0 0 50px #DFA4A4,
                0 0 100px #DFA4A4;
    cursor: pointer;
  }

  .Topmenu_style_a2__lOOyj {
    border-radius: 5px;
    background-color: #DFA4A4;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    /* overflow: hidden; */
    transition: .5s;
    letter-spacing: 0px;
    margin-right: 5px;
    white-space: nowrap;
    font-family: "Noto Sans KR";
    /* width: 69px; */
}

.Topmenu_style_a2__lOOyj:hover {
    background: #DFA4A4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #DFA4A4,
                0 0 25px #DFA4A4,
                0 0 50px #DFA4A4,
                0 0 100px #DFA4A4;
    cursor: pointer;
  }


  .Topmenu_style_a3__3jdKV {
    border-radius: 5px;
    background-color: #DFA4A4;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    /* overflow: hidden; */
    transition: .5s;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
    white-space: nowrap;
    /* width: 59px; */
}

.Topmenu_style_a3__3jdKV:hover {
    background: #DFA4A4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #DFA4A4,
                0 0 25px #DFA4A4,
                0 0 50px #DFA4A4,
                0 0 100px #DFA4A4;
    cursor: pointer;

  }

.Topmenu_profile_div__18fYX > a > span{
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    color: white;
}

.Topmenu_login_dropdown__3lT4e {
    padding-right: 14px;
    position: relative !important;
}


.Topmenu_login_dropdown__3lT4e > div > :nth-child(1) > span  {
    display: static !important;
    color: #010101;
    font-size: 14px;
    display: none;
}

.Topmenu_login_dropdown__3lT4e > div > :nth-child(3) {
    position: absolute !important;
    background-color: white !important;
    top: 90px !important;
    left: 5px !important;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

}

.Topmenu_login_dropdown__3lT4e > div > :nth-child(3) > div > img {
    width: 30px !important;
}

.Topmenu_login_dropdown__3lT4e > div > :nth-child(3) > div > span {
    font-family: "Noto Sans KR" !important;
    font-size: 13px !important;
    color: black;
    font-weight: 400 !important;
    text-align: center !important;
    width: 100% !important;
    display: inline-block;
}

.Topmenu_login_dropdown__3lT4e > div > :nth-child(3) > :nth-child(1) > span {
    display: inline;
}




.Topmenu_largeMenuLogoColumn__1QsZ2 {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: left !important;
}

@media screen and (min-width: 769px) {
    .Topmenu_topmenuWrap__33AiT .Topmenu_miniMenuDiv__1-wE4 {
        display: none;
    }

    .Topmenu_topmenuWrap__33AiT .Topmenu_menuList__2bxyy {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .Topmenu_topmenuWrap__33AiT {
        max-width: 100vw;
        min-width: 0px;
        width: 100vw;
    }

    .Topmenu_topmenuWrap__33AiT > :nth-child(1){
        display: none;
    }
    .Topmenu_miniMenuColumn__23h5r {
        display: flex !important;
    
    }
    .Topmenu_largeMenuDiv__2DCeY{
        display: none;
    }
    .Topmenu_largeMenuLogo__1xNox{
        display: none;
    }
    .Topmenu_largeMenuLogoColumn__1QsZ2{
        display: none !important;
    }
    .Topmenu_smallLogoColumn__D8PY8 {
        display: flex !important;
    }

    .Topmenu_largeMenuDivColumn__ITCQf{
        display: none !important;
    }

    .Topmenu_topmenuWrap__33AiT .Topmenu_miniMenuDiv__1-wE4 {
        padding: 15px;
        display: flex;
        width:100%;
        align-items: center;
    }

    .Topmenu_login_dropdown__3lT4e {
        padding-right: 0px;
    }

    .Topmenu_login_dropdown__3lT4e > div > :nth-child(3) {
        left: -10px !important;    
    }

}

/* @font-face {
    font-family: 'Noto Sans KR';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/Noto Sans KR.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */

.Footer_wrap_div__1-huJ * {
    font-family: "Noto Sans KR";
    color: white;
    font-size: 0.8em;
}

.Footer_wrap_div__1-huJ {
    background-color: #D32D36;
    background-image: url('/images/footer/background_01.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    flex-shrink: 0;
    width: 100vw;
    position: relative;
    z-index: 99;
}

.Footer_wrap_div__1-huJ > :nth-child(1) > :nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    /* margin-top: 15px; */
}

.Footer_wrap_div__1-huJ > :nth-child(1) > :nth-child(1) > span {
    font-style: normal;
    font-weight: bold;
    font-size: 1.8em;
    text-align: center;
    line-height: 27px;
    margin-left: 15px;
}

.Footer_wrap_div__1-huJ > :nth-child(1) > :nth-child(2) {
    margin-top: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
}

.Footer_wrap_div__1-huJ > :nth-child(2) {
    margin-top: 40px;
}

.Footer_wrap_div__1-huJ > :nth-child(2) > div > img:hover {
 cursor: pointer;
}


.Footer_lang_div__Az0hM * {
    color:black;
}

.Footer_lang_div__Az0hM {
    display: flex;
    /* background-color: white; */
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Footer_wrap_div__1-huJ > :nth-child(1) > :nth-child(2) > a {
    color: #FFFFFF;
    font-size: 9pt;
    border-bottom: 1px solid #FFFFFF;
    padding: 2px;
    margin-left: 60px;
}


@media screen and (max-width: 768px) {
    .Footer_wrap_div__1-huJ { flex-direction: column; word-break: keep-all;}
    .Footer_wrap_div__1-huJ > :nth-child(1) { display: flex; flex-direction: column; align-items: center;}
    .Footer_wrap_div__1-huJ > :nth-child(1) > p { text-align: center; }
}
.IntroKDSA_intro_tap_box__2sYjH {
    background-position: center;
    padding-top: 180px;
    padding-bottom: 280px;
    background-image: url("/images/intro/bg_01.jpg");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.IntroKDSA_img_div__3o3yI {
    display: flex;
    flex-direction: row;
    width: 800px;
    justify-content: space-between;
    margin-top: 80px;
}

.IntroKDSA_intro_tap_box__2sYjH > p {
    font-size: 20px;
    font-family: "Noto Sans KR";
    font-style: normal;
    text-align: center;
    color: white;
}

.IntroKDSA_intro_tap_box__2sYjH > div {
    font-size: 20px;
    font-family: "Noto Sans KR";
    font-style: normal;
    text-align: center;
    color: white;
}

.IntroKDSA_intro_tap_box__2sYjH .IntroKDSA_count_txt__3G7gu {
    font-size: 98px;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-weight: bold;
    color: #32faea;
    padding: 0;
    margin: 0;
}

.IntroKDSA_intro_tap_box__2sYjH .IntroKDSA_count_name__3Id1S {
    font-size: 28px;
    font-family: "Noto Sans KR";
    font-weight: bold;
    text-shadow: 2px 2px 2px gray;
    color: white;
    padding: 0;
    margin: 0;
}

.IntroKDSA_intro_tap_box__2sYjH .IntroKDSA_text_div__aqriL {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.IntroKDSA_text_div__aqriL > :nth-child(1) {
    font-family: "Noto Sans KR";
    color: white;
    font-weight: normal;
    text-shadow: gray 2px 2px 2px;
    font-size: 18px;
    margin-bottom: 0px;
}

.IntroKDSA_text_div__aqriL > :nth-child(2) {
    color: white;
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 56px;
    text-shadow: gray 2px 2px 2px;
    margin-top: 5px;
    margin-bottom: 10px;
    overflow: hidden;
}

.IntroKDSA_text_div__aqriL > :nth-child(3) {
    max-width: 1200px;
    padding: 0px 40px;
}

.IntroKDSA_orgChart_div__2BN6b {
    margin-top: 70px;
    border-radius: 5px;
}

.IntroKDSA_squad_table__2G2eA {
    max-width: 1000px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
    margin-top: 70px;
}

.IntroKDSA_squad_table__2G2eA > thead > tr {
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.IntroKDSA_squad_table__2G2eA > thead > tr > th {
    background-color: #5814e1;
    width: 80vw;
}

.IntroKDSA_squad_table__2G2eA > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
}

.IntroKDSA_squad_table__2G2eA > tbody > tr {
    height: 60px;
    font-size: 16px;
    font-family: "Noto Sans KR";
}

.IntroKDSA_squad_table__2G2eA > tbody > tr > td {
    border-bottom: 1px dashed gray;
}

.IntroKDSA_squad_table__2G2eA > tbody > :nth-last-child(1) > td {
    border-bottom: 0px;
}

@media screen and (max-width: 1200px) {
    .IntroKDSA_text_div__aqriL > :nth-child(3) {
        width: 800px;
    }
    .IntroKDSA_orgChart_div__2BN6b {
        width: 80%;
    }
}

@media screen and (max-width: 768px) {
    .IntroKDSA_intro_tap_box__2sYjH .IntroKDSA_text_div__aqriL > h1 {
        font-size: 40px;
        overflow: hidden;
    }

    .IntroKDSA_text_div__aqriL > :nth-child(2) {
        width: 430px;
        overflow: hidden;
    }
    .IntroKDSA_text_div__aqriL > :nth-child(3) {
        width: 430px;
        overflow: hidden;
    }

    .IntroKDSA_intro_tap_box__2sYjH .IntroKDSA_img_div__3o3yI {
        flex-direction: column;
    }

    .IntroKDSA_intro_tap_box__2sYjH .IntroKDSA_img_div__3o3yI > div {
        margin-bottom: 30px;
    }

    .IntroKDSA_squad_table__2G2eA {
        width: 80vw;
    }

    .IntroKDSA_squad_table__2G2eA > thead > tr {
        height: 40px;
        font-size: 12px;
    }

    .IntroKDSA_squad_table__2G2eA > tbody > tr {
        height: 40px;
        font-size: 16px;
    }
}

.IntroStatus_wrap_div__2Pqxl * {
    font-family: "Noto Sans KR";
}

.IntroStatus_wrap_div__2Pqxl {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    min-height: 1080px;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}

.IntroStatus_wrap_div__2Pqxl > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.IntroStatus_wrap_div__2Pqxl > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.IntroStatus_wrap_div__2Pqxl > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 56px;
    text-shadow: gray 2px 2px 2px;
    text-align: center;
}

.IntroStatus_province_div__1bE29 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 0px;
}


.IntroStatus_province_chief_table__1wSam{
    max-width: 1000px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
}

.IntroStatus_province_chief_table__1wSam > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    font-size: 16px;
}

.IntroStatus_province_chief_table__1wSam > tbody > tr > :nth-child(1) {
    height: 60px;
    font-size: 18px;
    font-weight: bold;
}

.IntroStatus_squad_table__1YpLZ{
    max-width: 1000px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
}

.IntroStatus_squad_table__1YpLZ > thead > tr {
    height: 40px ;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.IntroStatus_squad_table__1YpLZ > thead > tr > th {
    background-color: #5814e1;
    width: 80vw;
}

.IntroStatus_squad_table__1YpLZ > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
}

.IntroStatus_squad_table__1YpLZ > tbody > tr {
    height: 60px;
    font-size: 16px;
    font-family: "Noto Sans KR";
}

.IntroStatus_squad_table__1YpLZ > tbody > tr > td {
    border-bottom: 1px dashed gray;
}

.IntroStatus_squad_table__1YpLZ > tbody > :nth-last-child(1) > td {
    border-bottom: 0px
}

.IntroStatus_testtest__TyH1d{
    display: none;
}

@media screen and (max-width: 768px){

    /* .wrap_div > :nth-child(1) > h1 { font-size: 30px; } */

    .IntroStatus_wrap_div__2Pqxl > :nth-child(2) { font-size: 15px; }

    .IntroStatus_wrap_div__2Pqxl > :nth-child(2) > :nth-child(1) > :nth-child(1) > *{ font-size: 13px; }

    .IntroStatus_province_div__1bE29 > h1 { font-size: 20px; }

    .IntroStatus_province_chief_table__1wSam > tbody > tr > :nth-child(1) { height: 40px; font-size: 13px; }

    .IntroStatus_province_chief_table__1wSam > tbody > tr {font-size: 12px;}

    .IntroStatus_squad_table__1YpLZ { width: 80vw; }
    
    .IntroStatus_squad_table__1YpLZ > thead > tr { height: 40px ; font-size: 18px;}

    .IntroStatus_squad_table__1YpLZ > tbody > tr { height: 40px; font-size: 16px; }
}
.TeamModal_wrap_modal__2fl9T > div{
  /* background: radial-gradient(circle, rgba(68,69,154,1) 0%, rgba(11,26,42,1) 66%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.TeamModal_content_div__2Sz8x {
    /* margin: 50px, 0px; */
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 50px 0px; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate( -50%, -50% ); */
}

.TeamModal_content_div__2Sz8x > p {
    color: white;
    font-family: "Noto Sans KR";
    font-size: 25px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #61ece1;
}

.TeamModal_content_div__2Sz8x > h1 {
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 30px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.TeamModal_content_div__2Sz8x > :nth-child(3) {
    /* background-color: blue; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.TeamModal_team_logo_div__3cfus{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
}

.TeamModal_team_logo_div__3cfus > img{
    width: 200px;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgba(97, 236, 224, 0.3), 
                0 0 0 6px #020202, 
                0 0 0 9px #61ece1;
}

.TeamModal_team_logo_div__3cfus > p{
    color: white;
    font-family: "Noto Sans KR";
    font-size: 25px;
    margin-top: 30px;
    text-align: center;
}

.TeamModal_member_div__3kU1V{
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 700px;
}

.TeamModal_member_profile__1z3ti {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  position: relative;
}

/* .member_profile:hover {
  background-color: red;

} */

.TeamModal_member_profile__1z3ti:hover {
  cursor: pointer;
   
}
/* generated element for shine effect.
 * normal state is semi-transparent
 * white but with zero width. Set no
 * transition here for no mouse-leave
 * animations. Otherwise the effect
 * will play in reverse when your mouse
 * leaves the element
 */
.TeamModal_member_profile__1z3ti:after {
    content: "";
    position: absolute;
    top: 120px;
    left: 0px;
    width: 0%;
    height: 30%;
    background-color: rgba(255,255,255,0.4);
    transition: none;
   
}
/* on hover we animate the width to
 * 100% and opacity to 0 so the element
 * grows and fades out
 */
.TeamModal_member_profile__1z3ti:hover:after {
    width: 120%;
    background-color: rgba(255,255,255,0);
    transition: all 0.3s ease-out;
}

.TeamModal_member_profile__1z3ti > img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(97, 236, 224, 0.3), 
              0 0 0 6px #020202, 
              0 0 0 9px #61ece1;
  margin-bottom: 20px;
}

.TeamModal_member_profile__1z3ti > p{
  margin: 0px;
  font-family: "Noto Sans KR";
}

.TeamModal_member_profile__1z3ti > :nth-child(2){
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.TeamModal_member_profile__1z3ti > :nth-child(3){
  font-size: 12px;
  color: #61ece1;
  /* font-weight: bold; */
}

.TeamModal_style_a__2WUwr {
    border-radius: 5px;
    background-color: #e02e0e;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
    /* font-weight: bold; */

}

.TeamModal_style_a__2WUwr:hover {
    cursor: pointer;
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }


  @media screen and (max-width: 1200px){
    .TeamModal_wrap_modal__2fl9T { width: 800px !important; }
    .TeamModal_content_div__2Sz8x { width: 800px; }
    .TeamModal_content_div__2Sz8x > :nth-child(3) { flex-direction: column; }
    .TeamModal_team_logo_div__3cfus{ margin-right: 0px; margin-bottom: 50px; }
  }

  @media screen and (max-width: 768px) {
    .TeamModal_wrap_modal__2fl9T { width: 430px !important; }
    .TeamModal_content_div__2Sz8x { width: 430px; }
    .TeamModal_member_profile__1z3ti { width: 240px; margin: 20px 0px;}
  }
.PlayerInfoModal_content_div__36ZtB {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    /* background-color: blue; */
}

.PlayerInfoModal_member_profile__25Qsd > img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgba(97, 236, 224, 0.3), 
                0 0 0 6px #020202, 
                0 0 0 9px #61ece1;
    margin-top: 10px;
  }

  .PlayerInfoModal_style_a__2bv59 {
    border-radius: 5px;
    background-color: #e02e0e;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
    /* font-weight: bold; */

}

.PlayerInfoModal_style_a__2bv59:hover {
    cursor: pointer;
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }
.IntroMascot_secondtab__oCaVP {
    background-position: center;
    /* min-height: 1080px; */
    height: 100%;
    min-height: 100vh;
    width: 100vw !important;
    /* padding: 180px 0em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.IntroMascot_secondtab__oCaVP * {
    color:white;
}

.IntroMascot_secondtab__oCaVP > div {
    width: 1200px;
}


.IntroMascot_thirdtab__1p0N2 > div {
    width: 1200px;
}

.IntroMascot_intro_third_forth_img__2priI{
    width: 350px;
}

.IntroMascot_intro_third_forth_img2__2hKXh{
    width: 400px;
}

.IntroMascot_secondtab__oCaVP > div > :nth-child(1) {
    margin-right: 60px;
}

.IntroMascot_thirdtab__1p0N2 > div > :nth-child(1) {
    margin-right: 60px;
}

.IntroMascot_secondtab__oCaVP > div > :nth-child(2) > :nth-child(1) > h1 {
    text-align: center;
    display: inline;
    font-size: 56px;
    font-family: "Noto Sans KR";
    text-shadow: gray 2px 2px 2px;
    margin-top: 5px;
}
.IntroMascot_secondtab__oCaVP > div > :nth-child(2) > :nth-child(2) > div{
    font-family: "Noto Sans KR";
    /* margin-top: 30px; */
    color: white;
    width: 700px;
    font-size: 20px;
    line-height: 160%;
}


.IntroMascot_thirdtab__1p0N2 > div > :nth-child(1) > :nth-child(1) > h1 {
    text-align: center;
    display: block;
    font-size: 56px;
    font-family: "Noto Sans KR";
    text-shadow: gray 2px 2px 2px;
    margin-top: 5px;
    word-break: keep-all;
}

.IntroMascot_thirdtab__1p0N2 > div > :nth-child(1) > :nth-child(2) > div {
    color: white;
    width: 700px;
    line-height: 160%;
    font-size: 20px;
    line-height: 160%;
    font-family: "Noto Sans KR";
}
/* style={{ textAlign: "left", display: "inline", fontSize: "1.3em" }} */
/*  */
.IntroMascot_thirdtab__1p0N2 {
    background-position: center;
    /* min-height: 1080px; */
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    /* padding: 180px 0em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.IntroMascot_thirdtab__1p0N2 * {
    color:white;
}

/*  */

.IntroMascot_nav_link_down__1zsl7 {
    position: absolute;
    bottom: 10px;
    /* background-color: red; */
}


.IntroMascot_nav_link_down__1zsl7:hover {
    cursor: pointer;
}

/* .nav_link_down > i:hover {
    color: #D32D36;
} */

.IntroMascot_nav_link_up__Z6okq {
    position: absolute;
    top: 10px;
}

.IntroMascot_nav_link_up__Z6okq:hover {
    cursor: pointer;
}
/* 
.nav_link_up > i:hover {
    color: #D32D36;
} */

.IntroMascot_intro_tap_box__2fPUI h1 {
    margin: 0;
    font-size:5em
}

.IntroMascot_intro_tap_box__2fPUI .IntroMascot_text_div__2Q-Kk {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* max-width:1200px;
    min-width:600px; */
}

/* @media screen and (max-width: 1200px){
    .intro_third_forth_img{
        width: 300px;
    }

    .secondtab > div > :nth-child(2) > :nth-child(2) > div{
        width: 500px;
    }
    
    .thirdtab > div > :nth-child(1) > :nth-child(2) > div{
        width: 500px;
    }
} */


@media screen and (max-width: 768px){
    .IntroMascot_secondtab__oCaVP { height: -webkit-max-content; height: max-content; padding: 180px 0px;}
    .IntroMascot_thirdtab__1p0N2 { height: -webkit-max-content; height: max-content;}
    .IntroMascot_secondtab__oCaVP > div > :nth-child(1) { margin-right: 0px; }
    .IntroMascot_thirdtab__1p0N2 > div > :nth-child(1) { margin-right: 0px; }

    .IntroMascot_secondtab__oCaVP > div > :nth-child(2) > :nth-child(1) > h1 {
        /* color: red !important; */
        font-size: 3em;
        width: 85vw;
        word-break: break-all;
    }

    .IntroMascot_thirdtab__1p0N2 > div > :nth-child(1) > :nth-child(1) > h1 {
        /* color: red !important; */
        font-size: 3em;
        width: 85vw;
        word-break: break-all;
    }

    .IntroMascot_secondtab__oCaVP > div > :nth-child(2) > :nth-child(2) > div{
        color: white;
        width: 100vw;
        font-size: 1.2em;
        line-height: 160%;
    }

    .IntroMascot_secondtab__oCaVP > div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 430px;
    }
    
    .IntroMascot_secondtab__oCaVP > div > :nth-child(2) {
        margin-top:50px; 
    }

    .IntroMascot_secondtab__oCaVP {
        width: 350px;
    }

    .IntroMascot_intro_third_forth_img__2priI {
        width: 280px;
    }

    .IntroMascot_intro_third_forth_img2__2hKXh{
        width: 280px;
        margin-bottom: 40px;
    }

    /* .secondtab > div > :nth-child(2) > :nth-child(2) {
        width: 90vw;
    } */

    .IntroMascot_thirdtab__1p0N2 { padding: 120px 0px 30px 0px !important; }
    .IntroMascot_thirdtab__1p0N2 > div > :nth-child(1) > :nth-child(1) {
        width: 400px;
    }

    .IntroMascot_thirdtab__1p0N2 > div > :nth-child(1)  {
        margin-bottom: 180px;
    }

    .IntroMascot_thirdtab__1p0N2 > div > :nth-child(1) > :nth-child(2) > div {
        color: white;
        font-size: 1.2em;
        line-height: 160%;
        /* background-color: red; */
        width: 400px;
    }

    .IntroMascot_thirdtab__1p0N2> div {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width:90vw;
    }

    .IntroMascot_thirdtab__1p0N2 > div > :nth-child(2) {
        margin-top:50px; 
    }

    .IntroMascot_thirdtab__1p0N2 > .IntroMascot_intro_third_forth_img__2priI {
        margin-bottom: 60px;
        width: 350px;
    }


    .IntroMascot_thirdtab__1p0N2 > div > :nth-child(1) > :nth-child(2) {
        width: 460px;
    }

    .IntroMascot_nav_link_down__1zsl7 { display: none; }
    .IntroMascot_nav_link_up__Z6okq { display: none; }
}
.IntroLocation_forthtab__3umf8{
    background-position: center;
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    padding: 180px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.IntroLocation_forthtab__3umf8 * {
    color:white;
}

.IntroLocation_forthtab__3umf8> :nth-child(1) > h1 {
    font-size: 56px;
    font-family: "Noto Sans KR";
    font-weight: bold;
    text-shadow: gray 2px 2px 2px;
    margin-bottom: 80px;
}

.IntroLocation_forthtab__3umf8> :nth-child(1) > p {
    color: white;
    font-size: 23px;
    line-height: 160%;
    margin-bottom: 50px;
    word-break: keep-all;
    font-family: "Noto Sans KR";
    font-weight: bold;
    text-shadow: 2px 2px 2px gray;
}

.IntroLocation_map_div__zFCTZ{
    max-width: 900px;
    width: 100%;
    height: 500px;
    /* box-shadow:#020202 5px 5px 5px; */
    /* border: 1px ridge #020202; */
    border-radius: 2px;
    margin-bottom: 50px;
}

.IntroLocation_bus_car_div__14l12{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    width: 90vw;
    /* background-color: red; */
}

.IntroLocation_how_come__3F_bM {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    text-align: center;
}
.IntroLocation_how_come__3F_bM > :nth-child(1){
    padding-top: 50px;
    font-size: 100px;
    margin-right: 20px;
    text-shadow: 2px 2px 2px gray;
}

.IntroLocation_how_come__3F_bM > :nth-child(2) > div{
    color: white;
    font-size: 18px;
    font-family: "Noto Sans KR";
    font-weight: bold;
    line-height: 160%;
}

.IntroLocation_how_come__3F_bM > :nth-child(2) > h3{
    font-size: 30px;
    font-family: "Noto Sans KR";
    margin-bottom: 15px;
    font-weight: bold;
    text-shadow: 2px 2px 2px gray;
}

.IntroLocation_vertical_line__15Ip9{
    width: 1px;
    background-color: silver;
    height: 100%;
    float: left;
    border: 2px ridge silver ;
    border-radius: 2px;
  }


@media screen and (max-width: 768px){
    .IntroLocation_forthtab__3umf8> :nth-child(1) > h1 {
        font-size: 50px;
    }
    
    .IntroLocation_forthtab__3umf8> :nth-child(1) > p {
        font-size: 20px;
        text-align: center;
    }

    .IntroLocation_map_div__zFCTZ{
        height: 300px;
        width: 80vw;
        /* box-shadow: 1px 1px 1px; */
        margin-bottom: 50px;
    }

    .IntroLocation_bus_car_div__14l12{
        flex-direction: column;
        text-align: center;
    }

    .IntroLocation_how_come__3F_bM {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .IntroLocation_how_come__3F_bM > :nth-child(1){
        /* padding-top: 80px; */
        padding-left: 20px;
        font-size: 60px;
        /* margin-right: 100px; */
        margin-bottom: 70px;
        align-items: center;
        justify-content: center;
    }

    .IntroLocation_vertical_line__15Ip9{
        display: none;
    }
}
.Membersignup_modalLabel__j2lNg.Membersignup_modalLabel__j2lNg>span{ color: white; }

.Membersignup_invalid__3Uulk{
    color: red;
    margin-top: 7px;
    margin-left: 5px;
    font-size: 15px;
}

.Membersignup_valid__3W27t{
    color: white;
    margin-top: 7px;
    margin-left: 5px;
    font-size: 15px;
}

/* style={{ minHeight: "80vh", width: "800px",backgroundSize: "cover", display: "flex", justifyContent: "center", alignItems: "center"}} */
.Membersignup_login_form_wrap__2raO4{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 5;
    /* opacity: 0.7; */
    border: 0px;
    box-shadow: 0 0 30px white !important;
    background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
    /* background-color: ; */
    border-radius: 15px;
}


.Membersignup_login_form_div__3hJ4m{
    border-radius: 15px;
    padding: 50px;
    /* opacity: 0.7; */
    border: 0px;
    box-shadow: 0 0 30px white !important;
    background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
  }

/* 배경 starts, twinkling, cloud 이벤트 */
@keyframes Membersignup_move-twink-back__2woPI {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes Membersignup_move-twink-back__2woPI {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes Membersignup_move-clouds-back__3xqMY {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes Membersignup_move-clouds-back__3xqMY {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.Membersignup_stars__1XTst, .Membersignup_twinkling___xJqL, .Membersignup_clouds__t2aS_ {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  min-height: 1600px;
  height: 100vh;
  display:block;
}

.Membersignup_stars__1XTst {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.Membersignup_twinkling___xJqL{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:Membersignup_move-twink-back__2woPI 200s linear infinite;
  animation:Membersignup_move-twink-back__2woPI 200s linear infinite;
}

.Membersignup_clouds__t2aS_{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:Membersignup_move-clouds-back__3xqMY 200s linear infinite;
  animation:Membersignup_move-clouds-back__3xqMY 200s linear infinite;
}

/* ==================== */

/* ============================ 버튼 이벤트 a태그 */
.Membersignup_style_a1__1hJ08 {
    border-radius: 5px;
    background-color: #4B89DC;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.Membersignup_style_a1__1hJ08:hover {
    background: #4B89DC;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #4B89DC,
                0 0 25px #4B89DC,
                0 0 50px #4B89DC,
                0 0 100px #4B89DC;
  }

  .Membersignup_style_a2__3YUvX {
    border-radius: 5px;
    background-color: #88DC4B;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.Membersignup_style_a2__3YUvX:hover {
    background: #88DC4B;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #88DC4B,
                0 0 25px #88DC4B,
                0 0 50px #88DC4B,
                0 0 100px #88DC4B;
  }

  .Membersignup_style_a3__22XLM {

    background-color: #e02e0e;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.Membersignup_style_a3__22XLM:hover {
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }

.Membersignup_style_a__3NG6T> span {
    position: absolute;
    display: block;
  }

.Membersignup_style_a__3NG6T >:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    -webkit-animation: Membersignup_btn-anim1__3l8lq 1s linear infinite;
            animation: Membersignup_btn-anim1__3l8lq 1s linear infinite;
  }

  .Membersignup_mouse_hover__2nWur:hover{
    cursor: pointer;
  }

  @-webkit-keyframes Membersignup_btn-anim1__3l8lq {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  @keyframes Membersignup_btn-anim1__3l8lq {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  .Membersignup_style_a__3NG6T > :nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    -webkit-animation: Membersignup_btn-anim2__2UOls 1s linear infinite;
            animation: Membersignup_btn-anim2__2UOls 1s linear infinite;
    -webkit-animation-delay: .25s;
            animation-delay: .25s
  }
  
  @-webkit-keyframes Membersignup_btn-anim2__2UOls {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  @keyframes Membersignup_btn-anim2__2UOls {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .Membersignup_style_a__3NG6T > :nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    -webkit-animation: Membersignup_btn-anim3__3t6zk 1s linear infinite;
            animation: Membersignup_btn-anim3__3t6zk 1s linear infinite;
    -webkit-animation-delay: .5s;
            animation-delay: .5s
  }
  
  @-webkit-keyframes Membersignup_btn-anim3__3t6zk {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  @keyframes Membersignup_btn-anim3__3t6zk {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .Membersignup_style_a__3NG6T > :nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    -webkit-animation: Membersignup_btn-anim4__ZodYu 1s linear infinite;
            animation: Membersignup_btn-anim4__ZodYu 1s linear infinite;
    -webkit-animation-delay: .75s;
            animation-delay: .75s
  }
  
  @-webkit-keyframes Membersignup_btn-anim4__ZodYu {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  
  @keyframes Membersignup_btn-anim4__ZodYu {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  /* ================================= */


@media (min-width:500px) and (max-width: 768px) {
    .Membersignup_login_form_wrap__2raO4 {width: 500px;}
    .Membersignup_login_form_div__3hJ4m { width: 500px;}
    .Membersignup_profileRow__22Zam {
      width: 400px;
      overflow-x: hidden;
    }
}

@media (max-width: 499px) {
  .Membersignup_login_form_wrap__2raO4 {width: 320px;}
  .Membersignup_login_form_div__3hJ4m { width: 320px;}
  .Membersignup_profileRow__22Zam {
    width: 250px;
    overflow-x: hidden;
  }
}
.MemberSignUpGeral_textPolicy__2Z-ei.MemberSignUpGeral_textPolicy__2Z-ei>span{ 
    color: white;
    font-size: 15px;
    padding: 10px;
    margin-top: 10px;
    font-family: "Noto Sans KR";
    
}

.MemberSignUpGeral_login_form_wrap__3l5T_{
    position: relative;
    z-index: 5;
    max-width: 1000px;
    width: 80vw;
    /* background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important; */
}

.MemberSignUpGeral_login_form_div__3dOaN{
    /* padding-bottom: 50px !important; */
    padding: 0px 50px 50px 50px !important;
    border: 0px;
    box-shadow: 0 0 30px white !important;
    /* background: linear-gradient(235deg, #020202, #565656, #020202) !important; */
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
  }

/* ============================ 버튼 이벤트 a태그 */
.MemberSignUpGeral_style_a1__2G5sd {
  border-radius: 5px;
  background-color: #88DC4B;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 0px
}

.MemberSignUpGeral_style_a1__2G5sd:hover {
    background: #88DC4B;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #88DC4B,
                0 0 25px #88DC4B,
                0 0 50px #88DC4B,
                0 0 100px #88DC4B;
  }

  .MemberSignUpGeral_style_a2__2mhkO {
    background-color: #e02e0e;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 20px;
    letter-spacing: 0px
}

.MemberSignUpGeral_style_a2__2mhkO:hover {
  background: #e02e0e;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #e02e0e,
              0 0 25px #e02e0e,
              0 0 50px #e02e0e,
              0 0 100px #e02e0e;
  }

.MemberSignUpGeral_style_a__2evsI > span {
    position: absolute;
    display: block;
  }

.MemberSignUpGeral_style_a__2evsI >:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    -webkit-animation: MemberSignUpGeral_btn-anim1__1q0ve 1s linear infinite;
            animation: MemberSignUpGeral_btn-anim1__1q0ve 1s linear infinite;
  }

  .MemberSignUpGeral_mouse_hover__3PoIv:hover{
    cursor: pointer;
  }

  @-webkit-keyframes MemberSignUpGeral_btn-anim1__1q0ve {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  @keyframes MemberSignUpGeral_btn-anim1__1q0ve {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  .MemberSignUpGeral_style_a__2evsI > :nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    -webkit-animation: MemberSignUpGeral_btn-anim2__cdr62 1s linear infinite;
            animation: MemberSignUpGeral_btn-anim2__cdr62 1s linear infinite;
    -webkit-animation-delay: .25s;
            animation-delay: .25s
  }
  
  @-webkit-keyframes MemberSignUpGeral_btn-anim2__cdr62 {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  @keyframes MemberSignUpGeral_btn-anim2__cdr62 {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .MemberSignUpGeral_style_a__2evsI > :nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    -webkit-animation: MemberSignUpGeral_btn-anim3__m7bFp 1s linear infinite;
            animation: MemberSignUpGeral_btn-anim3__m7bFp 1s linear infinite;
    -webkit-animation-delay: .5s;
            animation-delay: .5s
  }
  
  @-webkit-keyframes MemberSignUpGeral_btn-anim3__m7bFp {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  @keyframes MemberSignUpGeral_btn-anim3__m7bFp {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .MemberSignUpGeral_style_a__2evsI > :nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    -webkit-animation: MemberSignUpGeral_btn-anim4__8sk_w 1s linear infinite;
            animation: MemberSignUpGeral_btn-anim4__8sk_w 1s linear infinite;
    -webkit-animation-delay: .75s;
            animation-delay: .75s
  }
  
  @-webkit-keyframes MemberSignUpGeral_btn-anim4__8sk_w {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  
  @keyframes MemberSignUpGeral_btn-anim4__8sk_w {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  /* ================================= */

/* 배경 starts, twinkling, cloud 이벤트 */
@keyframes MemberSignUpGeral_move-twink-back__1eq5y {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes MemberSignUpGeral_move-twink-back__1eq5y {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes MemberSignUpGeral_move-clouds-back__a_WAb {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes MemberSignUpGeral_move-clouds-back__a_WAb {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.MemberSignUpGeral_stars__2n0v8, .MemberSignUpGeral_twinkling__3sr19, .MemberSignUpGeral_clouds__savER {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  min-height: 1300px;
  display: block;
}

.MemberSignUpGeral_stars__2n0v8 {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.MemberSignUpGeral_twinkling__3sr19{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:MemberSignUpGeral_move-twink-back__1eq5y 200s linear infinite;
  animation:MemberSignUpGeral_move-twink-back__1eq5y 200s linear infinite;
}

.MemberSignUpGeral_clouds__savER{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:MemberSignUpGeral_move-clouds-back__a_WAb 200s linear infinite;
  animation:MemberSignUpGeral_move-clouds-back__a_WAb 200s linear infinite;
}

/* ==================== */


@media screen and (max-width: 768px) {
    .MemberSignUpGeral_form_wrap__1twcv {width: 1000px;}
}
.Membermypage_invalid__3kziD{
    color: red;
    margin-top: 7px;
    margin-left: 5px;
}

.Membermypage_valid__3OoOY{
    color: white;
    margin-top: 7px;
    margin-left: 5px;
}


.Membermypage_myPageWrapDiv__1JFsN intro_title_sub_text {font-size:1em}
.Membermypage_myPageWrapDiv__1JFsN .Membermypage_mypage_box__MQ_w0 {
    display: flex;
}

.Membermypage_mypage_box__MQ_w0 {
    background-position: center;
    padding: 80px 0em;
    /* justify-content: center; */
    background-repeat: no-repeat;
    background-size: cover;
}

.Membermypage_mypage_box__MQ_w0 .Membermypage_text_div__3zFDK {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    max-width:1200px;
    min-width:700px;
}

@media screen and (max-width: 768px) {
    .Membermypage_form_wrap__1wecz {width: 1000px;}
}

.Membermypage_login_form_wrap__16Uvn{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 5;
    /* opacity: 0.7; */
    border: 0px;
    box-shadow: 0 0 30px white !important;
    background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
    /* background-color: ; */
    border-radius: 15px;
    margin-top: 200px;
}

.Membermypage_login_form_wrap__16Uvn * {
  font-size:1em;
  color: #1b1b1b;
  font-family: "Noto Sans KR";
}

.Membermypage_login_form_wrap__16Uvn > .Membermypage_login_form_div__1uXZM label {
  text-align: start;
}

.Membermypage_login_form_wrap__16Uvn .Membermypage_modifiy__ae6Px span {
  color: black;
  font-weight: bold;
}

.Membermypage_login_form_wrap__16Uvn .Membermypage_mypage__3W12y {
  display:flex;
  align-items: center;
  font-weight: bold;
}

.Membermypage_login_form_wrap__16Uvn .Membermypage_mypage__3W12y span {
  color: black;
}

.Membermypage_login_form_wrap__16Uvn .Membermypage_mypage__3W12y span:nth-child(2) {
  margin-left:10px;
  background-color: 'red';
}


.Membermypage_login_form_div__1uXZM{
    border-radius: 15px;
    padding: 50px;
    /* opacity: 0.7; */
    border: 0px;
   
  }
.Membermypage_login_form_div__1uXZM > .Membermypage_profile__3f7O8{
    display:flex;
    align-items: center;
    justify-content: center;
}

/* 배경 starts, twinkling, cloud 이벤트 */
@keyframes Membermypage_move-twink-back__1ivUj {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes Membermypage_move-twink-back__1ivUj {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes Membermypage_move-clouds-back__2ee1S {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes Membermypage_move-clouds-back__2ee1S {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.Membermypage_stars__1Lolq, .Membermypage_twinkling__2mlFv, .Membermypage_clouds__3j8dn {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  min-height: 1900px;
  height: 100vh;
  display:block;
}

.Membermypage_stars__1Lolq {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.Membermypage_twinkling__2mlFv{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:Membermypage_move-twink-back__1ivUj 200s linear infinite;
  animation:Membermypage_move-twink-back__1ivUj 200s linear infinite;
}

.Membermypage_clouds__3j8dn{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:Membermypage_move-clouds-back__2ee1S 200s linear infinite;
  animation:Membermypage_move-clouds-back__2ee1S 200s linear infinite;
}

/* ==================== */

/* ============================ 버튼 이벤트 a태그 */
.Membermypage_style_a1__1FYoS {
    border-radius: 5px;
    background-color: #4B89DC;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 5px;
    letter-spacing: 0px
}

.Membermypage_style_a1__1FYoS:hover {
    background: #4B89DC;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #4B89DC,
                0 0 25px #4B89DC,
                0 0 50px #4B89DC,
                0 0 100px #4B89DC;
  }

  .Membermypage_style_a2__KvD-Y {
    border-radius: 5px;
    background-color: #e02e0e;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.Membermypage_style_a2__KvD-Y:hover {
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }

  .Membermypage_style_a3__2o4ep {

    background-color: #e02e0e;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.Membermypage_style_a3__2o4ep:hover {
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }

.Membermypage_style_a__1DpiG> span {
    position: absolute;
    display: block;
  }

.Membermypage_style_a__1DpiG >:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    -webkit-animation: Membermypage_btn-anim1__3gycR 1s linear infinite;
            animation: Membermypage_btn-anim1__3gycR 1s linear infinite;
  }

  .Membermypage_mouse_hover__2nqfj:hover{
    cursor: pointer;
  }

  @-webkit-keyframes Membermypage_btn-anim1__3gycR {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  @keyframes Membermypage_btn-anim1__3gycR {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  .Membermypage_style_a__1DpiG > :nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    -webkit-animation: Membermypage_btn-anim2__16DCf 1s linear infinite;
            animation: Membermypage_btn-anim2__16DCf 1s linear infinite;
    -webkit-animation-delay: .25s;
            animation-delay: .25s
  }
  
  @-webkit-keyframes Membermypage_btn-anim2__16DCf {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  @keyframes Membermypage_btn-anim2__16DCf {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .Membermypage_style_a__1DpiG > :nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    -webkit-animation: Membermypage_btn-anim3__2eIuk 1s linear infinite;
            animation: Membermypage_btn-anim3__2eIuk 1s linear infinite;
    -webkit-animation-delay: .5s;
            animation-delay: .5s
  }
  
  @-webkit-keyframes Membermypage_btn-anim3__2eIuk {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  @keyframes Membermypage_btn-anim3__2eIuk {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .Membermypage_style_a__1DpiG > :nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    -webkit-animation: Membermypage_btn-anim4__iY8yu 1s linear infinite;
            animation: Membermypage_btn-anim4__iY8yu 1s linear infinite;
    -webkit-animation-delay: .75s;
            animation-delay: .75s
  }
  
  @-webkit-keyframes Membermypage_btn-anim4__iY8yu {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  
  @keyframes Membermypage_btn-anim4__iY8yu {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  /* ================================= */


@media (min-width: 500px) and (max-width: 768px) {
    .Membermypage_login_form_wrap__16Uvn {
      width: 500px; 
      overflow-y: hidden; 
      overflow-x: hidden;
    }
    
    .Membermypage_login_form_div__1uXZM { 
      width: 500px; 
      overflow-y: hidden; 
      overflow-x: hidden;
    }

    .Membermypage_profileRow__3PXKP {
      width: 400px;
      overflow-x: hidden;
    }
}

@media (max-width: 499px) {
  .Membermypage_login_form_wrap__16Uvn {
    width: 300px; 
    overflow-y: hidden;
  }
  
  .Membermypage_profileRow__3PXKP {
    width: 200px;
  }

  .Membermypage_Text__3nuSR {
    font-size: 13px;
  }
  

}






* {
    margin: 0;
    padding: 0;
    font-family: "Noto Sans KR";
}
/* header {
    background-color:rgba(33, 33, 33, 0.9);
    color:#ffffff;
    display:block;
    font: 14px/1.3 Arial,sans-serif;
    height:50px;
    position:relative;
    z-index:5;
}
h2{
    margin-top: 30px;
    text-align: center;
}
header h2{
    font-size: 22px;
    margin: 0 auto;
    padding: 10px 0;
    width: 80%;
    text-align: center;
}
header a, a:visited {
    text-decoration:none;
    color:#fcfcfc;
} */

@keyframes login_move-twink-back__EBTmr {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes login_move-twink-back__EBTmr {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes login_move-clouds-back__AHf4p {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes login_move-clouds-back__AHf4p {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.login_stars__3UAWi, .login_twinkling__2PFZH, .login_clouds__4Rn8k {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  /* min-height: 1080px; */
  display:block;
}

.login_stars__3UAWi {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.login_twinkling__2PFZH{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:login_move-twink-back__EBTmr 200s linear infinite;
  animation:login_move-twink-back__EBTmr 200s linear infinite;
}

.login_clouds__4Rn8k{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:login_move-clouds-back__AHf4p 200s linear infinite;
  animation:login_move-clouds-back__AHf4p 200s linear infinite;
}


.login_login_form_wrap__2G6MZ{
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.login_login_form_div__2cGtc{
  /* opacity: 0.7; */
  border: 0px;
  box-shadow: 0 0 30px white !important;
  border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
}

/* ============================ 버튼 이벤트 a태그 */
.login_style_a__mLmXB {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.login_style_a__mLmXB:hover {
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 100px #03e9f4;
  }

.login_style_a__mLmXB > span {
    position: absolute;
    display: block;
  }

.login_style_a__mLmXB >:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    -webkit-animation: login_btn-anim1__Nvv7h 1s linear infinite;
            animation: login_btn-anim1__Nvv7h 1s linear infinite;
  }

  .login_mouse_hover__31M_I:hover{
    cursor: pointer;
  }

  @-webkit-keyframes login_btn-anim1__Nvv7h {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  @keyframes login_btn-anim1__Nvv7h {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  .login_style_a__mLmXB > :nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    -webkit-animation: login_btn-anim2__53chs 1s linear infinite;
            animation: login_btn-anim2__53chs 1s linear infinite;
    -webkit-animation-delay: .25s;
            animation-delay: .25s
  }
  
  @-webkit-keyframes login_btn-anim2__53chs {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  @keyframes login_btn-anim2__53chs {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .login_style_a__mLmXB > :nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    -webkit-animation: login_btn-anim3__3iLYc 1s linear infinite;
            animation: login_btn-anim3__3iLYc 1s linear infinite;
    -webkit-animation-delay: .5s;
            animation-delay: .5s
  }
  
  @-webkit-keyframes login_btn-anim3__3iLYc {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  @keyframes login_btn-anim3__3iLYc {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .login_style_a__mLmXB > :nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    -webkit-animation: login_btn-anim4__1MCXo 1s linear infinite;
            animation: login_btn-anim4__1MCXo 1s linear infinite;
    -webkit-animation-delay: .75s;
            animation-delay: .75s
  }
  
 
  @-webkit-keyframes login_btn-anim4__1MCXo {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  
 
  @keyframes login_btn-anim4__1MCXo {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }

  @media (max-width:500px) {
    .login_login_form_div__2cGtc{
      width: 280px;
    }

    .login_style_a__mLmXB {
     padding: 5px 10px;
    }
  }
  /* ================================= */
* {
    margin: 0;
    padding: 0;
}

.FindId_label__3O9QY.FindId_label__3O9QY>span{ color: white; }
/* header {
/* header {
    background-color:rgba(33, 33, 33, 0.9);
    color:#ffffff;
    display:block;
    font: 14px/1.3 Arial,sans-serif;
    height:50px;
    position:relative;
    z-index:5;
}
h2{
    margin-top: 30px;
    text-align: center;
}
header h2{
    font-size: 22px;
    margin: 0 auto;
    padding: 10px 0;
    width: 80%;
    text-align: center;
}
header a, a:visited {
    text-decoration:none;
    color:#fcfcfc;
} */

@keyframes FindId_move-twink-back__2pKqr {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes FindId_move-twink-back__2pKqr {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes FindId_move-clouds-back__2h48m {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes FindId_move-clouds-back__2h48m {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.FindId_stars__1X5CU, .FindId_twinkling__3zt3J, .FindId_clouds__1B0o5 {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  display:block;
}

.FindId_stars__1X5CU {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.FindId_twinkling__3zt3J{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:FindId_move-twink-back__2pKqr 200s linear infinite;
  animation:FindId_move-twink-back__2pKqr 200s linear infinite;
}

.FindId_clouds__1B0o5{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:FindId_move-clouds-back__2h48m 200s linear infinite;
  animation:FindId_move-clouds-back__2h48m 200s linear infinite;
}



.FindId_findid_form_wrap__3mRZA{
    position: relative;
    z-index: 5;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR";
}
/* header {
  background-color:rgba(33, 33, 33, 0.9);
  color:#ffffff;
  display:block;
  font: 14px/1.3 Arial,sans-serif;
  height:50px;
  position:relative;
  z-index:5;
}
h2{
  margin-top: 30px;
  text-align: center;
}
header h2{
  font-size: 22px;
  margin: 0 auto;
  padding: 10px 0;
  width: 80%;
  text-align: center;
}
header a, a:visited {
  text-decoration:none;
  color:#fcfcfc;
} */

@keyframes FindId_move-twink-back__2pKqr {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes FindId_move-twink-back__2pKqr {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

@keyframes FindId_move-clouds-back__2h48m {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-webkit-keyframes FindId_move-clouds-back__2h48m {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}

.FindId_stars__1X5CU, .FindId_twinkling__3zt3J, .FindId_clouds__1B0o5 {
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
min-height: 1080px;
display:block;
}

.FindId_stars__1X5CU {
background:#000 url(/images/login/stars.png) repeat top center;
z-index:0;
}

.FindId_twinkling__3zt3J{
background:transparent url(/images/login/twinkling.png) repeat top center;
z-index:1;
-webkit-animation:FindId_move-twink-back__2pKqr 200s linear infinite;
animation:FindId_move-twink-back__2pKqr 200s linear infinite;
}

.FindId_clouds__1B0o5{
  background:transparent url(/images/login/clouds3.png) repeat top center;
  z-index:3;
-webkit-animation:FindId_move-clouds-back__2h48m 200s linear infinite;
animation:FindId_move-clouds-back__2h48m 200s linear infinite;
}


.FindId_login_form_wrap__3GI-W{
  position: relative;
  z-index: 5;
}

.FindId_login_form_div__2Nn0v{
/* opacity: 0.7; */
border: 0px;
width: 350px;
box-shadow: 0 0 30px white !important;
border-radius: 15px !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

/* ============================ 버튼 이벤트 a태그 */
.FindId_style_a__2_nbb {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 0px;
  font-family: "Noto Sans KR";
}

.FindId_style_a__2_nbb:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
              0 0 25px #03e9f4,
              0 0 50px #03e9f4,
              0 0 100px #03e9f4;
}

.FindId_style_a__2_nbb > span {
  position: absolute;
  display: block;
}

.FindId_style_a__2_nbb >:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
}

.FindId_mouse_hover__3kCXy:hover{
  cursor: pointer;
}

@-webkit-keyframes FindId_btn-anim1__1oecf {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

@keyframes FindId_btn-anim1__1oecf {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.FindId_style_a__2_nbb > :nth-child(2){
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
}

@-webkit-keyframes FindId_btn-anim2__2WY7x {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

@keyframes FindId_btn-anim2__2WY7x {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.FindId_style_a__2_nbb > :nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
}

@-webkit-keyframes FindId_btn-anim3__aG39q {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

@keyframes FindId_btn-anim3__aG39q {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.FindId_style_a__2_nbb > :nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
}

@-webkit-keyframes FindId_btn-anim4__1yrX4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

@keyframes FindId_btn-anim4__1yrX4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

@media (max-width:500px) {
  .FindId_login_form_div__2Nn0v{
    width: 280px;
  }
}

/* ================================= */
* {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR";
}

.FindPassword_label__1sc30.FindPassword_label__1sc30>span{ color: white; }
/* header {
  background-color:rgba(33, 33, 33, 0.9);
  color:#ffffff;
  display:block;
  font: 14px/1.3 Arial,sans-serif;
  height:50px;
  position:relative;
  z-index:5;
}
h2{
  margin-top: 30px;
  text-align: center;
}
header h2{
  font-size: 22px;
  margin: 0 auto;
  padding: 10px 0;
  width: 80%;
  text-align: center;
}
header a, a:visited {
  text-decoration:none;
  color:#fcfcfc;
} */

@keyframes FindPassword_move-twink-back__3aCfd {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes FindPassword_move-twink-back__3aCfd {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

@keyframes FindPassword_move-clouds-back__31HO_ {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-webkit-keyframes FindPassword_move-clouds-back__31HO_ {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}

.FindPassword_stars__3lUXL, .FindPassword_twinkling__dcVwC, .FindPassword_clouds__HV2EE {
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
min-height: 1080px;
display:block;
}

.FindPassword_stars__3lUXL {
background:#000 url(/images/login/stars.png) repeat top center;
z-index:0;
}

.FindPassword_twinkling__dcVwC{
background:transparent url(/images/login/twinkling.png) repeat top center;
z-index:1;
-webkit-animation:FindPassword_move-twink-back__3aCfd 200s linear infinite;
animation:FindPassword_move-twink-back__3aCfd 200s linear infinite;
}

.FindPassword_clouds__HV2EE{
  background:transparent url(/images/login/clouds3.png) repeat top center;
  z-index:3;
-webkit-animation:FindPassword_move-clouds-back__31HO_ 200s linear infinite;
animation:FindPassword_move-clouds-back__31HO_ 200s linear infinite;
}


.FindPassword_login_form_wrap__2glbD{
  position: relative;
  display:'flex';
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.FindPassword_login_form_div__2ZYN7{
/* opacity: 0.7; */
border: 0px;
width: 350px;
box-shadow: 0 0 30px white !important;
border-radius: 15px !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

/* ============================ 버튼 이벤트 a태그 */
.FindPassword_style_a__3PEtY {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 0px;
  font-family: "Noto Sans KR";
}

.FindPassword_style_a__3PEtY:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
              0 0 25px #03e9f4,
              0 0 50px #03e9f4,
              0 0 100px #03e9f4;
}

.FindPassword_style_a__3PEtY > span {
  position: absolute;
  display: block;
}

.FindPassword_style_a__3PEtY >:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
 
  
}

.FindPassword_mouse_hover__2uS2F:hover{
  cursor: pointer;
}

@-webkit-keyframes FindPassword_btn-anim1__6wvMh {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

@keyframes FindPassword_btn-anim1__6wvMh {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.FindPassword_style_a__3PEtY > :nth-child(2){
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;

}

@-webkit-keyframes FindPassword_btn-anim2__149Dn {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

@keyframes FindPassword_btn-anim2__149Dn {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.FindPassword_style_a__3PEtY > :nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
}

@-webkit-keyframes FindPassword_btn-anim3__2OUyg {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

@keyframes FindPassword_btn-anim3__2OUyg {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.FindPassword_style_a__3PEtY > :nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
}

@-webkit-keyframes FindPassword_btn-anim4__2rXWD {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

@keyframes FindPassword_btn-anim4__2rXWD {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

@media (max-width:500px) {
  .FindPassword_login_form_div__2ZYN7{
    width: 280px;
  }

  .FindPassword_optionText__2l2CO {
   font-size: 11px;
  }
}
/* body {
    width: 100%;
  }
  .intro {
    width: 100%;
    height: 100vh;
    overflow: auto;
    margin: 0px auto;
    position: relative;
  }

  .full_video {
      width:177.vh;
      height:56.25vw;
      overflow-x:hidden;
      min-width:100%;
      min-height:100%;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);
      position:absolute;
  } */

  .Main_wrap_div__1CbPd {
    width: 100vw;
    background-repeat: repeat-x;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  .Main_style_a__D6G74 {
    background-color: rgba(88, 20, 225, 0.7);
    border-radius: 10px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    width: 150px;
    height: 50px;
    color: white;
    /* font-size: 16px; */
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px;
    text-align: center;
    padding-top: 16px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Noto Sans KR";
}

.Main_style_a__D6G74:hover {
  cursor: pointer;
    background: #5814E1;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #5814E1,
                0 0 25px #5814E1,
                0 0 50px #5814E1,
                0 0 150px #5814E1;
  }



  .Main_intro_txt_btn__3_mSo {
    left:50%;
    top:50%;
    -webkit-transform:translate(-50%, -50%);
            transform:translate(-50%, -50%);
    position:absolute;
  }

  .Main_bg__3E9DP {
    background-repeat: repeat-x;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: 1080px;
  }

  .Main_bg_01__1Wc8e {
    background-image: url(/images/main/main_bg_02.png);
    background-size: cover;
    flex-direction:column;
  }

  .Main_bg_02__3jDFW {
    background-image: url(/images/main/main_bg_03.png);
    background-size: cover;
    flex-direction:column;
  }

  .Main_bg_03__avUyY {
    background-image: url(/images/main/main_bg_04.png);
    background-size: cover;
    flex-direction:column;
  }

  .Main_bg_03__avUyY > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .Main_bg_03__avUyY > :nth-child(1) > h1 {
    color: white;
    font-family: "Noto Sans KR";
    font-size: 52px;
    text-shadow: gray 2px 2px 2px;
    margin-bottom: 30px;
  }
  .Main_bg_03__avUyY > :nth-child(1) > p {
    font-family: "Noto Sans KR";
    color: white;
    font-size: 25px;
    text-shadow: gray 2px 2px 2px;
    line-height:180%;
  }

  .Main_bg_03__avUyY > :nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 50px;
  }

  .Main_bg_03__avUyY > :nth-child(2) > div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .Main_bg_03__avUyY > :nth-child(2) > div > h1 {
    font-size:150px;
    font-weight: 6000;
    color:#32FAEA;
    padding:0;
    margin:0;
    text-shadow: gray 2px 2px 1px;
  }

  .Main_bg_03__avUyY > :nth-child(2) > div > span {
    font-size: 50px;
    font-weight:600;
    color:white;
    padding:0;
    margin-right: 40px;
    text-shadow: gray 2px 2px 1px;
  }

  .Main_bg_03__avUyY > :nth-child(3){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .Main_bg_03__avUyY > :nth-child(3) > :nth-child(1){
    position: relative;
    margin-right: 50px;
    margin-bottom: 0px;
  }
  .Main_bg_03__avUyY > :nth-child(3) > :nth-child(1) > p{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    font-size: 25px;
    font-weight: bold;
    color: #0d35b4;
  }

  .Main_bg_03__avUyY > :nth-child(3) > :nth-child(2){
    position: relative;
  }

  .Main_bg_03__avUyY > :nth-child(3) > :nth-child(2) > p{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    font-size: 25px;
    font-weight: bold;
    color: #0d35b4;
  }


  @media screen and (max-width: 768px){
    .Main_bg_03__avUyY { padding: 150px 0px; }
    .Main_bg_03__avUyY > :nth-child(1) { padding: 0px 50px }
    .Main_bg_03__avUyY > :nth-child(2) { flex-direction: column; }
    .Main_bg_03__avUyY > :nth-child(2) > div > h1 { font-size: 150px;}
    .Main_bg_03__avUyY > :nth-child(2) > div > span {font-size: 30px;}
    .Main_bg_03__avUyY > :nth-child(3) { flex-direction: column; }
    .Main_bg_03__avUyY > :nth-child(3) > :nth-child(1){ margin-right: 0px; margin-bottom: 50px; margin-top: 50px;}
    /* .bg_03 > :nth-child(3) > :nth-child(2){ } */
    .Main_intro_txt_btn__3_mSo > :nth-child(1) > img { width: 460px; }
  }



.PlayerRankMain_wrap_div__13c34 {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/images/main/bg_02.jpg);
    background-size: cover;
    flex-direction:column;
    padding: 70px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    /* height: 100vh; */
    overflow: hidden;
}

.PlayerRankMain_wrap_div__13c34 > :nth-child(1) > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 40px;
    text-align: center;
    letter-spacing: -0.015em;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 18px;
}

/* .wrap_div > :nth-child(1) > hr {
    width: 240px;
} */

.PlayerRankMain_all_rank_btn__17zVM {
    margin-top: 23px;
    border: 2px solid #CA2373;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0px 0px 9px #D84C5D);
            filter: drop-shadow(0px 0px 9px #D84C5D);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    font-family: "Noto Sans KR";
    color: #FFFFFF;
    width: 110px;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-shadow: 0px 1.25px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    transition: background-color 0.5s ease;
}

.PlayerRankMain_all_rank_btn__17zVM:hover {
    cursor: pointer;
    background-color: #CA2373;
}


.PlayerRankMain_content_div__yQ30I {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.PlayerRankMain_rank_div__3Di5n {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 270px;
    
}

.PlayerRankMain_rank_div__3Di5n > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PlayerRankMain_rank_div2__3nqCI {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-right: 70px; */
    margin-top: 0px;
}

.PlayerRankMain_rank_div2__3nqCI > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PlayerRankMain_rank_div3__1IKzo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-right: 70px; */
    margin-top: 0px;
}

.PlayerRankMain_rank_div3__1IKzo > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PlayerRankMain_rank_team_border1__XJPgA{
    -webkit-filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
            filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
    position: absolute;
    top: -15%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.PlayerRankMain_logoTeam_img1__1f7VE{
    position: absolute;
    top: -15%;
    left: 50.5%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
    width: 295px;
    height: 295px;
    z-index: 99;
}

.PlayerRankMain_rank_flash1__1Ws4p {
    position: absolute;
    top: -15%;
    left: 50.5%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.PlayerRankMain_rank_team_border2__13qVA{
    -webkit-filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF5C00);
            filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF5C00);
    position: absolute;
    top: -16%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.PlayerRankMain_logoTeam_img2__1diR_{
    position: absolute;
    top: -16%;
    left: 50.2%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(49% 0%, 94% 25%, 94% 75%, 49% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49% 0%, 94% 25%, 94% 75%, 49% 100%, 5% 75%, 5% 25%);
    height: 200px;
    width: 200px;
    z-index: 99;
}

.PlayerRankMain_rank_flash2__1GgXx {
    position: absolute;
    top: -16%;
    left: 50.6%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.PlayerRankMain_rank_team_border3__3IbGu{
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)), drop-shadow(0px 0px 3px #33004A), drop-shadow(0px 0px 60px #FF0000); */
    -webkit-filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF0000);
            filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF0000);
    position: absolute;
    top: -16%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}
.PlayerRankMain_rank_flash3__2jpKm {
    position: absolute;
    top: -16%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.PlayerRankMain_player_recored_text__2aQJX {
    color: white;
    font-family: "Noto Sans KR";
    font-style: normal;
    /* font-weight: bold; */
    font-size: 20px;
    margin: 20px 0px 0px 0px;
    text-align: center;
}
.PlayerRankMain_player_name__vyXDO{
    color: white;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: -0.015em;
    margin-bottom: 5px;
}


.PlayerRankMain_player_team__1qi8B {
    color: #5ED8FF;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -0.015em;
    width: 300px;
    text-align: center;
}

.PlayerRankMain_player_team1__3OxPr {
    color: #5ED8FF;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -0.015em;
    width: 300px;
    text-align: center;
    margin-bottom: 80px !important;
}



.PlayerRankMain_rank_crown__1V5w6 {
    width: 25px;
    height: 21px;
    margin-top: 30px;
}



.PlayerRankMain_rank_flag__1H9sL {
    -webkit-filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
            filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
}

@media screen and (max-width: 1500px) {
    .PlayerRankMain_rank_div__3Di5n {
        margin: 0px 50px;
    }
}


@media screen and (max-width: 1000px) {
    .PlayerRankMain_wrap_div__13c34 {
        height: 2100px;
        
    }
    .PlayerRankMain_content_div__yQ30I {
        margin-top: 90px;
        flex-direction: column;
        position: relative;
    }

    .PlayerRankMain_player_team1__3OxPr {
        margin-bottom: 0px;
    }

    .PlayerRankMain_rank_div__3Di5n {
        margin-right: 0px;
        position: relative;
        margin: 0px;
        top: -330px;
        left: 0px;
    }
    .PlayerRankMain_rank_div2__3nqCI {
        /* margin-top: 500px; */
        position: relative;
        top: 530px;
    }
    .PlayerRankMain_rank_div3__1IKzo {
        margin-top: 300px;
        
    }
}

@media screen and (max-width: 768px) {
    .PlayerRankMain_rank_team_border1__XJPgA { -webkit-filter: none; filter: none; }
    .PlayerRankMain_rank_team_border2__13qVA { -webkit-filter: none; filter: none; }
    .PlayerRankMain_rank_team_border3__3IbGu { -webkit-filter: none; filter: none; }
}
.TeamRankMain_wrap_div___eDzE {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/images/main/bg_01.jpg);
    background-size: cover;
    flex-direction:column;
    padding: 70px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    /* height: 100vh; */
    overflow: hidden;
}

.TeamRankMain_wrap_div___eDzE > :nth-child(1) > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 40px;
    text-align: center;
    letter-spacing: -0.015em;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 18px;
}

/* .wrap_div > :nth-child(1) > hr {
    width: 240px;
} */

.TeamRankMain_all_rank_btn__x4hgA {
    margin-top: 23px;
    border: 2px solid #BD3FDC;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0px 0px 9px #C44CD8);
            filter: drop-shadow(0px 0px 9px #C44CD8);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    font-family: "Noto Sans KR";
    color: #FFFFFF;
    width: 110px;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-shadow: 0px 1.25px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    transition: background-color 0.5s ease;
    /* margin-bottom: 300px; */
}

.TeamRankMain_all_rank_btn__x4hgA:hover {
    cursor: pointer;
    background-color: #BD3FDC;
}


.TeamRankMain_content_div__3IFq0 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.TeamRankMain_rank_div__2iguY {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 270px;
    
}

.TeamRankMain_rank_div__2iguY > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TeamRankMain_rank_div2__lTrzT {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-right: 70px; */
    margin-top: 0px;
}

.TeamRankMain_rank_div2__lTrzT > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TeamRankMain_rank_div3__124DN {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-right: 70px; */
    margin-top: 0px;
}

.TeamRankMain_rank_div3__124DN > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TeamRankMain_rank_team_border1__ehN7z{
    -webkit-filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
            filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
    position: absolute;
    top: -19%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.TeamRankMain_logoTeam_img1__26z_a{
    position: absolute;
    top: -19%;
    left: 50.5%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
    width: 295px;
    height: 295px;
    z-index: 99;
}

.TeamRankMain_rank_flash1__EmhTc {
    position: absolute;
    top: -19%;
    left: 50.5%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.TeamRankMain_rank_team_border2__5oxGm{
    -webkit-filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF5C00);
            filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF5C00);
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.TeamRankMain_logoTeam_img2__1P6LU{
    position: absolute;
    top: -21%;
    left: 50.2%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(49% 0%, 94% 25%, 94% 75%, 49% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49% 0%, 94% 25%, 94% 75%, 49% 100%, 5% 75%, 5% 25%);
    height: 200px;
    width: 200px;
    z-index: 99;
}

.TeamRankMain_rank_flash2__opFPc {
    position: absolute;
    top: -21%;
    left: 50.6%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.TeamRankMain_rank_team_border3__PP91l{
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)), drop-shadow(0px 0px 3px #33004A), drop-shadow(0px 0px 60px #FF0000); */
    -webkit-filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF0000);
            filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF0000);
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}
.TeamRankMain_rank_flash3__30NSF {
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.TeamRankMain_rank_team_name1__3Ubls{
    margin-top: 25px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    white-space: nowrap;
    margin-bottom: 80px !important;
    width: 300px;
}

.TeamRankMain_rank_team_name__3QKwc{
    margin-top: 25px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    white-space: nowrap;
    width: 300px;
}

.TeamRankMain_rank_crown__38fm0 {
    margin-top: 30px;
}



.TeamRankMain_rank_flag__J4HRH {
    -webkit-filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
            filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
}

@media screen and (max-width: 1500px) {
    .TeamRankMain_rank_div__2iguY {
        margin: 0px 50px;
    }
}



@media screen and (max-width: 1000px) {
    .TeamRankMain_wrap_div___eDzE {
        height: 1900px;
    }
    
    .TeamRankMain_content_div__3IFq0 {
        margin-top: 50px;
        flex-direction: column;
        position: relative;
    }



    .TeamRankMain_rank_team_name1__3Ubls{
        margin-bottom: 0px;
    }

    .TeamRankMain_rank_div__2iguY {
        margin-right: 0px;
        position: relative;
        margin: 0px;
        top: -220px;
        left: 0px;
    }
    .TeamRankMain_rank_div2__lTrzT {
        /* margin-top: 500px; */
        position: relative;
        top: 530px;
    }
    .TeamRankMain_rank_div3__124DN {
        margin-top: 400px;
        
    }
}

.MainVideo_content_div__1rdo9 {
  width: 100vw !important;
  height: 100vh;
  background-repeat: repeat-x;
  background-position: center;
  background-size: cover;
  flex-direction:column;
  padding: 180px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

/* .video_frame {
  pointer-events: none;
} */

.MainVideo_iframe_div__1XVkN {
  padding-top:56.25%;
  position: relative;
}

@media screen and (max-width: 768px){
  .MainVideo_iframe_div__1XVkN {
    padding-top: 490px;
    pointer-events: auto;
  }
}

/*  */



.CompetitionInfo_content_div__wavbf {
    padding: 70px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(/images/info_competition/bg_01.jpg);
    background-size: cover;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
}

.CompetitionInfo_content_div__wavbf > h1 {
    font-family: "post-no-bills-colombo-extrabold", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(52, 52, 52, 0.25), 0px 0px 250px #ffffff;
}

.CompetitionInfo_content_div__wavbf > hr {
    width: 171px;
}

.CompetitionInfo_dropdown_div__1ZUrQ {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.CompetitionInfo_dropdown_div__1ZUrQ > :nth-child(1) {
    margin-right: 10px;
}

.CompetitionInfo_arrow_btn__ERHZD {
    color: rgba(255, 255, 255, 0.5);
    text-shadow: 2px 4px 6px gray;
}

.CompetitionInfo_arrow_btn__ERHZD:hover {
    cursor: pointer;
    color: #ffffff;
}

.CompetitionInfo_competition_carousel__28AuR {
    max-width: 1200px;
    width: 100vw;
    height: 100vh;
    max-height: 700px;
    margin-top: 80px;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfo_competition_carousel__28AuR > div > :nth-child(1) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel__28AuR > div > :nth-child(2) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel__28AuR > div > :nth-child(4) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel__28AuR > div > :nth-child(5) {
    opacity: 0.8 !important;
}

.CompetitionInfo_competition_posters__3u7Am {
    border-radius: 10px;
    box-shadow: 0px 4px 30px gray;
}

.CompetitionInfo_competition_posters__3u7Am:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition-duration: 700ms;
}

.CompetitionInfo_transition_div__1Sxdl {
    position: fixed !important;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 30px;
    border-radius: 15px;
}

.CompetitionInfo_transition_content_div__2165w {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfo_transition_img__24u6t {
    max-width: 600px;
    width: auto;
    height: 70vh;
    max-height: 900px;
}

.CompetitionInfo_transition_img__24u6t:hover {
    cursor: pointer;
}

.CompetitionInfo_infoText_div__HP8fn {
    margin: 60px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Noto Sans KR";
    word-break: keep-all;
    text-align: center;
}

.CompetitionInfo_infoText_div__HP8fn > hr {
    width: 100%;
}

.CompetitionInfo_infoText_div__HP8fn > h1 {
    margin: 5px;
    font-weight: bold;
}

.CompetitionInfo_infoText_div__HP8fn > p {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    margin: 5px;
}

.CompetitionInfo_infoText_div__HP8fn > p > span {
    font-family: "Noto Sans KR";
    text-align: center;
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
}

.CompetitionInfo_orange_span__4Fewl {
    color: #f7941c;
    font-weight: 400;
}

/* .infoText_div > button {
    background: linear-gradient(90deg, #4158CD 4.83%, #A177E7 97.24%), #C4C4C4;
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
    border-radius: 7px;
    outline: none;
    border: 0px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    width: 110px;
    margin-top: 10px;
}

.infoText_div > button:hover{
    cursor: pointer;
} */

.CompetitionInfo_all_rank_btn__2rBFW {
    margin-top: 23px;
    border: 2px solid #bd3fdc;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0px 0px 9px #c44cd8);
            filter: drop-shadow(0px 0px 9px #c44cd8);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    font-family: "Noto Sans KR";
    color: #ffffff;
    width: 110px;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-shadow: 0px 1.25px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    transition: background-color 0.5s ease;
    /* margin-bottom: 300px; */
}

.CompetitionInfo_all_rank_btn__2rBFW:hover {
    cursor: pointer;
    background-color: #bd3fdc;
}

@media screen and (max-width: 1200px) {
    .CompetitionInfo_competition_carousel__28AuR {
        max-width: 800px;
        max-height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .CompetitionInfo_content_div__wavbf {
        padding: 100px 0px;
    }
    .CompetitionInfo_dropdown_div__1ZUrQ {
        flex-direction: column;
    }
    .CompetitionInfo_dropdown_div__1ZUrQ > :nth-child(1) {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .CompetitionInfo_competition_carousel__28AuR {
        width: 100%;
        height: 300px;
        margin-bottom: 40px;
    }
    .CompetitionInfo_transition_img__24u6t {
        height: 40vh;
    }
    .CompetitionInfo_infoText_div__HP8fn {
        width: 80%;
    }
}

.Shop_wrap_div__3QbWT {
    background-image: url("/images/main/bg_shop.png");
    width: 100vw;
    display: flex;
    padding: 30px 0px;
    /* height: 200px; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-repeat: no-repeat; */
    background-position: center;
    /* background-size: cover; */
    /* overflow-x: hidden; */
}

.Shop_banner_div__2fNo3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 80%;
    flex-wrap: wrap;
}

.Shop_banner_div__2fNo3 > div {
    margin: 10px;
}
.NationalPlayerMain_wrap_div__g0MCo {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    min-height: 100vh;

    background-position: center;
    width: 100vw;
    overflow: hidden;
}

.NationalPlayerMain_title_div__2W66S {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NationalPlayerMain_title_div__2W66S p {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 18px;
    margin-bottom: 5px;
}

.NationalPlayerMain_title_div__2W66S h1 {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 56px;
    margin-top: 0px;
    font-weight: bold;
    text-align: center;
}

.NationalPlayerMain_content_div__2eA_d {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 80px;
    width: 1320px;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 90px;
    gap: 90px;
    /* background-color: red !important; */
}


.NationalPlayerMain_profile_div__nj8gx {
    margin: 10px;
    width: 350px;
    height: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.3);
    /* border-radius: 15px; */
}


.NationalPlayerMain_profile_img_div__1eWM- img {
    width: 155px;
    height: 198px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.NationalPlayerMain_profile_text_div__1sPH3 {
    width: 160px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border: 0px;
    border-radius: 10px;
    padding: 5px;
}

.NationalPlayerMain_profile_text_div__1sPH3 p {
    margin: 0px;
    font-family: "Noto Sans KR";
    font-size: 15px;
    font-style: oblique;
    color: white;
}

.NationalPlayerMain_profile_text_div__1sPH3> :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.NationalPlayerMain_profile_text_div__1sPH3> :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.NationalPlayerMain_profile_text_div__1sPH3> :nth-child(1)> :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 23px;
}

.NationalPlayerMain_profile_text_div__1sPH3> :nth-child(1)> :nth-child(2) {
    color: #61ece1;
    font-size: 12px;
}

.NationalPlayerMain_profile_text_div__1sPH3> :nth-child(2) {
    margin-bottom: 10px;
}

.NationalPlayerMain_profile_text_div__1sPH3> :nth-child(2)> :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

.NationalPlayerMain_profile_text_div__1sPH3> :nth-child(3)> :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

@media screen and (max-width: 1200px) {
    .NationalPlayerMain_content_div__2eA_d {
        width: 740px;
    }
}

@media screen and (max-width: 738px) {

    /* .content_div {flex-direction: column; width: 370px;} */
    .NationalPlayerMain_content_div__2eA_d {
        flex-direction: column;
        width: 100vw;
    }
}

.NationalPlayerMain_card_wrap__2qJ10 {
    width: 380px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
}

.NationalPlayerMain_card_content__3IA3F {
    padding: 40px 32px 30px 33px;
    background-color: #0E1B37;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 160px;
}

.NationalPlayerMain_card_player_name_text__aRf-b {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    color: white;
    font-family: Play;
}

.NationalPlayerMain_card_description__64Fio {
    font-size: 14px;
    line-height: 16px;
    color: white;
    font-family: Play;
}

.NationalPlayerMain_image_wrap__AaQvl {
    width: 220px;
    height: 270px;
    position: relative;
}
.SquadManager_wrap_div__1ysgy * {
    font-family: "Noto Sans KR";
}

.SquadManager_wrap_div__1ysgy {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    min-height: 1080px;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover; 
}

.SquadManager_wrap_div__1ysgy > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 100px; */
    margin-bottom: 80px;
}

.SquadManager_wrap_div__1ysgy > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.SquadManager_wrap_div__1ysgy > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 56px;
    text-shadow: gray 2px 2px 2px;
    text-align: center;
}

/* .wrap_div > :nth-child(2) {
    margin-bottom: 50px;
    width: 250px;
    font-size: 20px
} */

.SquadManager_wrap_div__1ysgy > :nth-child(2) {
    color: white;
    font-size: 20px;
    font-family: "Noto Sans KR";

    /* text-align: right; */

}

.SquadManager_wrap_div__1ysgy > :nth-child(2) > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.SquadManager_wrap_div__1ysgy > :nth-child(2) > :nth-child(1) > :nth-child(1) {
    margin-bottom: 30px;
    font-size: 15px;
}

.SquadManager_wrap_div__1ysgy > :nth-child(2) > :nth-child(1) > :nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 5px;  
}

.SquadManager_wrap_div__1ysgy > :nth-child(2) > :nth-child(1) > :nth-child(2) > *{
    background-color: transparent;
    border-color: white;
    color: white;
    font-size: 15px;
    width: -webkit-max-content;
    width: max-content;
}


.SquadManager_squad_table__2pFew{
    max-width: 800px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
}

.SquadManager_squad_table__2pFew > thead > tr {
    height: 40px ;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.SquadManager_squad_table__2pFew > thead > tr > th {
    background-color: #5814e1;
    width: 80vw;
}


.SquadManager_squad_table__2pFew > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
}

.SquadManager_squad_table__2pFew > tbody > tr:hover {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}


.SquadManager_squad_table__2pFew > tbody > tr {
    height: 60px;
    font-size: 16px;
    font-family: "Noto Sans KR";
}

.SquadManager_squad_table__2pFew > tbody > tr > td {
    border-bottom: 1px dashed gray;
}

.SquadManager_squad_table__2pFew > tbody > :nth-last-child(1) > td {
    border-bottom: 0px
}

.SquadManager_team_modal__3KN0p{
    position: relative;
    -webkit-transform: translate( 22, 22 );
            transform: translate( 22, 22 );
    z-index: 50;
}


@media screen and (max-width: 768px){
    /* .wrap_div > :nth-child(1) > p { font-size: 18px; } */
    
    /* .wrap_div > :nth-child(1) > h1 { font-size: 50px; } */

    .SquadManager_squad_table__2pFew > thead > tr > th:first-child {
        width:18%
    }
    
    .SquadManager_squad_table__2pFew > thead > tr > :nth-child(2) {
        width:56.5%
    }
    
    .SquadManager_squad_table__2pFew > thead > tr > :nth-child(3) {
        width:25.5%
    }

    .SquadManager_wrap_div__1ysgy > :nth-child(2) { font-size: 15px; }

    .SquadManager_wrap_div__1ysgy > :nth-child(2) > :nth-child(1) > :nth-child(1) > *{ font-size: 13px; width: 100px; }

    .SquadManager_squad_table__2pFew > thead > tr { height: 40px ; font-size: 15px;}

    .SquadManager_squad_table__2pFew > tbody > tr { height: 40px; font-size: 13px; }
}

/* #5814e1 -> 테이블 헤더쪽 색상 */
.NationalPlayer_wrap_div__2IZ52 {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    /* height: 100vh; */
    /* height: 100%; */
    /* min-height: 600px; */
    min-height: 100vh;
}

.NationalPlayer_title_div__1EAl6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NationalPlayer_title_div__1EAl6 p {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 18px;
    margin-bottom: 5px;
}

.NationalPlayer_title_div__1EAl6 h1{
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 56px;
    margin-top: 0px;
    font-weight: bold;
    text-align: center;
}

.NationalPlayer_content_div__20Pzm {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 80px;
    width: 1320px;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 90px;
    gap: 90px;
    /* background-color: red !important; */
}


.NationalPlayer_profile_div__1NfqO {
    margin: 10px;
    width: 350px;
    height: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.3);
    /* border-radius: 15px; */
}


.NationalPlayer_profile_img_div__2A03f img {
    width: 155px;
    height: 198px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
}

.NationalPlayer_profile_text_div__1buvf {
    width: 160px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border: 0px;
    border-radius: 10px;
    padding: 5px;
}

.NationalPlayer_profile_text_div__1buvf p {
    margin: 0px;
    font-family: "Noto Sans KR";
    font-size: 15px;
    font-style: oblique;
    color: white;
}

.NationalPlayer_profile_text_div__1buvf > :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.NationalPlayer_profile_text_div__1buvf > :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.NationalPlayer_profile_text_div__1buvf > :nth-child(1) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 23px;
}

.NationalPlayer_profile_text_div__1buvf > :nth-child(1) > :nth-child(2) {
    color: #61ece1;
    font-size: 12px;
}

.NationalPlayer_profile_text_div__1buvf > :nth-child(2) {
    margin-bottom: 10px;
}

.NationalPlayer_profile_text_div__1buvf > :nth-child(2) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

.NationalPlayer_profile_text_div__1buvf > :nth-child(3) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

@media screen and (max-width: 1200px){
    .NationalPlayer_content_div__20Pzm {width: 740px;}
}

@media screen and (max-width: 738px){
    /* .content_div {flex-direction: column; width: 370px;} */
    .NationalPlayer_content_div__20Pzm {flex-direction: column; width: 100vw;}
}

.NationalPlayer_card_wrap__1eXx9 {
    width: 380px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
}

.NationalPlayer_card_content__1Q-rq {
    padding: 40px 32px 30px 33px ;
    background-color: #0E1B37;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 160px;
}

.NationalPlayer_card_player_name_text__2RMF7 {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    color: white;
    font-family: Play;
}

.NationalPlayer_card_description__11TwB {
    font-size: 14px;
    line-height: 16px;
    color: white;
    font-family: Play;
}

.NationalPlayer_image_wrap__3BKdf {
    width: 220px;
    height: 270px;
    position: relative;
}
.RegionalPlayer_wrap_div__122ge {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    /* height: 100vh; */
    /* min-height: 600px; */
    min-height: 100vh;
}

.RegionalPlayer_title_div__1g_Kx {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
}

.RegionalPlayer_title_div__1g_Kx p {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 18px;
    margin-bottom: 5px;
}

.RegionalPlayer_title_div__1g_Kx h1{
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 56px;
    margin-top: 0px;
    font-weight: bold;
    text-align: center;
}

.RegionalPlayer_content_div__2ecT8 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80vw;
    max-width: 1200px;
    flex-wrap: wrap;
}

.RegionalPlayer_region_drop__31oOX {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: white !important;
    color: white !important;
    font-size: 15px;
    width: 180px !important;
    margin-bottom: 40px;
}

.RegionalPlayer_profile_div__3Dkpd {
    margin: 10px;
    width: 350px;
    height: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.3);
    /* border-radius: 15px; */
}


.RegionalPlayer_profile_img_div__2O9XI img {
    width: 155px;
    height: 198px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
}

.RegionalPlayer_profile_text_div__26YjG {
    width: 160px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border: 0px;
    border-radius: 10px;
    padding: 5px;
}

.RegionalPlayer_profile_text_div__26YjG p {
    margin: 0px;
    font-family: "Noto Sans KR";
    font-size: 15px;
    font-style: oblique;
    color: white;
}

.RegionalPlayer_profile_text_div__26YjG > :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.RegionalPlayer_profile_text_div__26YjG > :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.RegionalPlayer_profile_text_div__26YjG > :nth-child(1) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 23px;
}

.RegionalPlayer_profile_text_div__26YjG > :nth-child(1) > :nth-child(2) {
    color: #61ece1;
    font-size: 12px;
}

.RegionalPlayer_profile_text_div__26YjG > :nth-child(2) {
    margin-bottom: 10px;
}

.RegionalPlayer_profile_text_div__26YjG > :nth-child(2) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

.RegionalPlayer_profile_text_div__26YjG > :nth-child(3) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

@media screen and (max-width: 1200px){
    .RegionalPlayer_content_div__2ecT8 {width: 740px;}
}

@media screen and (max-width: 738px){
    .RegionalPlayer_content_div__2ecT8 {flex-direction: column; width: 370px;}
}
.PrivacyPolicy_textPolicy__3IUbB.PrivacyPolicy_textPolicy__3IUbB>span{ 
    color: white;
    font-size: 15px;
    padding: 10px;
    margin-top: 10px;
    font-family: "Noto Sans KR";
    
}

.PrivacyPolicy_wrap_div__2811j {
  min-height: 1300px;
  height: 100vh;
}

.PrivacyPolicy_login_form_wrap__3B9dn{
    position: relative;
    z-index: 5;
    max-width: 1000px;
    width: 80vw;
    /* background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important; */
}

.PrivacyPolicy_login_form_div__1hKap{
    /* padding-bottom: 50px !important; */
    padding: 0px 50px 50px 50px !important;
    border: 0px;
    box-shadow: 0 0 30px white !important;
    /* background: linear-gradient(235deg, #020202, #565656, #020202) !important; */
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
  }

/* ============================ 버튼 이벤트 a태그 */
.PrivacyPolicy_style_a1__YHH9v {
  border-radius: 5px;
  background-color: #88DC4B;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 0px
}

.PrivacyPolicy_style_a1__YHH9v:hover {
    background: #88DC4B;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #88DC4B,
                0 0 25px #88DC4B,
                0 0 50px #88DC4B,
                0 0 100px #88DC4B;
  }

  .PrivacyPolicy_style_a2__2c3qd {
    background-color: #e02e0e;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.PrivacyPolicy_style_a2__2c3qd:hover {
  background: #e02e0e;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #e02e0e,
              0 0 25px #e02e0e,
              0 0 50px #e02e0e,
              0 0 100px #e02e0e;
  }

.PrivacyPolicy_style_a__35rwE > span {
    position: absolute;
    display: block;
  }

.PrivacyPolicy_style_a__35rwE >:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    -webkit-animation: PrivacyPolicy_btn-anim1__1QDqW 1s linear infinite;
            animation: PrivacyPolicy_btn-anim1__1QDqW 1s linear infinite;
  }

  .PrivacyPolicy_mouse_hover__1f0uR:hover{
    cursor: pointer;
  }

  @-webkit-keyframes PrivacyPolicy_btn-anim1__1QDqW {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  @keyframes PrivacyPolicy_btn-anim1__1QDqW {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  .PrivacyPolicy_style_a__35rwE > :nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    -webkit-animation: PrivacyPolicy_btn-anim2__3_4yK 1s linear infinite;
            animation: PrivacyPolicy_btn-anim2__3_4yK 1s linear infinite;
    -webkit-animation-delay: .25s;
            animation-delay: .25s
  }
  
  @-webkit-keyframes PrivacyPolicy_btn-anim2__3_4yK {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  @keyframes PrivacyPolicy_btn-anim2__3_4yK {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .PrivacyPolicy_style_a__35rwE > :nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    -webkit-animation: PrivacyPolicy_btn-anim3__3x8KV 1s linear infinite;
            animation: PrivacyPolicy_btn-anim3__3x8KV 1s linear infinite;
    -webkit-animation-delay: .5s;
            animation-delay: .5s
  }
  
  @-webkit-keyframes PrivacyPolicy_btn-anim3__3x8KV {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  @keyframes PrivacyPolicy_btn-anim3__3x8KV {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .PrivacyPolicy_style_a__35rwE > :nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    -webkit-animation: PrivacyPolicy_btn-anim4__36tLr 1s linear infinite;
            animation: PrivacyPolicy_btn-anim4__36tLr 1s linear infinite;
    -webkit-animation-delay: .75s;
            animation-delay: .75s
  }
  
  @-webkit-keyframes PrivacyPolicy_btn-anim4__36tLr {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  
  @keyframes PrivacyPolicy_btn-anim4__36tLr {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  /* ================================= */

/* 배경 starts, twinkling, cloud 이벤트 */
@keyframes PrivacyPolicy_move-twink-back__PL3ub {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes PrivacyPolicy_move-twink-back__PL3ub {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes PrivacyPolicy_move-clouds-back__1o6-K {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes PrivacyPolicy_move-clouds-back__1o6-K {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.PrivacyPolicy_stars__ES5Tc, .PrivacyPolicy_twinkling__1UqHH, .PrivacyPolicy_clouds__2epSQ {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  min-height: 1300px;
  display: block;
}

.PrivacyPolicy_stars__ES5Tc {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.PrivacyPolicy_twinkling__1UqHH{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:PrivacyPolicy_move-twink-back__PL3ub 200s linear infinite;
  animation:PrivacyPolicy_move-twink-back__PL3ub 200s linear infinite;
}

.PrivacyPolicy_clouds__2epSQ{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:PrivacyPolicy_move-clouds-back__1o6-K 200s linear infinite;
  animation:PrivacyPolicy_move-clouds-back__1o6-K 200s linear infinite;
}

/* ==================== */


@media screen and (max-width: 768px) {
    .PrivacyPolicy_wrap_div__2811j { height: 1300px; }
    .PrivacyPolicy_form_wrap__1NPtD {width: 1000px;}
    .PrivacyPolicy_login_form_div__1hKap {
      font-size: 9pt;
      text-align: center;
    }
}
.competiton_application_competition__28R8J.competiton_application_competition__28R8J > span {
  color: white;
}

@media screen and (max-width: 768px) {
  .competiton_application_competition_form_wrap__2ZSXp {
    max-width: 300px;
  }
}

.competiton_application_login_form_wrap__W9TD0 {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  /* opacity: 0.7; */
  border: 0px;
  box-shadow: 0 0 30px white !important;
  background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
  /* background-color: ; */
  border-radius: 15px;
}

.competiton_application_login_form_div__Q34Cj {
  border-radius: 15px;
  padding: 50px;
  /* opacity: 0.7; */
  border: 0px;
  box-shadow: 0 0 30px white !important;
  background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
}

/* 배경 starts, twinkling, cloud 이벤트 */
@keyframes competiton_application_move-twink-back__1WI88 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-webkit-keyframes competiton_application_move-twink-back__1WI88 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}

@keyframes competiton_application_move-clouds-back__F8e6B {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}
@-webkit-keyframes competiton_application_move-clouds-back__F8e6B {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}

.competiton_application_stars__2-bco,
.competiton_application_twinkling__9RArp,
.competiton_application_clouds__2mp7k {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 1500px;
  height: 100%;
  display: block;
}

.competiton_application_stars__2-bco {
  background: #000 url(/images/login/stars.png) repeat top center;
  z-index: 0;
}

.competiton_application_twinkling__9RArp {
  background: transparent url(/images/login/twinkling.png) repeat top center;
  z-index: 1;
  -webkit-animation: competiton_application_move-twink-back__1WI88 200s linear infinite;
  animation: competiton_application_move-twink-back__1WI88 200s linear infinite;
}

.competiton_application_clouds__2mp7k {
  background: transparent url(/images/login/clouds3.png) repeat top center;
  z-index: 3;
  -webkit-animation: competiton_application_move-clouds-back__F8e6B 200s linear infinite;
  animation: competiton_application_move-clouds-back__F8e6B 200s linear infinite;
}

/* ==================== */

/* ============================ 버튼 이벤트 a태그 */
.competiton_application_style_a1__pijrB {
  border-radius: 5px;
  background-color: #4b89dc;
  position: relative;
  display: inline-block;
  padding: 7px 10px;
  color: white;
  font-size: 11px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 0px;
}

.competiton_application_style_a1__pijrB:hover {
  background: #4b89dc;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #4b89dc, 0 0 25px #4b89dc, 0 0 50px #4b89dc,
    0 0 100px #4b89dc;
}

.competiton_application_style_a2__2lTDT {
  border-radius: 5px;
  background-color: #88dc4b;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 0px;
}

.competiton_application_style_a2__2lTDT:hover {
  background: #88dc4b;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #88dc4b, 0 0 25px #88dc4b, 0 0 50px #88dc4b,
    0 0 100px #88dc4b;
}

.competiton_application_style_a3__3yt0o {
  background-color: #e02e0e;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 0px;
}

.competiton_application_style_a3__3yt0o:hover {
  background: #e02e0e;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #e02e0e, 0 0 25px #e02e0e, 0 0 50px #e02e0e,
    0 0 100px #e02e0e;
}

.competiton_application_style_a__34Apt > span {
  position: absolute;
  display: block;
}

.competiton_application_style_a__34Apt > :nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  -webkit-animation: competiton_application_btn-anim1__3slt9 1s linear infinite;
          animation: competiton_application_btn-anim1__3slt9 1s linear infinite;
}

.competiton_application_mouse_hover__1WaRf:hover {
  cursor: pointer;
}

@-webkit-keyframes competiton_application_btn-anim1__3slt9 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

@keyframes competiton_application_btn-anim1__3slt9 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.competiton_application_style_a__34Apt > :nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  -webkit-animation: competiton_application_btn-anim2__23KKz 1s linear infinite;
          animation: competiton_application_btn-anim2__23KKz 1s linear infinite;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

@-webkit-keyframes competiton_application_btn-anim2__23KKz {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

@keyframes competiton_application_btn-anim2__23KKz {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.competiton_application_style_a__34Apt > :nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  -webkit-animation: competiton_application_btn-anim3__1E5ze 1s linear infinite;
          animation: competiton_application_btn-anim3__1E5ze 1s linear infinite;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@-webkit-keyframes competiton_application_btn-anim3__1E5ze {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

@keyframes competiton_application_btn-anim3__1E5ze {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.competiton_application_style_a__34Apt > :nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  -webkit-animation: competiton_application_btn-anim4__2MR4Q 1s linear infinite;
          animation: competiton_application_btn-anim4__2MR4Q 1s linear infinite;
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}

@-webkit-keyframes competiton_application_btn-anim4__2MR4Q {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

@keyframes competiton_application_btn-anim4__2MR4Q {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
/* ================================= */

@media screen and (max-width: 768px) {
  .competiton_application_login_form_wrap__W9TD0 {
    width: 500px;
  }
  .competiton_application_login_form_div__Q34Cj {
    width: 500px;
  }
}

.LeagueTab_item_div__1dtLP {
    width: 100px;
    background-color: white;
    padding: 13px 0;
    color: gray;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size:1.1em;
    border-right:1px solid #c0c0c0;
}

.LeagueTab_item_div__1dtLP:hover {
    cursor: pointer;
    background-color: #5814E1;
    color: white;
}

.LeagueTab_item_div__1dtLP:first-child {
    border-radius: 8px 0 0 8px;
}

.LeagueTab_item_div__1dtLP:last-child {
    border-radius: 0 8px 8px 0;
    border:0;
}

@media (min-width: 500px) and (max-width: 768px) {
    .LeagueTab_item_div__1dtLP {
        width: 80px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right:1px solid #c0c0c0;
    }
}

@media (max-width: 499px) {
    .LeagueTab_item_div__1dtLP {
        width: 70px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right:1px solid #c0c0c0;
    }
 
}


.CompetitionInfo_competition_div__NDoky * {
    font-family: "Noto Sans KR";
}

.CompetitionInfo_competition_div__NDoky {
    font-family: "Noto Sans KR";
    background-image: url("/images/info_squad/squad_bg_01.jpg");
    min-height: 1080px;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.CompetitionInfo_competition_div__NDoky > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 50px 0px; */
}

.CompetitionInfo_competition_div__NDoky > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.CompetitionInfo_competition_div__NDoky > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 56px;
    color: white;
    text-shadow: gray 2px 2px 2px;
}

.CompetitionInfo_competition_div__NDoky > :nth-child(2) {
    color: white;
    font-size: 15px;
    justify-content: space-between;
    font-family: "Noto Sans KR";
}

.CompetitionInfo_competition_dropdown__2vvrV {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    margin-bottom: 50px;
}

.CompetitionInfo_competition_dropdown__2vvrV > :nth-child(1) > :nth-child(1) {
    margin-right: 10px;
}

/* .competition_dropdown > * { */
.CompetitionInfo_competition_dropdown__2vvrV > div > * {
    background-color: transparent !important;
    border-color: white !important;
    color: white !important;
    text-align: center !important;
    /* font-size: 20px; */
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
}

.CompetitionInfo_league_tab__2FfD6 {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 50px;
}

.CompetitionInfo_matchType_tab__sILOr {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 50px;
}

.CompetitionInfo_competition_table__VNjSF {
    max-width: 1600px;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
}

.CompetitionInfo_competition_table__VNjSF > thead > tr {
    height: 40px;
    font-size: 18px;
    justify-content: center;
    color: white;
}

.CompetitionInfo_competition_table__VNjSF > thead > tr > th {
    background-color: #5814e1;
    font-size: 15px;
    justify-content: center;
    width: 200px;
}

.CompetitionInfo_competition_table__VNjSF > tbody {
    background-image: url("/images/info_squad/squad_table_bg.png");
    color: white;
}

.CompetitionInfo_competition_table__VNjSF > tbody > tr {
    height: 60px;
    font-size: 16px;
    font-family: "Noto Sans KR";
}

.CompetitionInfo_competition_table__VNjSF > tbody > tr > td {
    border-bottom: 1px dashed gray;
}

.CompetitionInfo_competition_table__VNjSF > tbody > :nth-last-child(1) > td {
    border-bottom: 0px;
}

.CompetitionInfo_table_wrap__12NI1 {
    display: block;
    width: 100%;
    overflow-x: scroll;
}

@media (max-width: 499px) {
    .CompetitionInfo_table_wrap__12NI1 {
        width: 300px;
    }

    .CompetitionInfo_table_wrap__12NI1::-webkit-scrollbar-thumb {
        background-color: white;
    }

    .CompetitionInfo_competition_table__VNjSF {
        width: 900px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
    }

    .CompetitionInfo_competition_div__NDoky > :nth-child(2) {
        font-size: 15px;
    }

    .CompetitionInfo_competition_table__VNjSF > thead > tr > th {
        font-size: 9pt;
    }

    .CompetitionInfo_competition_table__VNjSF > tbody > tr {
        font-size: 9pt;
    }
}

/* @media (min-width:500px) and (max-width: 768px){ */
@media screen and (max-width: 768px) {
    .CompetitionInfo_competition_div__NDoky > :nth-child(2) {
        font-size: 15px;
    }

    .CompetitionInfo_competition_table__VNjSF > thead > tr > th {
        font-size: 9pt;
    }

    .CompetitionInfo_competition_table__VNjSF > tbody > tr {
        height: 40px;
        font-size: 9pt;
    }

    .CompetitionInfo_competition_dropdown__2vvrV > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .CompetitionInfo_competition_dropdown__2vvrV > :nth-child(1) > :nth-child(1) {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    /* .competition_dropdown > * { */
    .CompetitionInfo_competition_dropdown__2vvrV > div > * {
        background-color: transparent !important;
        border-color: white !important;
        color: white !important;
        width: 300px !important;
    }
}

.CompetitionInfo_redText__9QQPq {
    color: red;
}

.CompetitionInfo_blueText__2iDXe {
    color: blue;
}
/* #5814e1 -> 테이블 헤더쪽 색상 */

.MainModal_style_a__2RFFm {
    background-color: rgba(50, 250, 83, 0.8);
    border-radius: 10px;
    position: relative;
    display: inline-block;
    padding: 15px 20px;
    /* width: fit-content; */
    height: 50px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 0px;
    text-align: center;
    font-weight: bold;
    font-family: "Noto Sans KR";
}

.MainModal_style_a__2RFFm:hover {
  cursor: pointer;
    background: #32FA53;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #32FA53,
                0 0 25px #32FA53,
                0 0 50px #32FA53,
                0 0 150px #32FA53;
  }

  .MainModal_style_a1__2J9jJ {
    background-color: rgba(82, 82, 82, 0.8);
    border-radius: 10px;
    position: relative;
    display: inline-block;
    padding: 15px 20px;
    /* width: 180px; */
    height: 50px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 0px;
    text-align: center;
    font-weight: bold;
    font-family: "Noto Sans KR";
}
.MainModal_style_a1__2J9jJ:hover {
    cursor: pointer;
      background: #525252;
      color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 5px #525252,
                  0 0 25px #525252,
                  0 0 50px #525252,
                  0 0 150px #525252;
    }

.MainModal_mouse_hover__2rdYt {
    cursor: pointer;
}


@media (min-width:554px) and (max-width:768px){
  .MainModal_style_a__2RFFm {padding:10px; font-size: 9pt; height: 40px;}
  .MainModal_style_a1__2J9jJ {margin-top: 10px; padding: 10px; font-size: 9pt; height: 40px;}
}


@media (min-width:321px) and (max-width:553px){
  .MainModal_style_a__2RFFm {padding: 10px; font-size: 9pt; height: 40px;}
  .MainModal_style_a1__2J9jJ {margin-top: 10px; padding: 10px; font-size: 9pt; height: 40px;}
}

@media (max-width:320px){
  .MainModal_style_a__2RFFm {padding: 10px; font-size: 9pt; height: 40px;}
  .MainModal_style_a1__2J9jJ {margin-top: 10px; padding: 10px; font-size: 9pt; height: 40px;}
}
.MatchType_item_div__3tuL0 {
    width: 100px;
    background-color: white;
    padding: 13px 0;
    color: gray;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size:1.1em;
    border-right:1px solid #c0c0c0;
}

.MatchType_item_div__3tuL0:hover {
    cursor: pointer;
    background-color: #5814E1;
    color: white;
}

.MatchType_item_div__3tuL0:first-child {
    border-radius: 8px 0 0 8px;
}

.MatchType_item_div__3tuL0:last-child {
    border-radius: 0 8px 8px 0;
    border:0;
}

@media (min-width: 500px) and (max-width: 768px) {
    .MatchType_item_div__3tuL0 {
        width: 80px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 13px;
        border-right:1px solid #c0c0c0;
    }
}

@media (max-width: 499px) {
    .MatchType_item_div__3tuL0 {
        width: 70px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 13px;
        border-right:1px solid #c0c0c0;
    }
 
}
.CompetitionInfo_content_div__1Eecp {
    padding: 100px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(/images/info_competition/bg_02.jpg);
    background-size: cover;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
}

.CompetitionInfo_content_div__1Eecp > h1 {
    font-family: "post-no-bills-colombo-extrabold", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(52, 52, 52, 0.25), 0px 0px 250px #ffffff;
}

.CompetitionInfo_content_div__1Eecp > hr {
    width: 171px;
}

.CompetitionInfo_dropdown_div__24_ts {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.CompetitionInfo_dropdown_div__24_ts > :nth-child(1) {
    margin-right: 10px;
}

.CompetitionInfo_arrow_btn__2qWcD {
    color: rgba(255, 255, 255, 0.5);
    text-shadow: 2px 4px 6px gray;
}

.CompetitionInfo_arrow_btn__2qWcD:hover {
    cursor: pointer;
    color: #ffffff;
}

.CompetitionInfo_competition_carousel___g3bW {
    max-width: 1200px;
    width: 100vw;
    height: 100vh;
    max-height: 700px;
    margin-top: 80px;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfo_competition_carousel___g3bW > div > :nth-child(1) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel___g3bW > div > :nth-child(2) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel___g3bW > div > :nth-child(4) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel___g3bW > div > :nth-child(5) {
    opacity: 0.8 !important;
}

.CompetitionInfo_competition_posters__1uJVN {
    border-radius: 10px;
    box-shadow: 0px 4px 30px gray;
}

.CompetitionInfo_competition_posters__1uJVN:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition-duration: 700ms;
}

.CompetitionInfo_transition_div__jZuO4 {
    position: fixed !important;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 30px;
    border-radius: 15px;
}

.CompetitionInfo_transition_content_div__1mETG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfo_transition_img__2eiCc {
    max-width: 600px;
    width: auto;
    height: 70vh;
    max-height: 900px;
}

.CompetitionInfo_transition_img__2eiCc:hover {
    cursor: pointer;
}

.CompetitionInfo_infoText_div__1DFZg {
    margin: 60px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Noto Sans KR";
    word-break: keep-all;
    text-align: center;
}

.CompetitionInfo_infoText_div__1DFZg > hr {
    width: 100%;
}

.CompetitionInfo_infoText_div__1DFZg > h1 {
    margin: 5px;
    font-weight: bold;
}

.CompetitionInfo_infoText_div__1DFZg > p {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    margin: 5px;
}

.CompetitionInfo_infoText_div__1DFZg > p > span {
    font-family: "Noto Sans KR";
    text-align: center;
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
}

.CompetitionInfo_orange_span__3g2Bh {
    color: #f7941c;
    font-weight: 400;
}

/* .infoText_div > button {
    background: linear-gradient(90deg, #4158CD 4.83%, #A177E7 97.24%), #C4C4C4;
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
    border-radius: 7px;
    outline: none;
    border: 0px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    width: 110px;
    margin-top: 10px;
}

.infoText_div > button:hover{
    cursor: pointer;
} */

.CompetitionInfo_all_rank_btn__2n5R7 {
    margin-top: 23px;
    border: 2px solid #bd3fdc;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0px 0px 9px #c44cd8);
            filter: drop-shadow(0px 0px 9px #c44cd8);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    font-family: "Noto Sans KR";
    color: #ffffff;
    width: 110px;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-shadow: 0px 1.25px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    transition: background-color 0.5s ease;
    /* margin-bottom: 300px; */
}

.CompetitionInfo_all_rank_btn__2n5R7:hover {
    cursor: pointer;
    background-color: #bd3fdc;
}

@media screen and (max-width: 1200px) {
    .CompetitionInfo_competition_carousel___g3bW {
        max-width: 800px;
        max-height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .CompetitionInfo_content_div__1Eecp {
        padding: 100px 0px;
    }
    .CompetitionInfo_dropdown_div__24_ts {
        flex-direction: column;
    }
    .CompetitionInfo_dropdown_div__24_ts > :nth-child(1) {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .CompetitionInfo_competition_carousel___g3bW {
        width: 100%;
        height: 300px;
        margin-bottom: 40px;
    }
    .CompetitionInfo_transition_img__2eiCc {
        height: 40vh;
    }
    .CompetitionInfo_infoText_div__1DFZg {
        width: 80%;
    }
}

.CompetitionInfoLeague_wrap_div__1883h {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    /* min-height: 100vh; */
    height: 100%;
    color: #FFFFFF;
}

.CompetitionInfoLeague_wrap_div__1883h > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    /* height: 67px; */
    padding: 10px 0px;
    color: #FFFFFF;
    margin-bottom: 190px;
    width: 100vw;
}

.CompetitionInfoLeague_league1_title__22Yri {
    background: linear-gradient(89.98deg, rgba(255,255,255,0) 25%, rgba(227, 109, 0, 0.546875) 50%, rgba(255, 255, 255, 0) 75%);
}
.CompetitionInfoLeague_league2_title__1Qqml {
    background: linear-gradient(89.98deg, rgba(255,255,255,0) 25%, rgba(0, 132, 227, 0.546875) 46.54%, rgba(255, 255, 255, 0) 75%);
}
.CompetitionInfoLeague_league3_title__34dfS {
    background: linear-gradient(89.98deg,  rgba(255,255,255,0) 25%, rgba(182, 0, 227, 0.546875) 46.54%, rgba(255, 255, 255, 0) 75%);
}

.CompetitionInfoLeague_content_div__3dJ8T {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.CompetitionInfoLeague_rank_div__1P4xc {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
}

.CompetitionInfoLeague_rank_div__1P4xc > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfoLeague_rank_div2__2BdJ0 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
}

.CompetitionInfoLeague_rank_div2__2BdJ0 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfoLeague_rank_div3__18cv2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
    margin-top: 0px;
}

.CompetitionInfoLeague_rank_div3__18cv2 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.CompetitionInfoLeague_rank_div4__2w03W {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
    margin-top: 0px;
}

.CompetitionInfoLeague_rank_div4__2w03W > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.CompetitionInfoLeague_rank_team_name__1fxO8{
    margin-top: 25px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    width: 200px;
}

.CompetitionInfoLeague_rank_team_reward__37ng2{
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #F7941C;
}

.CompetitionInfoLeague_rank_team_border1__32G-v{
    -webkit-filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
            filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
    position: absolute;
    top: -34%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.CompetitionInfoLeague_logoTeam_img1__3C-ow{
    position: absolute;
    top: -34%;
    left: 50.4%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(50% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(50% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
    width: 164px;
    height: 164px;
    z-index: 99;
}



.CompetitionInfoLeague_rank_team_border2__19wMN{
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF5C00);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF5C00);
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.CompetitionInfoLeague_logoTeam_img2__3mo6j{
    position: absolute;
    top: -21%;
    left: 50.4%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(50% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(50% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
    width: 114px;
    height: 114px;
    /* height: 113px; */
    z-index: 99;
}

.CompetitionInfoLeague_rank_team_border3__TJ8uQ{
    -webkit-filter: drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF0000);
            filter: drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF0000);
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}


.CompetitionInfoLeague_rank_team_border4__2acE5{
    -webkit-filter: drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF0000);
            filter: drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF0000);
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}



.CompetitionInfoLeague_rank_flag__NCdUN {
    -webkit-filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
            filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
}

@media screen and (max-width: 1000px){
    .CompetitionInfoLeague_wrap_div__1883h {
        height: 100%;
    }
    .CompetitionInfoLeague_content_div__3dJ8T {
        flex-direction: column;
    }
    .CompetitionInfoLeague_rank_div__1P4xc {
        margin-right: 0px;
        margin: 100px 0px;
        position: relative;
        top: -340px;
    }

    .CompetitionInfoLeague_rank_div2__2BdJ0 {
        margin-right: 0px;
        margin: 100px 0px;
        position: relative;
        top: 390px;
    }

    .CompetitionInfoLeague_rank_div3__18cv2{
        margin: 45px 0px;
    }
    .CompetitionInfoLeague_rank_div4__2w03W {
        margin-top: 100px;
        margin-bottom: 85px;
    }
}
.TournamentBracketForResult_bracketTable__2OHY0 {
    margin-top: 100px;
    margin-bottom: 150px;
    border: 0;
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-left: 25px;
}

.TournamentBracketForResult_matchWinner__2UKzD {
    background: #272f36;
    color: #FF8C00;
    box-sizing: border-box;
    font-weight: 600;
    line-height: 0.7rem;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
 }

.TournamentBracketForResult_matchWinner__2UKzD span {
    vertical-align: middle;
    font-size: 0.9rem;
    font-family: "Noto Sans KR";
}
  
.TournamentBracketForResult_matchWinner__2UKzD span:last-child {
    padding-left: 10px;
    padding-right: 5px;
}

.TournamentBracketForResult_matchLosser__251vt {
    background: #182026;
    color: #6b798c;
    box-sizing: border-box;
    font-weight: 700;
    line-height: 0.7rem;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
}

.TournamentBracketForResult_matchLosser__251vt span {
    vertical-align: middle;
    font-size: 0.9rem;
    font-family: "Noto Sans KR";
}
  
.TournamentBracketForResult_matchLosser__251vt span:last-child {
    padding-left: 12px;
    padding-right: 5px;
}

.TournamentBracketForResult_teamLog__1qjG2 {
    width: 23px;
    height: 23px;
    vertical-align: middle;
    border-radius: 50%;
}

.TournamentBracketForResult_borderTop__1cZQV {
    border-top:1px solid #6b798c
}

.TournamentBracketForResult_borderTopLeft__3lLY1 {
    border-top:1px solid #6b798c;
    border-left:1px solid #6b798c;
}

.TournamentBracketForResult_borderTopRight__1LLhQ {
    border-top:1px solid #6b798c;
    border-right:1px solid #6b798c;
}

.TournamentBracketForResult_borderLeftTop__37ZXO {
    border-left:1px solid #6b798c;
    border-top:1px solid #6b798c;
}

.TournamentBracketForResult_borderRight__3_WDN {
    border-right:1px solid #6b798c;
}

.TournamentBracketForResult_borderLeft__3Ue0K {
    border-left:1px solid #6b798c;
}


@media screen and (max-width: 768px){
    .TournamentBracketForResult_teamLog__1qjG2 {
        width: 15px;
        height: 15px;
        vertical-align: middle;
    }

    .TournamentBracketForResult_matchWinner__2UKzD {
        background: #272f36;
        color: #FF8C00;
        box-sizing: border-box;
        font-weight: 700;
        line-height: 0.7rem;
        text-align: center;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
     }
    
    .TournamentBracketForResult_matchWinner__2UKzD span {
        vertical-align: middle;
        font-size: 0.6rem;
        font-family: "Noto Sans KR";
    }
      
    .TournamentBracketForResult_matchWinner__2UKzD span:last-child {
        padding-left: 2px;
        padding-right: 2px;
    }
    
    .TournamentBracketForResult_matchLosser__251vt {
        background: #182026;
        color: #6b798c;
        box-sizing: border-box;
        font-weight: 700;
        line-height: 0.6rem;
        text-align: center;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
     }
    
    .TournamentBracketForResult_matchLosser__251vt span {
        vertical-align: middle;
        font-size: 0.7rem;
        font-family: "Noto Sans KR";
    }
      
    .TournamentBracketForResult_matchLosser__251vt span:last-child {
        padding-left: 2px;
        padding-right: 2px;
    }

    .TournamentBracketForResult_bracketTable__2OHY0 {
        border: 0;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        padding-left: 1px;
        display: none;
    }
  }
.AuthLevelTab_item_div__XCrYY {
    width: 100px;
    background-color: white;
    padding: 13px 0;
    color: gray;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size:1.1em;
    border-right:1px solid #c0c0c0;
}

.AuthLevelTab_item_div__XCrYY:hover {
    cursor: pointer;
    background-color: #5814E1;
    color: white;
}

.AuthLevelTab_item_div__XCrYY:first-child {
    border-radius: 8px 0 0 8px;
}

.AuthLevelTab_item_div__XCrYY:last-child {
    border-radius: 0 8px 8px 0;
    border:0;
}

@media (min-width: 500px) and (max-width: 768px) {
    .AuthLevelTab_item_div__XCrYY {
        width: 80px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right:1px solid #c0c0c0;
    }
}

@media (max-width: 499px) {
    .AuthLevelTab_item_div__XCrYY {
        width: 70px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right:1px solid #c0c0c0;
    }
 
}
.RankPlayer_rank_div__3Kski * {
    font-family: "Noto Sans KR";
    font-size: 12pt;
}

.RankPlayer_rank_div__3Kski {
    font-family: "Noto Sans KR";
    background-image: url("/images/rank/rank_bg_01.jpg");
    min-height: 1080px;
    height: 100vh;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.RankPlayer_rank_div__3Kski > .RankPlayer_rank_wrap__3Xkko {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.RankPlayer_rank_div__3Kski > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    font-family: "Noto Sans KR";
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.RankPlayer_rank_div__3Kski > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 40px;
    font-family: "Noto Sans KR";
    color: white;
    text-shadow: gray 2px 2px 2px;
}

/* Rectangle 9516 */
.RankPlayer_rank_div__3Kski > :nth-child(2) {
    color: white;
    font-size: 15px;
    justify-content: space-between;
    font-family: "Noto Sans KR";
}

.RankPlayer_rank_div__3Kski > .RankPlayer_rank_wrap__3Xkko .RankPlayer_levelWrap__2-Tjz {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.RankPlayer_radioButton__1YgeT {
    display: flex;
    background-image: url("/images/rank/level_tab_bg.png");
    align-items: center;
    width: 150px;
    height: 41px;
    font-size: 15px;
    border-radius: 20px;
    text-align: center;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 10px;
}

.RankPlayer_radioButton__1YgeT:first-child > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankPlayer_radioButton__1YgeT:first-child > :nth-child(2) {
    margin-right: auto;
}

.RankPlayer_rank_div__3Kski > div.RankPlayer_rank_wrap__3Xkko .RankPlayer_levelWrap__2-Tjz > :nth-child(2) > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankPlayer_rank_div__3Kski > div.RankPlayer_rank_wrap__3Xkko .RankPlayer_levelWrap__2-Tjz > :nth-child(2) > :nth-child(2) {
    margin-right: auto;
}

.RankPlayer_radioButton__1YgeT:last-child > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankPlayer_radioButton__1YgeT:last-child > :nth-child(2) {
    margin-right: auto;
}

.RankPlayer_rank_table__3cyPp {
    width: 1600px;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
    border-collapse: separate;
    border-spacing: 0 20px;
}

.RankPlayer_rank_table__3cyPp > thead > tr {
    height: 40px;
    font-size: 20px;
    color: white;
}

.RankPlayer_rank_table__3cyPp > thead > tr > th {
    background-color: rgba(20, 22, 30, 0.8);
    width: 160px;
}

.RankPlayer_rank_table__3cyPp > thead > tr > th:first-child {
    background-color: rgba(20, 22, 30, 0.8);
    width: 4%;
}

.RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(2) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 5.5%;
}

.RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(3) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 5.5%;
}

.RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(4) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 10%;
}

.RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(5) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 12.5%;
}

.RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(6) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 31.5%;
}

.RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(7) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 15.5%;
}

.RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(8) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 15.5%;
}

.RankPlayer_trophyRow__dljxS {
    width: 4%;
}

.RankPlayer_rank__1ZHqD {
    font-size: 35px;
    width: 5.5%;
}

.RankPlayer_rankImgRow__2dH9z {
    font-size: 18px;
    width: 5.5%;
}

.RankPlayer_ProfileImgRow__2TTlN {
    font-size: 18px;
    width: 10%;
}

.RankPlayer_ProfileName__1mO3_ {
    font-size: 18px;
    padding-left: 7px;
    width: 12.5%;
}

.RankPlayer_TeamRow__2YZ_x {
    font-size: 18px;
    width: 31.5%;
    padding-left: 11px;
}

.RankPlayer_LevelRow__1iGKM {
    font-size: 18px;
    width: 15.5%;
    padding-left: 14px;
}

.RankPlayer_record__-qRds {
    font-size: 18px;
    width: 15.5%;
    padding-left: 14px;
}

.RankPlayer_rank_table__3cyPp > tbody {
    color: white;
}

.RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK {
    background-image: url("/images/rank/rank_back_01.png");
    background-size: 100% 100%;
    max-width: 1600px;
    width: 100%;
    height: 85px;
    background-repeat: no-repeat;
}

.RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK:hover {
    /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
    box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
    background-image: url("/images/rank/rank_back_over.png");
    cursor: pointer;
}

.RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK:hover :nth-child(2) {
    /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
    box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
    color: #ff2358;
}

.RankPlayer_rank_table__3cyPp > tbody > tr > td > .RankPlayer_bodyTable__8Zbh2 {
    height: 500px;
    overflow-y: scroll;
}

.RankPlayer_rank_table__3cyPp > tbody > tr > td > .RankPlayer_bodyTable__8Zbh2::-webkit-scrollbar-thumb {
    background-color: white;
}

.RankPlayer_record__-qRds {
    width: 20%;
}

.RankPlayer_rankUp__1CWVx {
    width: 15px;
    height: 11px;
}

.RankPlayer_rankSame__2rBCS {
    width: 16px;
    height: 8px;
}

.RankPlayer_rankDown__18VyC {
    width: 15px;
    height: 11px;
}

.RankPlayer_rankRecord__FNAxx {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff2358;
    border-radius: 11px;
    width: 100px;
    height: 45px;
    font-size: 15px;
    background-size: cover;
    position: relative;
    margin: auto;
    text-align: center;
}

.RankPlayer_profile__3ENeM {
    width: 44px;
    height: 44px;
    -webkit-clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
}

.RankPlayer_trophy__1iu2- {
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.RankPlayer_table_wrap__uGZcQ {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (max-width: 499px) {
    .RankPlayer_table_wrap__uGZcQ {
        widows: 100vw;
        overflow-x: auto;
    }

    .RankPlayer_rank_div__3Kski > * {
        font-family: "Noto Sans KR";
        font-size: 9pt;
        overflow-x: "auto";
    }

    .RankPlayer_rank_table__3cyPp {
        max-width: 700px;
        /* width: 100%; */
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
        overflow-x: auto;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        height: 5px;
    }
    .RankPlayer_rank_table__3cyPp > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
    }

    .RankPlayer_rank_table__3cyPp > tbody {
        color: white;
    }

    .RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
        overflow-x: "auto";
    }

    .RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK > td:first-child {
        padding-left: 5px;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 5%;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(2) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 9%;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(3) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 9%;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(4) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 10%;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(5) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 12.5%;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(6) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 23.5%;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(7) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 15.5%;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr > :nth-child(8) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 15.5%;
    }

    .RankPlayer_trophyRow__dljxS {
        width: 5%;
    }

    .RankPlayer_rank__1ZHqD {
        font-size: 15px;
        width: 9%;
    }

    .RankPlayer_rankImgRow__2dH9z {
        font-size: 13px;
        width: 9%;
    }

    .RankPlayer_ProfileImgRow__2TTlN {
        font-size: 13px;
        padding-left: 5px;
        width: 10%;
    }

    .RankPlayer_ProfileName__1mO3_ {
        font-size: 13px;
        padding-left: 10px;
        width: 12.5%;
    }

    .RankPlayer_TeamRow__2YZ_x {
        font-size: 13px;
        width: 23.5%;
        padding-left: 10px;
    }

    .RankPlayer_LevelRow__1iGKM {
        font-size: 13px;
        width: 15.5%;
        padding-left: 16px;
    }

    .RankPlayer_record__-qRds {
        font-size: 13px;
        width: 15.5%;
        padding-left: 13px;
    }

    .RankPlayer_rankUp__1CWVx {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankSame__2rBCS {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankDown__18VyC {
        width: 8px;
        height: 5px;
    }

    .RankPlayer_rankRecord__FNAxx {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 50px;
        height: 25px;
        font-size: 7px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankPlayer_profile__3ENeM {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankPlayer_trophy__1iu2- {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankPlayer_radioButton__1YgeT {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 90px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankPlayer_radioButton__1YgeT:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__1YgeT:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }

    .RankPlayer_rank_div__3Kski > div.RankPlayer_rank_wrap__3Xkko .RankPlayer_levelWrap__2-Tjz > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_rank_div__3Kski > div.RankPlayer_rank_wrap__3Xkko .RankPlayer_levelWrap__2-Tjz > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 5px;
    }

    .RankPlayer_radioButton__1YgeT:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__1YgeT:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }
}

@media (min-width: 500px) and (max-width: 767px) {
    .RankPlayer_rank_div__3Kski * {
        font-family: "Noto Sans KR";
        font-size: 9pt;
        overflow-x: "auto";
    }

    .RankPlayer_rank_table__3cyPp {
        max-width: 500px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
        overflow-x: "auto";
    }

    .RankPlayer_rank_table__3cyPp > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        height: 5px;
    }
    .RankPlayer_rank_table__3cyPp > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .RankPlayer_rank_table__3cyPp > tbody {
        color: white;
    }

    .RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
        overflow-x: "auto";
    }

    .RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK > td:first-child {
        padding-left: 5px;
    }

    .RankPlayer_rank__1ZHqD {
        font-size: 1em;
    }

    .RankPlayer_rankUp__1CWVx {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankSame__2rBCS {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankDown__18VyC {
        width: 8px;
        height: 5px;
    }

    .RankPlayer_rankRecord__FNAxx {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 50px;
        height: 25px;
        font-size: 10px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankPlayer_profile__3ENeM {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankPlayer_trophy__1iu2- {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankPlayer_radioButton__1YgeT {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 90px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankPlayer_radioButton__1YgeT:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__1YgeT:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }

    .RankPlayer_rank_div__3Kski > div.RankPlayer_rank_wrap__3Xkko .RankPlayer_levelWrap__2-Tjz > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_rank_div__3Kski > div.RankPlayer_rank_wrap__3Xkko .RankPlayer_levelWrap__2-Tjz > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 5px;
    }

    .RankPlayer_radioButton__1YgeT:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__1YgeT:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }
}
@media (min-width: 768px) and (max-width: 1200px) {
    .RankPlayer_rank_div__3Kski * {
        font-family: "Noto Sans KR";
        font-size: 10pt;
    }

    .RankPlayer_rank_table__3cyPp {
        max-width: 1000px;
        width: 900px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankPlayer_rank_table__3cyPp > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 15px;
        height: 5px;
    }
    .RankPlayer_rank_table__3cyPp > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .RankPlayer_rank_table__3cyPp > tbody {
        color: white;
    }

    .RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 70px;
        background-size: 100% 100%;
    }

    .RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .RankPlayer_rank_table__3cyPp > tbody .RankPlayer_tableRow__3InXK > td:first-child {
        padding-left: 20px;
    }

    .RankPlayer_rank__1ZHqD {
        font-size: 1em;
    }

    .RankPlayer_rankUp__1CWVx {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankSame__2rBCS {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankDown__18VyC {
        width: 8px;
        height: 5px;
    }

    .RankPlayer_rankRecord__FNAxx {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 40px;
        height: 30px;
        font-size: 10px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankPlayer_profile__3ENeM {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankPlayer_trophy__1iu2- {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankPlayer_radioButton__1YgeT {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 100px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankPlayer_radioButton__1YgeT:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__1YgeT:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }

    .RankPlayer_rank_div__3Kski > div.RankPlayer_rank_wrap__3Xkko .RankPlayer_levelWrap__2-Tjz > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_rank_div__3Kski > div.RankPlayer_rank_wrap__3Xkko .RankPlayer_levelWrap__2-Tjz > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 5px;
    }

    .RankPlayer_radioButton__1YgeT:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__1YgeT:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }
}

.RankTeam_rank_div__k-j53 * {
    font-family: "Noto Sans KR";
    font-size: 12pt;
}

.RankTeam_rank_div__k-j53 {
    font-family: "Noto Sans KR";
    background-image: url("/images/rank/rank_bg_01.jpg");
    min-height: 1200px;
    height: 100vh;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.RankTeam_rank_div__k-j53 > .RankTeam_rank_wrap__1_jl3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.RankTeam_rank_div__k-j53 > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    font-family: "Noto Sans KR";
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.RankTeam_rank_div__k-j53 > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 40px;
    font-family: "Noto Sans KR";
    color: white;
    text-shadow: gray 2px 2px 2px;
}

.RankTeam_rank_div__k-j53 > :nth-child(2) {
    color: white;
    font-size: 15px;
    justify-content: space-between;
    font-family: "Noto Sans KR";
}

.RankTeam_rank_div__k-j53 > .RankTeam_rank_wrap__1_jl3 .RankTeam_rankTypeWrap__n0qxB {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: center;
    margin-bottom: 40px;
}

.RankTeam_radioButton__35VoY {
    display: flex;
    background-image: url("/images/rank/level_tab_bg.png");
    align-items: center;
    width: 200px;
    height: 41px;
    font-size: 15px;
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 10px;
}

.RankTeam_radioButton__35VoY:first-child > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankTeam_radioButton__35VoY:first-child > :nth-child(2) {
    margin-right: auto;
}

.RankTeam_rank_div__k-j53 > .RankTeam_rank_wrap__1_jl3 .RankTeam_rankTypeWrap__n0qxB > :nth-child(2) > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankTeam_rank_div__k-j53 > .RankTeam_rank_wrap__1_jl3 .RankTeam_rankTypeWrap__n0qxB > :nth-child(2) > :nth-child(2) {
    margin-right: auto;
}

.RankTeam_radioButton__35VoY:last-child > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankTeam_radioButton__35VoY:last-child > :nth-child(2) {
    margin-right: auto;
}

.RankTeam_rank_table__RCSmt {
    width: 1600px;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
    border-collapse: separate;
    border-spacing: 0 20px;
}

.RankTeam_rank_table__RCSmt > thead > tr {
    height: 40px !important;
    color: white;
}

.RankTeam_rank_table__RCSmt > thead > tr > th {
    background-color: rgba(20, 22, 30, 0.8);
    width: 160px;
}

.RankTeam_rank_table__RCSmt > thead > tr > th:first-child {
    background-color: rgba(20, 22, 30, 0.8);
    width: 5%;
}

.RankTeam_rank_table__RCSmt > thead > tr > :nth-child(2) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 8%;
}

.RankTeam_rank_table__RCSmt > thead > tr > :nth-child(3) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 8%;
}

.RankTeam_rank_table__RCSmt > thead > tr > :nth-child(4) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 8%;
}

.RankTeam_rank_table__RCSmt > thead > tr > :nth-child(5) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 51%;
}

.RankTeam_rank_table__RCSmt > thead > tr > :nth-child(6) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 20%;
}

.RankTeam_rank_table__RCSmt > thead > tr {
    height: 80px;
    color: white;
}

.RankTeam_rank_table__RCSmt > tbody {
    color: white;
}

.RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd {
    background-image: url("/images/rank/rank_back_01.png");
    background-size: 100% 100%;
    max-width: 1600px;
    width: 100%;
    height: 85px;
    background-repeat: no-repeat;
    overflow-x: "auto";
}

.RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd:hover {
    /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
    box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
    background-image: url("/images/rank/rank_back_over.png");
    cursor: pointer;
}

.RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd:hover :nth-child(2) {
    /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
    box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
    color: #ff2358;
}

.RankTeam_rank_table__RCSmt > tbody > tr > td > .RankTeam_bodyTable__3g4dV {
    height: 500px;
    overflow-y: scroll;
}

.RankTeam_rank_table__RCSmt > tbody > tr > td > .RankTeam_bodyTable__3g4dV::-webkit-scrollbar-thumb {
    background-color: white;
}

.RankTeam_rank_table__RCSmt > thead > tr > th:first-child {
    background-color: rgba(20, 22, 30, 0.8);
    width: 5%;
}

.RankTeam_rank_table__RCSmt > thead > tr > :nth-child(2) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 10%;
}

.RankTeam_rank_table__RCSmt > thead > tr > :nth-child(3) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 10%;
}

.RankTeam_rank_table__RCSmt > thead > tr > :nth-child(4) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 10%;
}

.RankTeam_rank_table__RCSmt > thead > tr > :nth-child(5) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 45%;
}

.RankTeam_rank_table__RCSmt > thead > tr > :nth-child(6) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 20%;
}

.RankTeam_trophyRow__21NQU {
    width: 5%;
}

.RankTeam_rank__2iZcC {
    font-size: 35px;
    width: 10%;
}

.RankTeam_rankImgRow__2yisW {
    font-size: 18px;
    width: 10%;
}

.RankTeam_teamLogRow__kZBNg {
    font-size: 18px;
    width: 10%;
    padding-left: 5px;
}

.RankTeam_teamText__1XvsG {
    font-size: 18px;
    width: 45%;
    padding-left: 5px;
}

.RankTeam_record__3-iAk {
    font-size: 18px;
    width: 20%;
    padding-left: 15px;
}

.RankTeam_rankUp__2OBWA {
    width: 15px;
    height: 11px;
}

.RankTeam_moreBg__3FV-W {
    width: 100px;
    height: 100px;
}

.RankTeam_rankSame__2kE2h {
    width: 16px;
    height: 8px;
}

.RankTeam_rankDown__UKOMf {
    width: 15px;
    height: 11px;
}

.RankTeam_rankRecord__oAK4c {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff2358;
    border-radius: 11px;
    width: 122px;
    height: 45px;
    font-size: 15px;
    background-size: cover;
    position: relative;
    margin: auto;
    text-align: center;
}

.RankTeam_teamLog__20TS3 {
    width: 44px;
    height: 44px;
    -webkit-clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
}

.RankTeam_trophy__1E5Y- {
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.RankTeam_competition_table__3syER > tbody > :nth-last-child(1) > td {
    border-bottom: 0px;
    text-align: center;
}

.RankTeam_table_wrap__2yN1h {
    /* display: block; */
    display: flex;
    /* width: 90vw; */
    max-width: 1600px;
    width: 90vw;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* background-color: yellow; */
}

.RankTeam_competition_dropdown__3FCa0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    margin-bottom: 50px;
}

.RankTeam_competition_dropdown__3FCa0 > div > * {
    background-color: transparent !important;
    border-color: white !important;
    color: white !important;
    text-align: center !important;
    /* font-size: 20px; */
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
}

@media (max-width: 499px) {
    .RankTeam_table_wrap__2yN1h {
        width: 300px;
        overflow-x: "scroll";
    }

    .RankTeam_rank_div__k-j53 * {
        font-family: "Noto Sans KR";
        font-size: 9pt;
    }

    .RankTeam_rank_table__RCSmt {
        width: 500px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
    }

    .RankTeam_rank_table__RCSmt > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankTeam_rank_table__RCSmt > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        height: 5px;
    }
    .RankTeam_rank_table__RCSmt > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .RankTeam_rank_table__RCSmt > tbody {
        color: white;
    }

    .RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
        overflow-x: "auto";
    }

    .RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd > td:first-child {
        padding-left: 5px;
    }

    .RankTeam_rank_table__RCSmt > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 5%;
    }

    .RankTeam_rank_table__RCSmt > thead > tr > :nth-child(2) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 10%;
    }

    .RankTeam_rank_table__RCSmt > thead > tr > :nth-child(3) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 10%;
    }

    .RankTeam_rank_table__RCSmt > thead > tr > :nth-child(4) {
        background-color: rgba(20, 22, 30, 0.8);
        padding-left: 12px;
        width: 10%;
    }

    .RankTeam_rank_table__RCSmt > thead > tr > :nth-child(5) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 45%;
    }

    .RankTeam_rank_table__RCSmt > thead > tr > :nth-child(6) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 20%;
    }

    .RankTeam_trophyRow__21NQU {
        width: 5%;
    }

    .RankTeam_rank__2iZcC {
        font-size: 15px;
        padding-right: 16px;
        width: 10%;
    }

    .RankTeam_rankImgRow__2yisW {
        font-size: 13px;
        padding-right: 5px;
        width: 10%;
    }

    .RankTeam_teamLogRow__kZBNg {
        font-size: 13px;
        width: 10%;
    }

    .RankTeam_teamText__1XvsG {
        font-size: 13px;
        padding-left: 5px;
        width: 45%;
    }

    .RankTeam_record__3-iAk {
        font-size: 13px;
        width: 20%;
        padding-left: 27px;
        padding-right: 13px;
    }

    .RankTeam_rankUp__2OBWA {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankSame__2kE2h {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankDown__UKOMf {
        width: 8px;
        height: 5px;
    }

    .RankTeam_rankRecord__oAK4c {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 27px;
        height: 21px;
        font-size: 7px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankTeam_teamLog__20TS3 {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankTeam_trophy__1E5Y- {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankTeam_radioButton__35VoY {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 100px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankTeam_radioButton__35VoY:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__35VoY:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 10px;
    }

    .RankTeam_rank_div__k-j53 > div.RankTeam_rank_wrap__1_jl3 .RankTeam_rankTypeWrap__n0qxB > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_rank_div__k-j53 > div.RankTeam_rank_wrap__1_jl3 .RankTeam_rankTypeWrap__n0qxB > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 22.5px;
    }

    .RankTeam_radioButton__35VoY:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__35VoY:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 11.25px;
    }
}

@media (min-width: 500px) and (max-width: 768px) {
    .RankTeam_rank_div__k-j53 * {
        font-family: "Noto Sans KR";
        font-size: 8pt;
    }

    .RankTeam_rank_table__RCSmt {
        /* max-width: 500px; */
        /* width: 90vw; */
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
        /* background-color: red; */
    }

    .RankTeam_rank_table__RCSmt > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankTeam_rank_table__RCSmt > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 10px;
        height: 5px;
    }
    .RankTeam_rank_table__RCSmt > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .RankTeam_rank_table__RCSmt > tbody {
        color: white;
    }

    .RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
    }

    .RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd > td:first-child {
        padding-left: 10px;
    }

    .RankTeam_rank__2iZcC {
        font-size: 1em;
    }

    .RankTeam_rankUp__2OBWA {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankSame__2kE2h {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankDown__UKOMf {
        width: 8px;
        height: 5px;
    }

    .RankTeam_rankRecord__oAK4c {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 30px;
        height: 25px;
        font-size: 10px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankTeam_teamLog__20TS3 {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankTeam_trophy__1E5Y- {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankTeam_radioButton__35VoY {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 100px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankTeam_radioButton__35VoY:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__35VoY:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 10px;
    }

    .RankTeam_rank_div__k-j53 > div.RankTeam_rank_wrap__1_jl3 .RankTeam_rankTypeWrap__n0qxB > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_rank_div__k-j53 > div.RankTeam_rank_wrap__1_jl3 .RankTeam_rankTypeWrap__n0qxB > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 22.5px;
    }

    .RankTeam_radioButton__35VoY:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__35VoY:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 11.25px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .RankTeam_rank_div__k-j53 * {
        font-family: "Noto Sans KR";
        font-size: 10pt;
    }

    .RankTeam_rank_table__RCSmt {
        max-width: 1000px;
        width: 900px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
    }

    .RankTeam_rank_table__RCSmt > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankTeam_rank_table__RCSmt > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 15px;
        height: 5px;
    }
    .RankTeam_rank_table__RCSmt > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .RankTeam_rank_table__RCSmt > tbody {
        color: white;
    }

    .RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
    }

    .RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
        color: #ff2358;
    }

    .RankTeam_rank_table__RCSmt > tbody .RankTeam_tableRow__2Ahrd > td:first-child {
        padding-left: 20px;
    }

    .RankTeam_rank__2iZcC {
        font-size: 1em;
    }

    .RankTeam_rankUp__2OBWA {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankSame__2kE2h {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankDown__UKOMf {
        width: 8px;
        height: 5px;
    }

    .RankTeam_rankRecord__oAK4c {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 40px;
        height: 30px;
        font-size: 10px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankTeam_teamLog__20TS3 {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankTeam_trophy__1E5Y- {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankTeam_radioButton__35VoY {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 120px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankTeam_radioButton__35VoY:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__35VoY:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 10px;
    }

    .RankTeam_rank_div__k-j53 > div.RankTeam_rank_wrap__1_jl3 .RankTeam_rankTypeWrap__n0qxB > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_rank_div__k-j53 > div.RankTeam_rank_wrap__1_jl3 .RankTeam_rankTypeWrap__n0qxB > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 32.5px;
    }

    .RankTeam_radioButton__35VoY:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__35VoY:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 11.25px;
    }
}

.RankTypeTab_item_div__33tFJ {
    width: 100px;
    background-color: white;
    padding: 13px 0;
    color: gray;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size:1.1em;
    border-right:1px solid #c0c0c0;
}

.RankTypeTab_item_div__33tFJ:hover {
    cursor: pointer;
    background-color: #5814E1;
    color: white;
}

.RankTypeTab_item_div__33tFJ:first-child {
    border-radius: 8px 0 0 8px;
}

.RankTypeTab_item_div__33tFJ:last-child {
    border-radius: 0 8px 8px 0;
    border:0;
}
.LeagueTab_item_div__2u096 {
    width: 100px;
    background-color: white;
    padding: 13px 0;
    color: gray;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size: 1.1em;
    border-right: 1px solid #c0c0c0;
}

.LeagueTab_item_div__2u096:hover {
    cursor: pointer;
    background-color: #5814e1;
    color: white;
}

.LeagueTab_item_div__2u096:first-child {
    border-radius: 8px 0 0 8px;
}

.LeagueTab_item_div__2u096:last-child {
    border-radius: 0 8px 8px 0;
    border: 0;
}

@media (min-width: 500px) and (max-width: 768px) {
    .LeagueTab_item_div__2u096 {
        width: 80px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right: 1px solid #c0c0c0;
    }
}

@media (max-width: 499px) {
    .LeagueTab_item_div__2u096 {
        width: 70px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right: 1px solid #c0c0c0;
    }
}

.RankYouthTeam_intro_tap_box__154Fb {
    background-position: center;
    min-height: 1080px;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #090832; */
}
.ScoreDisplay_wrap_div__RLFCk {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: relative; */
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 150px;
    overflow-y: hidden;
}

.ScoreDisplay_wrap_div__RLFCk > p {
    font-family: "Noto Sans KR";
    color: white;
    font-weight: bold;
}

.ScoreDisplay_wrap_div__RLFCk > :nth-child(1){
    font-size: 3em;
    margin: 0px;
}

.ScoreDisplay_wrap_div__RLFCk > :nth-child(2){
    font-size: 40em;
    margin: 0px;
    padding: 0px;
    width: -webkit-max-content;
    width: max-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.ScoreInput_wrap_div__Q_3M- {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: relative; */
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* padding-top: 150px; */
    overflow-y: hidden;
}

.ScoreInput_wrap_div__Q_3M- > h1 {
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 300px;
    color: white;
}

.ScoreInput_wrap_div__Q_3M- > :nth-child(2) {
    font-size: 80px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding-top: 7px;
}

.ScoreInput_wrap_div__Q_3M- > :nth-child(3) {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    padding-top: 3px;
}

.ScoreInput_wrap_div__Q_3M- > p:hover{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);
}
/* div, p, span {font-size:1em} */
.List_wrap_div__2Imre {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__2Imre * {
    color: white;
}

.List_title_div__2OfoF {
    margin-bottom: 80px;
}

.List_title_div__2OfoF h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__XQzBn {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__XQzBn div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__17j6K {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__17j6K > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__17j6K > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__17j6K > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__17j6K:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__3C1vR {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__3C1vR:hover {
    cursor: pointer;
}

.List_board_pagination__2omVx {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__2omVx > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__2omVx > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__2omVx > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__2NpNR {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__2cAaM {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__1SzO4 {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__1SzO4:hover {
    cursor: pointer;
}

.List_scroll_hidden__2Giqw::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__2Giqw {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__2Imre { padding: 100px 0px; }
    .List_table_content_line__17j6K { width: 100%; }
}
.Content_wrap_div__1GPi2 {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__1GPi2 *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__1GPi2 > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__1WEcS {
    margin-bottom: 80px;
}

.Content_title_div__1WEcS h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__3hG6p {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__nu7oH {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__HAHjV {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__HAHjV:hover {
    cursor: pointer;
}

.Content_submit_btn__zEUvf:hover {
    cursor: pointer;
}

.Content_img_preview__35AfU {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__jiacB iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__jiacB * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__1GPi2 {
        padding: 100px 0px;
    }
    .Content_content_div__3hG6p {
        width: 100%;
    }

    .Content_board_content_div__nu7oH {
        padding: 10px;
    }

    .Content_video_iframe__jiacB iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.ModalConfirm_wrap_div__2OfYA * {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}
.Writer_wrap_div__BTwe2 {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__BTwe2 *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__DEWiJ {
    margin-bottom: 80px;
}

.Writer_title_div__DEWiJ h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__2Ne1J {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__2ImWW {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__2ImWW:hover {
    cursor: pointer;
}

.Writer_del_btn__FY7St {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__FY7St:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__2zeUA  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__BTwe2 { padding: 100px 0px }
    .Writer_content_div__2Ne1J { width: 100vw; }
}
.Modify_wrap_div__Ayrzi {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__Ayrzi *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__2SZKZ {
    margin-bottom: 80px;
}

.Modify_title_div__2SZKZ > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__1gJu9 {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__1opvp {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__1opvp:hover {
    cursor: pointer;
}

.Modify_del_btn__3Rlew {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__3Rlew:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__Ayrzi { padding: 100px 0px; }
    .Modify_content_div__1gJu9 {
        padding: 10px;
    }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__3lYsG {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__3lYsG * {
    color: white;
}

.List_title_div__1KxLG {
    margin-bottom: 80px;
}

.List_title_div__1KxLG h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__1QOcY {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__1QOcY div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__27MFg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__27MFg > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__27MFg > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__27MFg > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__27MFg:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__27anr {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__27anr:hover {
    cursor: pointer;
}

.List_board_pagination__Pxa28 {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__Pxa28 > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__Pxa28 > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__Pxa28 > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__s5zAf {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__1zQEr {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__1OFO8 {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__1OFO8:hover {
    cursor: pointer;
}

.List_scroll_hidden__XuwPa::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__XuwPa {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__3lYsG { padding: 100px 0px; }
    .List_table_content_line__27MFg { width: 100%; }
}
.Content_wrap_div__3BE-n {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__3BE-n *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__3BE-n > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__2Sc4r {
    margin-bottom: 80px;
}

.Content_title_div__2Sc4r h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__2GYpM {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__3my8u {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__RY2ac {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__RY2ac:hover {
    cursor: pointer;
}

.Content_submit_btn__3EVzN:hover {
    cursor: pointer;
}

.Content_img_preview__3wTTr {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__3Rpt8 iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__3Rpt8 * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__3BE-n {
        padding: 100px 0px;
    }
    .Content_content_div__2GYpM {
        width: 100%;
    }

    .Content_board_content_div__3my8u {
        padding: 10px;
    }

    .Content_video_iframe__3Rpt8 iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Writer_wrap_div__3AXrH {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__3AXrH *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__3OYmM {
    margin-bottom: 80px;
}

.Writer_title_div__3OYmM h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__13wQN {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__1fqkP {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__1fqkP:hover {
    cursor: pointer;
}

.Writer_del_btn__P3Sjf {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__P3Sjf:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__ReKkX  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__3AXrH { padding: 100px 0px }
    .Writer_content_div__13wQN { width: 100vw; }
}
.Modify_wrap_div__2Y6fK {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__2Y6fK *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__3cD0p {
    margin-bottom: 80px;
}

.Modify_title_div__3cD0p > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__2pJ4A {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__1Ta1Z {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__1Ta1Z:hover {
    cursor: pointer;
}

.Modify_del_btn__3s-tF {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__3s-tF:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__2Y6fK { padding: 100px 0px; }
    .Modify_content_div__2pJ4A {
        padding: 10px;
    }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__2JH9S {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__2JH9S * {
    color: white;
}

.List_title_div__1yr3r {
    margin-bottom: 80px;
}

.List_title_div__1yr3r h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__3WCHE {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__3WCHE div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__12wpV {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__12wpV > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__12wpV > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__12wpV > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__12wpV:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__wXiFD {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__wXiFD:hover {
    cursor: pointer;
}

.List_board_pagination__404Wh {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__404Wh > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__404Wh > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__404Wh > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__33gFa {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__12T2U {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__1DVpY {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__1DVpY:hover {
    cursor: pointer;
}

.List_scroll_hidden__185Ld::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__185Ld {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__2JH9S { padding: 100px 0px; }
    .List_table_content_line__12wpV { width: 100%; }
}
.Content_wrap_div__188Bk {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__188Bk *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__188Bk > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__3UYM6 {
    margin-bottom: 80px;
}

.Content_title_div__3UYM6 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__3Dngd {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__2kfJA {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn___dy8_ {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn___dy8_:hover {
    cursor: pointer;
}

.Content_submit_btn__z6n-7:hover {
    cursor: pointer;
}

.Content_img_preview__3VSzC {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__3J7mL iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__3J7mL * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__188Bk {
        padding: 100px 0px;
    }
    .Content_content_div__3Dngd {
        width: 100%;
    }

    .Content_board_content_div__2kfJA {
        padding: 10px;
    }

    .Content_video_iframe__3J7mL iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.EducationApply_wrap_div__2s3-o * {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}
.Writer_wrap_div__bjYVj {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__bjYVj *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__3SxZC {
    margin-bottom: 80px;
}

.Writer_title_div__3SxZC h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__XrT0s {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__3ZMrj {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__3ZMrj:hover {
    cursor: pointer;
}

.Writer_del_btn__VHZXQ {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__VHZXQ:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__aGSED  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__bjYVj { padding: 100px 0px }
    .Writer_content_div__XrT0s { width: 100vw; }
}
.Modify_wrap_div__RSMwH {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__RSMwH *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__3RHdt {
    margin-bottom: 80px;
}

.Modify_title_div__3RHdt > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__fLZiO {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__qZxN- {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__qZxN-:hover {
    cursor: pointer;
}

.Modify_del_btn__1IZDw {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__1IZDw:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__RSMwH { padding: 100px 0px; }
    .Modify_content_div__fLZiO {
        padding: 10px;
    }
}
.ApplyTeamRegistration_wrap_div__Y5pbj * {
    font-family: "Noto Sans KR";
}

.ApplyTeamRegistration_wrap_div__Y5pbj {
    /* background-color: #F7F8Fd; */
    background-color: #F4F8FF;
    padding: 180px 30px;
    font-family: "Noto Sans KR";;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    min-height: 100vh;
    width: 100vw;
    height: 100%;
}



.ApplyTeamRegistration_menu_div__1M20B {
    height: -webkit-max-content;
    height: max-content;
    border-radius: 5px;
    padding: 25px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 30px;
}

.ApplyTeamRegistration_menu_div__1M20B> h1 {
    font-size: 20px;
    margin: 0px;
}

.ApplyTeamRegistration_menu_div__1M20B > hr {
    border: 0;
    border-top: 1px solid rgba(17, 12, 46, 0.15);
    width: 100%;
    margin: 10px 0px;
}

.ApplyTeamRegistration_menu_div__1M20B > p {
    /* background-color: red; */
    margin: 0px;
    padding: 5px;
}

.ApplyTeamRegistration_menu_div__1M20B > p:hover {
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}

.ApplyTeamRegistration_content_div__2VRHs {
    height: -webkit-max-content;
    height: max-content;
    position: relative;
    width: 90%;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    min-height: 472px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.ApplyTeamRegistration_datepicker_input__3jaAQ > input {
    border: 1px solid #000000 !important;
}
/* div, p, span {font-size:1em} */
.List_wrap_div__1SD5t {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__1SD5t * {
    color: white;
}

.List_title_div__1POZa {
    margin-bottom: 80px;
}

.List_title_div__1POZa h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__21h4h {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__21h4h div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__1zGiV {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__1zGiV > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__1zGiV > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__1zGiV > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__1zGiV:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__uqzj1 {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__uqzj1:hover {
    cursor: pointer;
}

.List_board_pagination__1Qi3X {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__1Qi3X > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__1Qi3X > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__1Qi3X > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__2lliD {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__3mL1u {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__2GyCK {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__2GyCK:hover {
    cursor: pointer;
}

.List_scroll_hidden__3hb0a::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__3hb0a {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__1SD5t { padding: 100px 0px; }
    .List_table_content_line__1zGiV { width: 100%; }
}
.Content_wrap_div__124Qp {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__124Qp *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__124Qp > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__2woK5 {
    margin-bottom: 80px;
}

.Content_title_div__2woK5 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__2RBlN {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__2Xuq_ {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__3Eokl {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__3Eokl:hover {
    cursor: pointer;
}

.Content_submit_btn__3V_mL:hover {
    cursor: pointer;
}

.Content_img_preview__2AOCv {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__39ffB iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__39ffB * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__124Qp {
        padding: 100px 0px;
    }
    .Content_content_div__2RBlN {
        width: 100%;
    }

    .Content_board_content_div__2Xuq_ {
        padding: 10px;
    }

    .Content_video_iframe__39ffB iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Writer_wrap_div__3h4tM {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__3h4tM *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__20Lo5 {
    margin-bottom: 80px;
}

.Writer_title_div__20Lo5 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__3EoEo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__Glmum {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__Glmum:hover {
    cursor: pointer;
}

.Writer_del_btn__23pyG {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__23pyG:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__3H_PF  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__3h4tM { padding: 100px 0px }
    .Writer_content_div__3EoEo { width: 100vw; }
}
.Modify_wrap_div__3LbEA {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__3LbEA *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__DLJJd {
    margin-bottom: 80px;
}

.Modify_title_div__DLJJd > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__2KYhl {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__dhbcH {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__dhbcH:hover {
    cursor: pointer;
}

.Modify_del_btn__3r8qq {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__3r8qq:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__3LbEA { padding: 100px 0px; }
    .Modify_content_div__2KYhl {
        padding: 10px;
    }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__2Ygn_ {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__2Ygn_ * {
    color: white;
}

.List_title_div__2E_Ax {
    margin-bottom: 80px;
}

.List_title_div__2E_Ax h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__1xlOS {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__1xlOS div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__2mSuq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__2mSuq > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__2mSuq > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__2mSuq > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__2mSuq:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__1_i-U {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__1_i-U:hover {
    cursor: pointer;
}

.List_board_pagination__3PueK {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__3PueK > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__3PueK > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__3PueK > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__3LY9Q {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__5h8vY {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__2uD4n {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__2uD4n:hover {
    cursor: pointer;
}

.List_scroll_hidden__tDRTa::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__tDRTa {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__2Ygn_ { padding: 100px 0px; }
    .List_table_content_line__2mSuq { width: 100%; }
}
.Content_wrap_div__1IMRz {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__1IMRz *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__1IMRz > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__3wL_Y {
    margin-bottom: 80px;
}

.Content_title_div__3wL_Y h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__1Eouc {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__3jgVc {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__2dKbM {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__2dKbM:hover {
    cursor: pointer;
}

.Content_submit_btn__2eSp3:hover {
    cursor: pointer;
}

.Content_img_preview__3KuIN {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__3zjpv iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__3zjpv * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__1IMRz {
        padding: 100px 0px;
    }
    .Content_content_div__1Eouc {
        width: 100%;
    }

    .Content_board_content_div__3jgVc {
        padding: 10px;
    }

    .Content_video_iframe__3zjpv iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__2o-e7 {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__2o-e7 *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__1Y_CD {
    margin-bottom: 80px;
}

.Modify_title_div__1Y_CD > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__3Zgj7 {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__3cD1Q {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__3cD1Q:hover {
    cursor: pointer;
}

.Modify_del_btn__L4_P7 {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__L4_P7:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__2o-e7 { padding: 100px 0px; }
    .Modify_content_div__3Zgj7 {
        padding: 10px;
    }
}
.Writer_wrap_div__24d_j {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__24d_j *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__29gMi {
    margin-bottom: 80px;
}

.Writer_title_div__29gMi h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__1wZDb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__1v69- {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__1v69-:hover {
    cursor: pointer;
}

.Writer_del_btn__2iOfc {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__2iOfc:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__1xMcc  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__24d_j { padding: 100px 0px }
    .Writer_content_div__1wZDb { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__29bd4 {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__29bd4 * {
    color: white;
}

.List_title_div__3URlJ {
    margin-bottom: 80px;
}

.List_title_div__3URlJ h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__2C0M4 {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__2C0M4 div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__3Gow8 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__3Gow8 > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__3Gow8 > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__3Gow8 > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__3Gow8:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__3UOa0 {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__3UOa0:hover {
    cursor: pointer;
}

.List_board_pagination__3s6nk {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__3s6nk > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__3s6nk > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__3s6nk > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__ycA7h {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__10aPR {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__2xmbQ {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__2xmbQ:hover {
    cursor: pointer;
}

.List_scroll_hidden__3hn_0::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__3hn_0 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__29bd4 { padding: 100px 0px; }
    .List_table_content_line__3Gow8 { width: 100%; }
}
.Content_wrap_div__rAquy {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__rAquy *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__rAquy > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__2hScq {
    margin-bottom: 80px;
}

.Content_title_div__2hScq h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__3Qhxk {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__3H643 {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__2tJKt {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__2tJKt:hover {
    cursor: pointer;
}

.Content_submit_btn__2ly-4:hover {
    cursor: pointer;
}

.Content_img_preview__3ZHKa {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__3ps3c iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__3ps3c * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__rAquy {
        padding: 100px 0px;
    }
    .Content_content_div__3Qhxk {
        width: 100%;
    }

    .Content_board_content_div__3H643 {
        padding: 10px;
    }

    .Content_video_iframe__3ps3c iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__2r4aP {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__2r4aP *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__3uvbz {
    margin-bottom: 80px;
}

.Modify_title_div__3uvbz > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__3yq93 {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__288Mh {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__288Mh:hover {
    cursor: pointer;
}

.Modify_del_btn__kh0FG {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__kh0FG:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__2r4aP { padding: 100px 0px; }
    .Modify_content_div__3yq93 {
        padding: 10px;
    }
}
.Writer_wrap_div__1NMpN {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__1NMpN *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__12_48 {
    margin-bottom: 80px;
}

.Writer_title_div__12_48 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__2zsuV {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__1CwJy {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__1CwJy:hover {
    cursor: pointer;
}

.Writer_del_btn__2JOCD {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__2JOCD:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__1C_9s  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__1NMpN { padding: 100px 0px }
    .Writer_content_div__2zsuV { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__2X6Dc {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__2X6Dc * {
    color: white;
}

.List_title_div__2qSoK {
    margin-bottom: 80px;
}

.List_title_div__2qSoK h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__25Srp {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__25Srp div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__3kKqk {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__3kKqk > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__3kKqk > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__3kKqk > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__3kKqk:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__3EpGK {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__3EpGK:hover {
    cursor: pointer;
}

.List_board_pagination__Xah4Y {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__Xah4Y > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__Xah4Y > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__Xah4Y > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__5OqRs {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__3lflD {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__t-C5a {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__t-C5a:hover {
    cursor: pointer;
}

.List_scroll_hidden__3T5Xz::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__3T5Xz {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__2X6Dc { padding: 100px 0px; }
    .List_table_content_line__3kKqk { width: 100%; }
}
.Content_wrap_div__1hbIe {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__1hbIe *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__1hbIe > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__1gO-8 {
    margin-bottom: 80px;
}

.Content_title_div__1gO-8 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__1XpVZ {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__x9ALH {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__xGRzO {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__xGRzO:hover {
    cursor: pointer;
}

.Content_submit_btn__1qaLS:hover {
    cursor: pointer;
}

.Content_img_preview__3jk4T {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__1LmAG iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__1LmAG * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__1hbIe {
        padding: 100px 0px;
    }
    .Content_content_div__1XpVZ {
        width: 100%;
    }

    .Content_board_content_div__x9ALH {
        padding: 10px;
    }

    .Content_video_iframe__1LmAG iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__2rADR {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__2rADR *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__jFdOc {
    margin-bottom: 80px;
}

.Modify_title_div__jFdOc > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__1o6Ho {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__180ex {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__180ex:hover {
    cursor: pointer;
}

.Modify_del_btn__3wULC {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__3wULC:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__2rADR { padding: 100px 0px; }
    .Modify_content_div__1o6Ho {
        padding: 10px;
    }
}
.Writer_wrap_div__1pbqu {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__1pbqu *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__2wu_D {
    margin-bottom: 80px;
}

.Writer_title_div__2wu_D h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__thVao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__44BAf {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__44BAf:hover {
    cursor: pointer;
}

.Writer_del_btn__2KLnz {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__2KLnz:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__3NSx4  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__1pbqu { padding: 100px 0px }
    .Writer_content_div__thVao { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__2MvmX {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__2MvmX * {
    color: white;
}

.List_title_div__TfNhW {
    margin-bottom: 80px;
}

.List_title_div__TfNhW h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__3ziRV {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__3ziRV div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__18iS0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__18iS0 > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__18iS0 > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__18iS0 > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__18iS0:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__2aLrh {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__2aLrh:hover {
    cursor: pointer;
}

.List_board_pagination__2rVJG {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__2rVJG > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__2rVJG > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__2rVJG > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__20ZsN {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__3zxR9 {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__14_2c {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__14_2c:hover {
    cursor: pointer;
}

.List_scroll_hidden__3oZDt::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__3oZDt {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__2MvmX { padding: 100px 0px; }
    .List_table_content_line__18iS0 { width: 100%; }
}
.Content_wrap_div__2hsCH {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__2hsCH *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__2hsCH > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__1rz6Q {
    margin-bottom: 80px;
}

.Content_title_div__1rz6Q h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__1ACr6 {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__2axwv {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__1IcHk {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__1IcHk:hover {
    cursor: pointer;
}

.Content_submit_btn__2hKCp:hover {
    cursor: pointer;
}

.Content_img_preview__2mB5P {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__309Hd iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__309Hd * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__2hsCH {
        padding: 100px 0px;
    }
    .Content_content_div__1ACr6 {
        width: 100%;
    }

    .Content_board_content_div__2axwv {
        padding: 10px;
    }

    .Content_video_iframe__309Hd iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__2YQOg {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__2YQOg *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__2XIw_ {
    margin-bottom: 80px;
}

.Modify_title_div__2XIw_ > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__1QC72 {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__212j_ {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__212j_:hover {
    cursor: pointer;
}

.Modify_del_btn__2RjwE {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__2RjwE:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__2YQOg { padding: 100px 0px; }
    .Modify_content_div__1QC72 {
        padding: 10px;
    }
}
.Writer_wrap_div__1Tj_q {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__1Tj_q *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__3lQD2 {
    margin-bottom: 80px;
}

.Writer_title_div__3lQD2 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__1FTpY {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__1cJ_Q {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__1cJ_Q:hover {
    cursor: pointer;
}

.Writer_del_btn__2u2C6 {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__2u2C6:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__19tbG  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__1Tj_q { padding: 100px 0px }
    .Writer_content_div__1FTpY { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__Ff8SY {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__Ff8SY * {
    color: white;
}

.List_title_div__3_qGr {
    margin-bottom: 80px;
}

.List_title_div__3_qGr h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__2K956 {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__2K956 div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__BqzEA {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__BqzEA > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__BqzEA > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__BqzEA > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__BqzEA:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__2ScYc {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__2ScYc:hover {
    cursor: pointer;
}

.List_board_pagination__38Pd- {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__38Pd- > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__38Pd- > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__38Pd- > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__18ixh {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__E7c2l {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__2dV1k {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__2dV1k:hover {
    cursor: pointer;
}

.List_scroll_hidden__13fiO::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__13fiO {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__Ff8SY { padding: 100px 0px; }
    .List_table_content_line__BqzEA { width: 100%; }
}
.Content_wrap_div__MCMJI {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__MCMJI *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__MCMJI > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__3MWDv {
    margin-bottom: 80px;
}

.Content_title_div__3MWDv h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__1qtCn {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__25W6n {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__1TXv6 {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__1TXv6:hover {
    cursor: pointer;
}

.Content_submit_btn__2Vy8Q:hover {
    cursor: pointer;
}

.Content_img_preview__1aJKv {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__1pqz- iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__1pqz- * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__MCMJI {
        padding: 100px 0px;
    }
    .Content_content_div__1qtCn {
        width: 100%;
    }

    .Content_board_content_div__25W6n {
        padding: 10px;
    }

    .Content_video_iframe__1pqz- iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__1GfNm {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__1GfNm *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__1ImpY {
    margin-bottom: 80px;
}

.Modify_title_div__1ImpY > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__3Cd9v {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__3smE9 {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__3smE9:hover {
    cursor: pointer;
}

.Modify_del_btn__31Vnd {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__31Vnd:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__1GfNm { padding: 100px 0px; }
    .Modify_content_div__3Cd9v {
        padding: 10px;
    }
}
.Writer_wrap_div__uU2vx {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__uU2vx *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__ntDU3 {
    margin-bottom: 80px;
}

.Writer_title_div__ntDU3 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__1jfBL {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__3gD78 {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__3gD78:hover {
    cursor: pointer;
}

.Writer_del_btn__3YQxo {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__3YQxo:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__2ivzB  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__uU2vx { padding: 100px 0px }
    .Writer_content_div__1jfBL { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__2iTRK {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__2iTRK * {
    color: white;
}

.List_title_div__39MNt {
    margin-bottom: 80px;
}

.List_title_div__39MNt h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__3awMt {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__3awMt div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__EI8xd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__EI8xd > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__EI8xd > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__EI8xd > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__EI8xd:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__1RJNN {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__1RJNN:hover {
    cursor: pointer;
}

.List_board_pagination__2OWH5 {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__2OWH5 > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__2OWH5 > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__2OWH5 > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__24zXz {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__2QUiT {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__3be41 {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__3be41:hover {
    cursor: pointer;
}

.List_scroll_hidden__1Rwdp::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__1Rwdp {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__2iTRK { padding: 100px 0px; }
    .List_table_content_line__EI8xd { width: 100%; }
}
.Content_wrap_div__1hjRw {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__1hjRw *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__1hjRw > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__chRlo {
    margin-bottom: 80px;
}

.Content_title_div__chRlo h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__3NIxh {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__3zJgY {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__3mcaf {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__3mcaf:hover {
    cursor: pointer;
}

.Content_submit_btn__2KVlU:hover {
    cursor: pointer;
}

.Content_img_preview__JibBf {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__2KWdV iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__2KWdV * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__1hjRw {
        padding: 100px 0px;
    }
    .Content_content_div__3NIxh {
        width: 100%;
    }

    .Content_board_content_div__3zJgY {
        padding: 10px;
    }

    .Content_video_iframe__2KWdV iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__3Vkaa {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__3Vkaa *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__UnQYm {
    margin-bottom: 80px;
}

.Modify_title_div__UnQYm > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__UwhqK {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__2sk1q {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__2sk1q:hover {
    cursor: pointer;
}

.Modify_del_btn__1seA9 {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__1seA9:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__3Vkaa { padding: 100px 0px; }
    .Modify_content_div__UwhqK {
        padding: 10px;
    }
}
.Writer_wrap_div__2IRyV {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__2IRyV *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__1fNnR {
    margin-bottom: 80px;
}

.Writer_title_div__1fNnR h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__d51hY {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__1onzH {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__1onzH:hover {
    cursor: pointer;
}

.Writer_del_btn__3xLZY {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__3xLZY:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__3irAf  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__2IRyV { padding: 100px 0px }
    .Writer_content_div__d51hY { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__1QhTJ {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__1QhTJ * {
    color: white;
}

.List_title_div__2QGIL {
    margin-bottom: 80px;
}

.List_title_div__2QGIL h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__3UGbS {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__3UGbS div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__3EBgj {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__3EBgj > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__3EBgj > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__3EBgj > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__3EBgj:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__1KmeF {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__1KmeF:hover {
    cursor: pointer;
}

.List_board_pagination__1bEgT {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__1bEgT > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__1bEgT > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__1bEgT > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__3LcTW {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__3ADNR {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__Csc5t {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__Csc5t:hover {
    cursor: pointer;
}

.List_scroll_hidden__2MYE1::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__2MYE1 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__1QhTJ { padding: 100px 0px; }
    .List_table_content_line__3EBgj { width: 100%; }
}
.Content_wrap_div__3Rwze {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__3Rwze *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__3Rwze > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__nGiok {
    margin-bottom: 80px;
}

.Content_title_div__nGiok h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__1nTk4 {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__2D-x6 {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__1YecM {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__1YecM:hover {
    cursor: pointer;
}

.Content_submit_btn__12ejY:hover {
    cursor: pointer;
}

.Content_img_preview__24JTe {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__2HZ_C iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__2HZ_C * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__3Rwze {
        padding: 100px 0px;
    }
    .Content_content_div__1nTk4 {
        width: 100%;
    }

    .Content_board_content_div__2D-x6 {
        padding: 10px;
    }

    .Content_video_iframe__2HZ_C iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__3jTQI {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__3jTQI *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__1g0zh {
    margin-bottom: 80px;
}

.Modify_title_div__1g0zh > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__1LK_y {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__2oFGO {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__2oFGO:hover {
    cursor: pointer;
}

.Modify_del_btn__FOe3c {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__FOe3c:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__3jTQI { padding: 100px 0px; }
    .Modify_content_div__1LK_y {
        padding: 10px;
    }
}
.Writer_wrap_div__1k37u {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__1k37u *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__1fMco {
    margin-bottom: 80px;
}

.Writer_title_div__1fMco h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__1TtEM {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__1JYtu {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__1JYtu:hover {
    cursor: pointer;
}

.Writer_del_btn__quwY6 {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__quwY6:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__1dTHp  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__1k37u { padding: 100px 0px }
    .Writer_content_div__1TtEM { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__L7_3K {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__L7_3K * {
    color: white;
}

.List_title_div__qNN9D {
    margin-bottom: 80px;
}

.List_title_div__qNN9D h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__3WWoi {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__3WWoi div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__1vQNv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__1vQNv > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__1vQNv > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__1vQNv > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__1vQNv:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__2hnnK {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__2hnnK:hover {
    cursor: pointer;
}

.List_board_pagination__j4Ofw {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__j4Ofw > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__j4Ofw > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__j4Ofw > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__3mJtE {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__1vQ_2 {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__3IQqU {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__3IQqU:hover {
    cursor: pointer;
}

.List_scroll_hidden__1aQR2::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__1aQR2 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__L7_3K { padding: 100px 0px; }
    .List_table_content_line__1vQNv { width: 100%; }
}
.Content_wrap_div__2fuD3 {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__2fuD3 *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__2fuD3 > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__iBXtq {
    margin-bottom: 80px;
}

.Content_title_div__iBXtq h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__2NNHO {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__36vlt {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__IbiSK {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__IbiSK:hover {
    cursor: pointer;
}

.Content_submit_btn__3BrSs:hover {
    cursor: pointer;
}

.Content_img_preview__2M70l {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__ie4zt iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__ie4zt * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__2fuD3 {
        padding: 100px 0px;
    }
    .Content_content_div__2NNHO {
        width: 100%;
    }

    .Content_board_content_div__36vlt {
        padding: 10px;
    }

    .Content_video_iframe__ie4zt iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__2xnfR {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__2xnfR *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__2bdXh {
    margin-bottom: 80px;
}

.Modify_title_div__2bdXh > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__1QhbA {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__3GvFQ {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__3GvFQ:hover {
    cursor: pointer;
}

.Modify_del_btn__Bw6ii {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__Bw6ii:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__2xnfR { padding: 100px 0px; }
    .Modify_content_div__1QhbA {
        padding: 10px;
    }
}
.Writer_wrap_div__1ZUN3 {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__1ZUN3 *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__3TIoB {
    margin-bottom: 80px;
}

.Writer_title_div__3TIoB h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__EnJ2O {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__2_V8N {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__2_V8N:hover {
    cursor: pointer;
}

.Writer_del_btn__NqzSV {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__NqzSV:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__1_AP-  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__1ZUN3 { padding: 100px 0px }
    .Writer_content_div__EnJ2O { width: 100vw; }
}
.Kiosk_allWrap__1infB {
    background-image: url(/images/kiosk/back_01.jpg);
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Kiosk_kioskWrap__4IfoL {
    background-image: url(/images/kiosk/20201130.png);
    background-size: cover;
    width: 1920px;
    height: 1080px;
    flex-direction: column;
}

.Kiosk_card__11rqD {
    width:231px;
    height: 367px;
}
.AdminManager_wrap_div__3ypqx * {
    font-family: "Noto Sans KR";
}

.AdminManager_wrap_div__3ypqx {
    /* background-color: #F7F8Fd; */
    background-color: #F4F8FF;
    padding: 100px 30px;
    font-family: "Noto Sans KR";;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    min-height: 100vh;
    width: 100vw;
    height: 100%;
}

.AdminManager_content_div__35unp {
    height: -webkit-max-content;
    height: max-content;
    position: relative;
    width: 90%;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    min-height: 472px;
}
.LeftMenu_menu_div__xsUX_ {
    /* width: 150px; */
    height: -webkit-max-content;
    height: max-content;
    border-radius: 5px;
    padding: 25px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 30px;
    min-width: 200px;
}

.LeftMenu_menu_div__xsUX_> h1 {
    font-size: 20px;
    margin: 0px;
}

.LeftMenu_menu_div__xsUX_ > hr {
    border: 0;
    border-top: 1px solid rgba(17, 12, 46, 0.15);
    width: 100%;
    margin: 10px 0px;
}

.LeftMenu_menu_div__xsUX_ > p {
    /* background-color: red; */
    margin: 0px;
    padding: 5px;
}

.LeftMenu_menu_div__xsUX_ > p:hover {
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}

.LeftMenu_alarm_div1__1tZa_ {
    background-color: #F4F8FF;
    height: -webkit-max-content;
    height: max-content;
    border-radius: 5px;
    padding: 5px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.LeftMenu_alarm_div1__1tZa_:hover{
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}

.LeftMenu_alarm_div2__3VHt7 {
    background-color: #F4F8FF;
    height: -webkit-max-content;
    height: max-content;
    border-radius: 5px;
    padding: 5px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.LeftMenu_alarm_div2__3VHt7:hover {
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}
.competition_redHederText__v02WS{
    color: red;
    font-size: 1em;
}


.competition_blueHederText__3sgKi{
    color: blue;
    font-size: 1em;
}

.competition_redText__22w6U{
    color: red;
    font-size: 1em;
}

.competition_blueText__3UyQw{
    color: blue;
    font-size: 1em;
}

.ScoreModal_set_drop__3tCpw {
    /* background-color: rgba(255, 255, 255, 0) !important; */
    font-size: 15px;
    width: 200px !important;
    margin-bottom: 40px;
}
.ScoreModal_score_div__3w3c6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.ScoreModal_score_div__3w3c6 > div {
    margin: 0px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.ScoreModal_score_div__3w3c6 > :nth-child(1) {
    background-color: red;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: "Noto Sans KR";
    width: 150px;
    height: 150px;
}

.ScoreModal_score_div__3w3c6 > :nth-child(2) {
    background-color: blue;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: "Noto Sans KR";
    width: 150px;
    height: 150px;
}

.ScoreModal_score_div__3w3c6 > div:hover {
    cursor: pointer;
}


.PlayerSearch_tableRow__BFamD:hover{
    background-color: rgba(0, 0, 0, 0.05) !important;
    cursor: pointer;
}
.CompetitionManager_noDataWrap__3QGUy {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}

.TeamSearch_tableRow__1Ne31:hover{
    background-color: rgba(0, 0, 0, 0.05) !important;
    cursor: pointer;
}
.CsvDownloadManager_table_row__MYShK:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.PaymentManager_table_row__Uoqia:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
}
.TeamInfo_datepicker_input__1EeW5 > input {
    border: 1px solid #000000 !important;
}
.TeamPlayers_player_tableRow__1_iCw:hover { 
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.08) !important;
 }
.ChangeManager_mouse_hover__MOuuI:hover {
    cursor: pointer;
}
.TeamRequestsManage_wrap_div__1sgX7{
    /* padding: 180px 0px !important; */
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    position: relative;
}

.TeamRequestsManage_content_div__17ze5 {
    max-width: 1200px;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TeamRequestsManage_teamRequests_table__A5402 > tr:hover {
    cursor: pointer;
    background-color: rgba(0,0,50,.1) !important;
  }
  
.ApplyTeamRegistrationManager_wrap_div__rhlkL{
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    position: relative;
}

.ApplyTeamRegistrationManager_content_div__1Z3gH {
    max-width: 1200px;
    height: 100%;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ApplyTeamRegistrationManager_teamRegistrations_table__1dzAK > tr:hover {
    cursor: pointer;
    background-color: rgba(0,0,50,.1) !important;
  }
  
.ApplyTeamRegistrationForm_content_div__3izWS {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ApplyTeamRegistrationForm_content_div__3izWS * {
    font-family: "Noto Sans KR";
}

.ApplyTeamRegistrationForm_content_div__3izWS span {
    color: red;
    font-weight: bold;
}



.ApplyTeamRegistrationForm_content_div__3izWS > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px;
}

/* .content_div > div > p {
    margin: 0px;
    font-size: 15px;
} */
.ApplyTeamRegistrationForm_content_div__3izWS > div > :nth-child(1) {
    margin: 0px;
    font-size: 15px;
    flex: 1 1;
}



.ApplyTeamRegistrationForm_content_div__3izWS > div > :nth-child(2) {
    text-align: center;
    flex: 3 1;
}
@keyframes AdultSelect_move-twink-back__1pGYm {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes AdultSelect_move-twink-back__1pGYm {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes AdultSelect_move-clouds-back__2YaBR {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes AdultSelect_move-clouds-back__2YaBR {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.AdultSelect_stars__1_i16, .AdultSelect_twinkling__JYLqY, .AdultSelect_clouds__hQCtF {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  /* min-height: 1080px; */
  display:block;
}

.AdultSelect_stars__1_i16 {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.AdultSelect_twinkling__JYLqY{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:AdultSelect_move-twink-back__1pGYm 200s linear infinite;
  animation:AdultSelect_move-twink-back__1pGYm 200s linear infinite;
}

.AdultSelect_clouds__hQCtF{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:AdultSelect_move-clouds-back__2YaBR 200s linear infinite;
  animation:AdultSelect_move-clouds-back__2YaBR 200s linear infinite;
}


.AdultSelect_wrap_div__J5hwr {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AdultSelect_content_div__1b8cx {
    border: 0px;
    box-shadow: 0 0 30px white !important;
    border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 450px;
}
.AdultSelect_content_div__1b8cx > p {
    color: #F0F0F0;
    font-size: 17px;
    font-weight: bold;
    /* position: relative; */
    /* top: -30px; */
    margin-bottom: 40px;
    margin-top: -40px;
}

.AdultSelect_content_div__1b8cx > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 60px;
}

.AdultSelect_memberselect_div__19IdU {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AdultSelect_memberselect_div__19IdU > p {
    color: #F0F0F0;

}

.AdultSelect_style_a__3HEAX {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    /* margin-top: 40px; */
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.AdultSelect_style_a__3HEAX:hover {
    cursor: pointer;
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 100px #03e9f4;
  }

.AdultSelect_style_a1__1Oklp {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #F22121;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    /* margin-top: 40px; */
    top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.AdultSelect_style_a1__1Oklp:hover {
    cursor: pointer;
    background: #F22121;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #F22121,
                0 0 25px #F22121,
                0 0 50px #F22121,
                0 0 100px #F22121;
  }

  @media screen and (max-width: 768px) {
    .AdultSelect_content_div__1b8cx {
        width: 80vw;
        height: 700px;
    }
    /* .content_div > p {
        top: -20px;
    } */
    .AdultSelect_content_div__1b8cx > div {
        flex-direction: column;
    }
    .AdultSelect_style_a__3HEAX {
        margin-bottom: 20px;
    }
    .AdultSelect_style_a1__1Oklp {
        top: 30px;
    }
  }
@keyframes IdentityVerification_move-twink-back__RioQZ {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes IdentityVerification_move-twink-back__RioQZ {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes IdentityVerification_move-clouds-back__iTZhB {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes IdentityVerification_move-clouds-back__iTZhB {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.IdentityVerification_stars__25PQ2, .IdentityVerification_twinkling__3-Wsz, .IdentityVerification_clouds__3pCSN {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  /* min-height: 1080px; */
  display:block;
}

.IdentityVerification_stars__25PQ2 {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.IdentityVerification_twinkling__3-Wsz{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:IdentityVerification_move-twink-back__RioQZ 200s linear infinite;
  animation:IdentityVerification_move-twink-back__RioQZ 200s linear infinite;
}

.IdentityVerification_clouds__3pCSN{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:IdentityVerification_move-clouds-back__iTZhB 200s linear infinite;
  animation:IdentityVerification_move-clouds-back__iTZhB 200s linear infinite;
}


.IdentityVerification_wrap_div__3QF8y {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    /* padding: 100px; */
    /* background-color: red !important; */
}

.IdentityVerification_content_div__1yCHI {
    border: 0px;
    box-shadow: 0 0 30px white !important;
    border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 80px 0px;
}
.IdentityVerification_content_div__1yCHI > p {
    color: #F0F0F0;
    font-size: 17px;
    font-weight: bold;
    position: relative;
    top: -40px;
    margin-bottom: 20px;
    /* margin-top: -20px; */
}

.IdentityVerification_content_div__1yCHI > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 60px;
}

.IdentityVerification_memberselect_div__3V7ON {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.IdentityVerification_memberselect_div__3V7ON > p {
    color: #F0F0F0;

}

.IdentityVerification_style_a__1c5n_ {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    /* margin-top: 40px; */
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.IdentityVerification_style_a__1c5n_:hover {
    cursor: pointer;
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 100px #03e9f4;
  }

.IdentityVerification_style_a1__FMSLU {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #F22121;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    /* margin-top: 40px; */
    top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.IdentityVerification_style_a1__FMSLU:hover {
    cursor: pointer;
    background: #F22121;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #F22121,
                0 0 25px #F22121,
                0 0 50px #F22121,
                0 0 100px #F22121;
  }

  @media screen and (max-width: 768px) {
    .IdentityVerification_total_div__1bw1Y {
        height: 150vh !important;
    }
    .IdentityVerification_total_div__1bw1Y > :nth-child(1) {
        height: 150vh !important;
    }
    .IdentityVerification_total_div__1bw1Y > :nth-child(2) {
        height: 150vh !important;
    }
    .IdentityVerification_content_div__1yCHI {
        /* margin-top: 100px; */
        width: 80vw;
        height: 560px;
    }
    .IdentityVerification_content_div__1yCHI > p {
        top: -20px;
    }
    .IdentityVerification_content_div__1yCHI > div {
        flex-direction: column;
    }
    .IdentityVerification_style_a__1c5n_ {
        margin-bottom: 20px;
    }
    .IdentityVerification_style_a1__FMSLU {
        top: 30px;
    }
  }
@keyframes NiceFail_move-twink-back__1qsbc {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes NiceFail_move-twink-back__1qsbc {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes NiceFail_move-clouds-back__1rDuS {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes NiceFail_move-clouds-back__1rDuS {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.NiceFail_stars__1N938, .NiceFail_twinkling__DXnA1, .NiceFail_clouds__bLVbn {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  /* min-height: 1080px; */
  display:block;
}

.NiceFail_stars__1N938 {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.NiceFail_twinkling__DXnA1{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:NiceFail_move-twink-back__1qsbc 200s linear infinite;
  animation:NiceFail_move-twink-back__1qsbc 200s linear infinite;
}

.NiceFail_clouds__bLVbn{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:NiceFail_move-clouds-back__1rDuS 200s linear infinite;
  animation:NiceFail_move-clouds-back__1rDuS 200s linear infinite;
}

* {
    margin: 0;
    padding: 0;
    font-family: "Noto Sans KR";
}

@keyframes FailedToFetchPage_move-twink-back__HM6dl {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes FailedToFetchPage_move-twink-back__HM6dl {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes FailedToFetchPage_move-clouds-back__3PrN6 {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes FailedToFetchPage_move-clouds-back__3PrN6 {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.FailedToFetchPage_stars__C7Tm-, .FailedToFetchPage_twinkling__1hQB5, .FailedToFetchPage_clouds__YGAT0 {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  /* min-height: 1080px; */
  display:block;
}

.FailedToFetchPage_stars__C7Tm- {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.FailedToFetchPage_twinkling__1hQB5{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:FailedToFetchPage_move-twink-back__HM6dl 200s linear infinite;
  animation:FailedToFetchPage_move-twink-back__HM6dl 200s linear infinite;
}

.FailedToFetchPage_clouds__YGAT0{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:FailedToFetchPage_move-clouds-back__3PrN6 200s linear infinite;
  animation:FailedToFetchPage_move-clouds-back__3PrN6 200s linear infinite;
}

.FailedToFetchPage_wrap_div__JVoxb{
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.FailedToFetchPage_content_div__1bxk6{
    /* opacity: 0.7; */
    padding: 50px;
    border: 0px;
    box-shadow: 0 0 30px white !important;
    border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

.FailedToFetchPage_content_div__1bxk6 > h1 {
    color: #FFFFFF;
    font-family: "Noto Sans KR";
}

.FailedToFetchPage_content_div__1bxk6 > p {
    color: #FFFFFF;
    font-family: "Noto Sans KR";
}
.Payment_title_div__7R_yK {
    width: 70%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
    height: 30px;
}

.Payment_participationCompetition_div__3Yn9G {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.Payment_participationCompetition_div__3Yn9G > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
    margin: 10px;
}

@media screen and (max-width: 768px) {
    .Payment_title_div__7R_yK {
        width: 100%;
        flex-direction: column;
    }
}

.CertificateOfExperience_wrap_div__14bZ- {
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: Noto Sans KR;
}

.CertificateOfExperience_back_img__257sH {
    width: 600px;
    position: absolute;
    z-index: -99;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%);
}

/* .back_img1 {
    width: 600px;
    position: absolute;
    z-index: -99;
    transform: translate(0, -25%);
}

.back_img2 {
    width: 600px;
    position: absolute;
    z-index: -99;
    transform: translate(0, 225%);
} */

.CertificateOfExperience_title_div__2s8kx {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
}

.CertificateOfExperience_title_div__2s8kx > img {
    width: 135px;
    margin-right: 20px;
}

.CertificateOfExperience_title_div__2s8kx > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}

.CertificateOfExperience_title_div__2s8kx > div > h3 {
    /* margin: 0; */
    font-size: 28px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
}

.CertificateOfExperience_title_div__2s8kx > div > h1 {
    /* margin: 0; */
    font-size: 70px;
    font-weight: 450;
    margin: 0;
}

.CertificateOfExperience_info_table__18s0T {
    width: 90%;
    max-width: 793px;
    text-align: center;
    border: 1px solid #010101;
    border-collapse: collapse;
    margin-bottom: 10px;
    padding: 5px;
    font-size: 17px;
    font-family: Noto Sans KR;
    font-weight: normal;
}

.CertificateOfExperience_info_table__18s0T > tbody > tr td {
    border: 1px solid #010101;
    border-collapse: collapse;
    padding: 5px;
}

.CertificateOfExperience_table_title__1N1YI {
    background-color: #f5f5f5;
    width: 150px;
}

.CertificateOfExperience_table_title2__3gpoi {
    background-color: #f5f5f5;
    width: 50px;
}

.CertificateOfExperience_info_div__1QC5F {
    width: 100%;
    /* background-color: red; */
    max-width: 650px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 100;
}

.CertificateOfExperience_info_div__1QC5F > :nth-child(1) {
    /* flex: 0.5; */
    flex: 2 1;
    margin-right: 180px;
    text-align: center;
    /* font-weight: bold; */
}
.CertificateOfExperience_info_div__1QC5F > div > :nth-child(1) {
    text-align: center;
    /* font-weight: bold; */
}
/* .info_div > :nth-child(2) {
    flex: 1;
    text-align: center;
    font-weight: bold;
  } */

.CertificateOfExperience_content_p__3gw2p {
    width: 90%;
    max-width: 650px;
    font-size: 28px;
    line-height: 250%;
}

.CertificateOfExperience_date_p__39jlp {
    width: 90%;
    max-width: 650px;
    font-size: 23px;
    text-align: right;
}

.CertificateOfExperience_date_p2__f-8UY {
    width: 90%;
    max-width: 793px;
    font-size: 23px;
    text-align: right;
}

@page {
    size: A4;
    margin: 0;
}
@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
    }
    /* ... the rest of the rules ... */
    .CertificateOfExperience_page_break__25pUV {
        page-break-after: always !important;
        -webkit-column-break-after: always !important;
                break-after: always !important;
    }
}

.TestPage_wrap_div__VCtJD {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.TestPage_back_img__iEPmT {
    width: 600px;
    position: absolute;
    z-index: -99;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%);
}

@page {
    size: A4;
    margin: 0;

  }
  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
    }
    /* ... the rest of the rules ... */
    .TestPage_page_break__2iLGu {
        page-break-after: always !important;
        -webkit-column-break-after: always !important;
                break-after: always !important;
    }

  }
