* {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR";
}

.label.label>span{ color: white; }
/* header {
  background-color:rgba(33, 33, 33, 0.9);
  color:#ffffff;
  display:block;
  font: 14px/1.3 Arial,sans-serif;
  height:50px;
  position:relative;
  z-index:5;
}
h2{
  margin-top: 30px;
  text-align: center;
}
header h2{
  font-size: 22px;
  margin: 0 auto;
  padding: 10px 0;
  width: 80%;
  text-align: center;
}
header a, a:visited {
  text-decoration:none;
  color:#fcfcfc;
} */

@keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-moz-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-ms-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

@keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-webkit-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-moz-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-ms-keyframes move-clouds-back {
  from {background-position: 0;}
  to {background-position:10000px 0;}
}

.stars, .twinkling, .clouds {
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
min-height: 1080px;
display:block;
}

.stars {
background:#000 url(/images/login/stars.png) repeat top center;
z-index:0;
}

.twinkling{
background:transparent url(/images/login/twinkling.png) repeat top center;
z-index:1;

-moz-animation:move-twink-back 200s linear infinite;
-ms-animation:move-twink-back 200s linear infinite;
-o-animation:move-twink-back 200s linear infinite;
-webkit-animation:move-twink-back 200s linear infinite;
animation:move-twink-back 200s linear infinite;
}

.clouds{
  background:transparent url(/images/login/clouds3.png) repeat top center;
  z-index:3;

-moz-animation:move-clouds-back 200s linear infinite;
-ms-animation:move-clouds-back 200s linear infinite;
-o-animation:move-clouds-back 200s linear infinite;
-webkit-animation:move-clouds-back 200s linear infinite;
animation:move-clouds-back 200s linear infinite;
}


.login_form_wrap{
  position: relative;
  display:'flex';
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.login_form_div{
/* opacity: 0.7; */
border: 0px;
width: 350px;
box-shadow: 0 0 30px white !important;
border-radius: 15px !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

/* ============================ 버튼 이벤트 a태그 */
.style_a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 0px;
  font-family: "Noto Sans KR";
}

.style_a:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
              0 0 25px #03e9f4,
              0 0 50px #03e9f4,
              0 0 100px #03e9f4;
}

.style_a > span {
  position: absolute;
  display: block;
}

.style_a >:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
 
  
}

.mouse_hover:hover{
  cursor: pointer;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.style_a > :nth-child(2){
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;

}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.style_a > :nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.style_a > :nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

@media (max-width:500px) {
  .login_form_div{
    width: 280px;
  }

  .optionText {
   font-size: 11px;
  }
}